import { get_user_token } from '../helper/functions/auth';
import Unit from './Unit';

export default class TimeTrackingUnit extends Unit {

    constructor(communicator) {

        super(communicator, 'timetracking');

    }

    loadStatistics = (cb_success, cb_error) => {
        let args = {
            token: get_user_token(),
            action: 'loadStatistics',
            unit: this.name,
            data: {}
        }

        this.communicator.call(args, cb_success, cb_error);
    }

}



