import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import DynamicModuleAccessUnit from '../../units/DynamicModuleAccessUnit';


export default class Modules extends React.Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            show_errors: false,
            list : [],
            rules : []
        }

        this.notification = new React.createRef();
    }

    componentDidMount() {
        this.props.set_current_page(this.props.location.pathname);

        let dynamic_access_modules_unit = new DynamicModuleAccessUnit(this.props.com);
        let that = this;

        this.setState({
            list : dynamic_access_modules_unit.get_modules()
        })

        let cb_success = (res) => {

            that.setState({
                rules: res,
                loading: false
            });
        }

        let cb_error = (res) => {
            that.setState({
                loading: false
            });
        }

        let dynamic_module_access_rules = JSON.parse(sessionStorage.getItem('dynamic_access_modules_list'));
        
        if (dynamic_module_access_rules === null) {
            
            that.setState({
                loading: true
            });

            dynamic_access_modules_unit.get(cb_success, cb_error);

        } else {

            this.setState({
                rules: dynamic_module_access_rules,
            })

        }

    }

    go_to_module = (id) => {
    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12} style={{ paddingBottom: 50, marginTop: 44 }}>
                            <h1>Modules</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Table>
                                <TableBody>
                                    {
                                        this.state.list.map((module, key) => {
                                            return <TableRow key={key}>
                                            <TableCell>{module.label}</TableCell>
                                            <TableCell className="align-right">
                                                <Link to={"/modules/"+module.id}>edit</Link>
                                            </TableCell>
                                        </TableRow>
                                        })
                                    }
                                    
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                <Loading visible={this.state.loading} />
                <Notification ref={this.notification} />

            </>

        )
    }
}