import {Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';


export default class Statistics extends React.Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            show_errors: false,
            push_notifications: [],
            partial_loading: false
        }

        this.notification = new React.createRef();
    }

    componentDidMount() {

        this.props.set_current_page('/statistics');

        //let auth = new AuthenticationUnit(this.props.com);

        //auth.login('stadler@spectory.at', 'password');

    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row style={{ marginTop: 44 }}>
                        <Col md={12}><h1>Statistics</h1></Col>
                    </Row>
                </Container>

                <Table style={{ marginTop: 50 }}>
                    <TableBody>
                        {/*
                            <TableRow>
                                <TableCell>
                                    Working time statistics
                                </TableCell>
                                <TableCell className="align-right">
                                    <Link to={"/statistics/working"}>show</Link>
                                </TableCell>
                            </TableRow>
                        */}
                        <TableRow>
                            <TableCell>
                                User behavior
                            </TableCell>
                            <TableCell className="align-right">
                                <Link to={"/statistics/users"}>show</Link>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Push notifications
                            </TableCell>
                            <TableCell className="align-right">
                                <Link to={"/statistics/push"}>show</Link>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </>
        )
    }
}
