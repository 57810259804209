import {get_user_token} from '../helper/functions/auth';
import Unit from './Unit';


export default class ContentUnit extends Unit {

    constructor(communicator) {

        super(communicator, 'staticcontent');

    }

    load = (cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'getAll',
            unit: this.name,
        }

        this.communicator.call(args, cb_success, cb_error, 'static_content');

    }

    set = (data, cb_success, cb_error) => {

        let args = {
            data: data,
            action: 'set',
            token: get_user_token(),
            unit: this.name
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    get = (page, language) => {

        let static_content = JSON.parse(sessionStorage.getItem('static_content')).data.staticContent;

        let content_index = null;

        if (static_content == null) return null;

        static_content.forEach((entry, entry_index_cur) => {

            if (entry.page === page && entry.language === language) {

                content_index = entry_index_cur;

            }

        })

        return {
            content_index : content_index,
            contents : static_content
        };

    }

    set_locally = (id, new_entry) => {

        let static_content = JSON.parse(sessionStorage.getItem('static_content')).data.staticContent;

        let result = false;

        if (static_content == null) return null;

        static_content.forEach((page, pageKey) => {

            page.forEach((entry, key) => {

                if (entry.feedEntryId === id) {
                    static_content[pageKey][key] = new_entry;
                    result = true;
                }

            })

        });

        if (result === true) {
            sessionStorage.setItem('static_content', JSON.stringify(static_content));
        }

        return result;

    }

    delete = (page, language, cb_success, cb_error)  => {

        let args = {
            token: get_user_token(),
            action: 'delete',
            unit: this.name,
            data: {
                page: page,
                language: language,
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    getAllVocatons = (getSubjectArea, cb_success, cb_error) => {

        const args = {
            action: 'getSubjectAreas',
            unit: this.name,
            data: {
                groupsOnly: getSubjectArea ? getSubjectArea : false,
            }
        }

        this.communicator.call(args, cb_success, cb_error, 'subjectArea');

    }





}



