import { get_user_token } from '../helper/functions/auth';
import Unit from './Unit';


export default class GeneralSettingUnit extends Unit {

    constructor(communicator) {

        super(communicator, 'generalsetting');

    }

    set_destination = (data, cb_success, cb_error) => {

        let args = {
            data: data,
            action: 'setChatDestination',
            token: get_user_token(),
            unit: this.name
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    get_destinations = (cb_success, eb_error) => {

        let args = {
            token: get_user_token(),
            action: 'getAllChatDestinations',
            unit: this.name
        }

        this.communicator.call(args, cb_success, eb_error, 'admin_users_destinations');

    }

    delete_destination = (id, cb_success, cb_error) => {
        let args = {
            token: get_user_token(),
            action: 'deleteChatDestination',
            unit: this.name,
            data: {
                id: id
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    get_default_email = (cb_success, cb_error) => {
        let args = {
            token: get_user_token(),
            action: 'get',
            unit: this.name,
            data: {
                name: "defaultemail"
            }
        }

        this.communicator.call(args, cb_success, cb_error, 'defaultemail');
    }

    set_default_email = (email, cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'set',
            unit: this.name,
            data: {
                name: "defaultemail",
                value: email
            }
        }

        this.communicator.call(args, cb_success, cb_error);
    }

}



