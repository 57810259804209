import React from 'react';
import Popup from './Popup';

/**
 * Render Info Element
 */
export default class Info extends React.Component {

    constructor() {

        super();

        this.state = {
            visible: false,
        }

        this.popup = React.createRef();

    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * hide Tooltip
     */
    hide = () => {
        this.setState({ visible: false });
    }

    /**
     * show tooltip
     */
    show = () => {
        this.popup.current.open();
    }

    /**
     * Render Notification
     */
    render() {
        return (
            <>
                <div
                    className="info_button"
                    style={this.props.style}
                    onClick={this.show}
                ></div>

                <Popup ref={this.popup}>
                    {this.props.children}
                </Popup>

            </>
        )

    }
}