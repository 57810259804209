import React from 'react';

/**
 * Render Notification Element
 */
export default class Notification extends React.Component {

    constructor(){

        super();

        this.state = {
            visible : false,
            type : 'error',
            text : ''
        }
    }

    componentDidMount(){

        let newState = {visible : false}

        if(this.props.text !== undefined) newState.text = this.props.text;
        if(this.props.type !== undefined) newState.type = this.props.type;

        this.setState(newState);

    }

    /**
     * Close Notification
     */
    close = () => {
        this.setState({
            visible : false
        })
    }

    /**
     * Open Notification and set type & text
     * @param {String} type 
     * @param {String} text 
     */
    open = (type = null, text = null) => {

        let newState = {visible : true}

        if(text !== null) newState.text = text;
        if(type !== null) newState.type = type;

        this.setState(newState);

    }

    /**
     * Render Notification
     */
    render() {
        if(this.state.visible){

            return (
                <div className={"notification_container "+this.state.type}>
                    {this.state.text}
                    <div className="notification_close_button" onClick={() => {this.setState({visible : false})}}></div>
                </div>
            );
            
        }else{

            return null;

        }
        
    }
}