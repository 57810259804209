import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";

export default class UserKeyTagTable extends React.Component {
    constructor(props) {
        super(props);
    }

    renderTableElement(item) {
            return (
                <TableRow key={item.keyTagId}>
                    <TableCell style={{width: '5%'}}/>
                    <TableCell style={{width: '40%'}}>
                        {item.mail}
                    </TableCell>
                    <TableCell style={{textAlign: 'center', width: '30%'}}>
                        {item.keyTagId}
                    </TableCell>
                    <TableCell style={{textAlign: 'center', width: '20%'}}>
                        {item.lost ? 'lost' : ''}
                    </TableCell>
                    <TableCell style={{width: '5%'}}/>
                </TableRow>
            )
    }

    render() {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: '5%'}}/>
                        <TableCell style={{width: '40%'}}>User Name</TableCell>
                        <TableCell style={{textAlign: 'center', width: '30%'}}>Key-Tag ID</TableCell>
                        <TableCell style={{textAlign: 'center', width: '20%'}}>Lost</TableCell>
                        <TableCell style={{width: '5%'}}/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.props.userKeyTagsArray.filter((singleElement) => {
                            if (this.props.searchValue !== '')
                                return this.props.showLostKeys ?
                                    singleElement.lost && singleElement.keyTagId.toString().includes(this.props.searchValue) || (singleElement.mail && singleElement.mail.includes(this.props.searchValue)) :
                                    singleElement.keyTagId.toString().includes(this.props.searchValue) || (singleElement.mail && singleElement.mail.includes(this.props.searchValue))
                            else
                                return this.props.showLostKeys ? singleElement.lost && singleElement : singleElement
                        }).map((singleUserKeytagElement) => this.renderTableElement(singleUserKeytagElement))
                    }
                </TableBody>
            </Table>
        );
    }
}

