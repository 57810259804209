import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {calculateUtcTimezone, formatDatetime, formatDatetimeForDbSimple, getBase64, wrlog} from '../../../helper/misc';
import Loading from '../../../helper/screens/Loading';
import Notification from '../../../helper/screens/Notification';
import {validation} from '../../../helper/validation';
import FeedUnit from '../../../units/FeedUnit';

// Import Editor
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import {FormControlLabel, InputLabel, MenuItem, Select, TextField} from '@material-ui/core';

import languages from '../../../helper/data/languages.json';
import CustomCheckbox from '../../../helper/screens/CustomCheckbox';
import Popup from '../../../helper/screens/Popup';

import regions from '../../../helper/data/regions.json';
import Accordion from '../../../helper/screens/Accordion';
import VocationAccordion from '../../../helper/screens/VocationAccordion';

import Switch from '../../../helper/screens/Switch';
import {DOWNLOAD_URL} from '../../../store';

import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import YouTube from "react-youtube";


class CmsDetail extends React.Component {

    constructor() {
        super();

        this.state = {
            pictureUpload: true,
            youTubeUpload: false,
            youTubeLink: '',
            youTubeVideoID: '',
            autoplayYouTube: 1,
            loading: false,
            is_404: false,
            show_errors: false,
            entry: {},
            entry_id: null,
            version_index: null,
            show_title: '',
            language: null,
            languages: [],
            current_language: null,
            redirecting: false,
            current_panel: null,
            switch: 'entry',
            show_reply_form: null,
            show_replies: null,
            show_comment_form: false,
            image: null,
            entry_image_object: null,
            rules_updated: false,
            post_image_changed: false,
            post_image: null,
            feedImageBase64: null,
            originalFeedImageBase64: null,
            timeChanging: false,
            form: {
                time: {
                    value: '',
                    valid: false,
                    rules: {
                        isRequired: true
                    }
                },
                title: {
                    value: '',
                    valid: false,
                    rules: {
                        isRequired: true
                    }
                },
                language: {
                    value: 'de',
                    valid: true,
                    rules: {
                        isRequired: true
                    }
                },
                new_language: {
                    value: 'de',
                    valid: true,
                    rules: {
                        isRequired: true
                    }
                },
                visible: {
                    type: 'checkbox',
                    value: false,
                    valid: true,
                    rules: {
                    }
                },
                only_members: {
                    type: 'checkbox',
                    value: false,
                    valid: true,
                    rules: {
                    }
                },
                regions: {
                    type: 'checkbox_array',
                    value: [],
                    valid: true,
                    rules: {
                    }
                },
                vocations: {
                    type: 'checkbox_array',
                    value: [],
                    valid: true,
                    rules: {
                    }
                },
                comment: {
                    value: '',
                    valid: false,
                    rules: {
                        isRequired: true
                    }
                },
                creator_name: {
                    value: 'Vida-Team',
                    valid: true,
                    rules: {
                        isRequired: true
                    }
                },
                filter: 'all'
            }
        }

        this.notification = new React.createRef();
        this.popup = new React.createRef();
        this.sidebar_rightRef = new React.createRef();
        this.sidebar_right_burgerRef = new React.createRef();

        this.editor = null;

    }

    componentDidMount() {

        this._init();


        // Save Filter from Parent to present same settings when going back
        if (this.props.location !== undefined &&
            this.props.location.state !== undefined &&
            this.props.location.state.filter !== undefined
        ) {
            this.setState({
                filter: this.props.location.state.filter
            })
        }

    }

    componentDidUpdate() {

        if (!this.state.redirecting) return;

        this.setState({
            redirecting: false
        })


        if (this.editor !== null && this.editor !== undefined) {
            this.editor.destroy();
            this.editor = null;
        }
        this.editor = null;
        this._init();

    }

    _init_editor = (data = null) => {

        if (this.editor !== null) {
            return;
        }

        if (data.blocks === undefined) {
            data = { time: 1601035637587, blocks: [], version: "2.18.0" };
        }

        this.editor = new EditorJS({
            holder: 'editorjs',
            tools: {
                header: {
                    class: Header,
                    inlineToolbar: ['link'],
                    levels: [2, 3, 4],
                    defaultLevel: 3
                },
                quote: Quote,
            },
            placeholder: 'Let`s write an awesome story!',
            data: data
        });
    }

    _init = () => {

        let entry_id = this.props.match.params.id;
        let language = this.props.match.params.language;


        this.props.set_current_page('/cms');

        if (Number.isInteger(parseInt(entry_id))) {

            let feedEntry = new FeedUnit(this.props.com);
            let entry = feedEntry.get(parseInt(entry_id));

            let that = this;

            this.setState({
                entry_id: entry_id,
                loading: true
            });

            let cb_success = (res) => {

                this.get_rules(entry_id)

                let current_languages = [];

                res.data.feedEntry.data.forEach(data => {
                    current_languages.push(data.language);
                })

                this.setState({
                    entry: res.data.feedEntry,
                    loading: false,
                    languages: current_languages,
                });

                if (res.data.feedEntry.files.length > 0) {

                    let post_image = this.get_post_image(res.data.feedEntry.files);

                    feedEntry.downloadImage(post_image.path, (res) => {

                        this.setState({
                            feedImageBase64: `data:${post_image.contentType};base64,${res.data.file}`
                        });

                    }, (err) => { wrlog(err) });

                    this.setState({
                        post_image: post_image,
                        post_image_path: post_image ? DOWNLOAD_URL + post_image.path : null
                    })

                }
                if (res.data.feedEntry.data.length > 0 && (res.data.feedEntry.data[0].youTubeVideoID !== '' && res.data.feedEntry.data[0].youTubeVideoID !== null)) {
                    this.setState({
                        youTubeVideoID: res.data.feedEntry.data[0].youTubeVideoID,
                        pictureUpload: false,
                        youTubeUpload: true,
                        youTubeLink: 'https://www.youtube.com/watch?v='+res.data.feedEntry.data[0].youTubeVideoID,
                    })
                }

                // Set date
                let formCopy = this.state.form;
                formCopy.time.value = new Date(entry.displayTime);

                if (res.data.feedEntry.visible !== undefined) {

                    formCopy.visible.value = res.data.feedEntry.visible;

                }

                this.setState({
                    form: formCopy
                });

                if (language !== undefined) {

                    this._check_language(language, res.data.feedEntry);

                } else {

                    if (res.data.feedEntry.data[0] !== undefined) {

                        this.changeLanguage(res.data.feedEntry.data[0].language, res.data.feedEntry.feedEntryId);

                    }

                }

            }

            let cb_error = () => {

                that.setState({
                    loading: false
                });
            }

            feedEntry.load(parseInt(entry_id), cb_success, cb_error);

        } else if (entry_id === 'new') {

            let formCopy = this.state.form;
            formCopy.time.value = new Date();

            this.setState({
                entry_id: entry_id,
                show_title: 'New article',
                entry: { data: [] },
                form: formCopy
            })

            this._init_editor({ time: 1601035637587, blocks: [], version: "2.18.0" })


        } else {

            this.setState({
                is_404: true,
            })

        }

    }

    get_rules = (id) => {

        let feedEntry = new FeedUnit(this.props.com);

        const cb_success_rules = (res) => {

            let formCopy = this.state.form;

            let regions = [];
            let vocations = [];

            res.data.rules.forEach(rule => {

                if (rule.member === true) {
                    formCopy.only_members.value = true;
                }
                if (rule.region !== undefined && !regions.includes(rule.region)) {
                    regions.push(rule.region);
                }
                if (rule.subjectArea !== undefined && !vocations.includes(rule.subjectArea)) {
                    vocations.push(rule.subjectArea);
                }

            })

            if (regions.length > 0) {
                formCopy.regions.value = regions;
            }

            if (vocations.length > 0) {
                formCopy.vocations.value = vocations;
            }

            this.setState({
                form: formCopy
            })

        }
        const cb_error_rules = () => {
        }

        feedEntry.get_rules(parseInt(id), cb_success_rules, cb_error_rules);

    }

    _check_language = (language, entry) => {

        if (entry !== null && entry.data.length > 0) {

            let output = null;

            entry.data.forEach((version, index) => {

                if (version.language === language) output = { index: index, title: version.title };

            })

            if (output == null) {

                this.setState({
                    is_404: true
                })

            } else {

                let formCopy = this.state.form;

                formCopy.language.valid = true;
                formCopy.language.value = language;

                formCopy.title.valid = true;
                formCopy.title.value = output.title;

                this.setState({
                    version_index: output.index,
                    show_title: 'Edit "' + output.title + '"',
                    language: language,
                    form: formCopy
                });

                let content;


                try {
                    content = JSON.parse(entry.data[output.index].content);
                } catch {
                    content = { time: 1601035637587, blocks: [], version: "2.18.0" };
                }

                try {

                    this._init_editor(content)

                } catch (error) {
                    console.error(error)
                }
            }
        }
    }

    upload_image = (file, type, cb_success, cb_error) => {

        let feedEntry = new FeedUnit(this.props.com);

        feedEntry.upload_image(file, type, this.state.entry_id, cb_success, cb_error);

    }

    upload_image_as_base64 = (base64, type, cb_success, cb_error) => {

        let feedEntry = new FeedUnit(this.props.com);

        feedEntry.upload_image_as_base64(base64, type, this.state.entry_id, cb_success, cb_error);

    }

    delete_post_image = () => {

        let post_images = this.state.entry.files;

        if (post_images == null) return false;

        let feedEntry = new FeedUnit(this.props.com);

        post_images.forEach(file => {

            if (file.meta === undefined || file.meta == null) return;

            let meta = JSON.parse(file.meta);

            if (meta.type !== undefined && meta.type === 'post_image') {
                feedEntry.delete_image(file.internalName);
            }

        });

    }

    get_post_image = (files = null) => {

        let post_image = null;

        if (files == null) files = this.state.entry.files

        if (files !== undefined && files.length > 0) {

            files.forEach(file => {

                if (file.meta === undefined || file.meta == null) return;

                let meta = JSON.parse(file.meta);

                if (meta.type !== undefined && meta.type === 'post_image') {
                    post_image = file;
                }

            });
        }


        return post_image;

    }

    setFormattedImage = (image) => {
        var canvas = document.getElementById("canvasAutoFormat");

        var targetContentSize = 500;

        var scalingFactor = targetContentSize / Math.max(image.width, image.height);

        canvas.width = 2 * targetContentSize;
        canvas.height = 2 * targetContentSize;

        var ctx = canvas.getContext("2d");
        var backgroundColorPicker = document.getElementById("backgroundColorPicker");
        ctx.fillStyle = backgroundColorPicker.value;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, canvas.width / 2 - image.width * scalingFactor / 2, canvas.height / 2 - image.height * scalingFactor / 2, image.width * scalingFactor, image.height * scalingFactor);

        var base64Data = canvas.toDataURL();

        this.setState({
            post_image_changed: true,
            feedImageBase64: base64Data
        });
    }

    autoFormat = () => {

      var original = this.state.originalFeedImageBase64;
      if (this.state.originalFeedImageBase64 == null) {
        this.setState({
            originalFeedImageBase64: this.state.feedImageBase64
        });
        original = this.state.feedImageBase64;
      }

      var image = new Image();
      image.src = original;
      image.onload = this.setFormattedImage(image);
    }

    resetAutoFormat = () => {
      this.setState({
          post_image_changed: true,
          feedImageBase64: this.state.originalFeedImageBase64
      });
    }

    save = () => {

        let feedEntry = new FeedUnit(this.props.com);
        let that = this;

        this.setState({
            show_errors: false,
            autoplayYouTube: 0,
        })

        let isValid = true;

        if (!this.state.form.title.valid || !this.state.form.language.valid) {
            isValid = false;
            this.setState({
                show_errors: true
            })
        }


        if (!isValid) return;

        this.editor.save().then((content) => {

            let rules = [];
            const only_members = this.state.form.only_members.value;

            const entry_content = {...content, /*youtubeID: this.state.youTubeVideoID*/};


            if (that.state.form.regions.value.length > 0) {
                that.state.form.regions.value.forEach(region => {
                    if (that.state.form.vocations.value.length > 0) {
                        that.state.form.vocations.value.forEach(vocation => {

                            let rule = {};

                            if (only_members === true) rule['ismember'] = true;

                            rule['region'] = region;
                            rule['subjectArea'] = vocation;

                            rules.push(rule);

                        })
                    } else {

                        let rule = {};

                        if (only_members === true) rule['ismember'] = true;

                        rule['region'] = region;

                        rules.push(rule);

                    }

                })

            } else {

                if (that.state.form.vocations.value.length > 0) {

                    that.state.form.vocations.value.forEach(vocation => {

                        let rule = {};

                        if (only_members === true) rule['ismember'] = true;

                        rule['subjectArea'] = vocation;

                        rules.push(rule);

                    })

                } else {

                    if (only_members === true) rules.push({ ismember: true });

                }

            }

            that.setState({
                loading: true
            })

            if (rules.length < 1) {
                rules.push({})
            }

            let cb_success = (res) => {
                let redirect = () => {

                    feedEntry.load(parseInt(res.data.id), (res_load) => {

                        feedEntry.add_locally(res_load.data.feedEntry);

                        this.props.history.push('/cms/' + res.data.id + '/' + that.state.form.language.value);

                        this.setState({
                            redirecting: true,
                            loading: false
                        });

                        that.notification.current.open('success', 'Article created');

                    }, () => { });

                }

                if (this.state.post_image !== null) {

                    this.setState({
                        entry_id: res.data.id
                    })

                    this.upload_image(this.state.post_image, 'post_image', () => {

                        redirect();

                    }, () => { });

                } else if (this.state.originalFeedImageBase64 != null) {
                    this.upload_image_as_base64(this.state.feedImageBase64, 'post_image', () => {

                        redirect();

                    }, () => { });
                } else {

                    redirect();

                }
            }

            let cb_success_updated = () => {

                let reload_content = () => {
                    that.notification.current.open('success', 'Article saved');
                    this._init();
                }

                const cb_update_rules = () => {

                    if (this.state.post_image_changed) {

                        if (this.get_post_image() !== null) {
                            this.delete_post_image();
                        }

                        if (this.state.originalFeedImageBase64 != null) {
                            this.upload_image_as_base64(this.state.feedImageBase64, 'post_image', () => {

                                feedEntry.load(parseInt(this.state.entry_id), (res_load) => {

                                    feedEntry.set_locally(this.state.entry_id, res_load.data.feedEntry)
                                    that.notification.current.open('success', 'Article saved');

                                    that.setState({
                                        loading: false
                                    });

                                }, () => { });

                            }, () => {

                                feedEntry.load(parseInt(this.state.entry_id), (res_load) => {

                                    feedEntry.set_locally(this.state.entry_id, res_load.data.feedEntry)
                                    that.notification.current.open('success', 'Article saved');

                                    that.setState({
                                        loading: false
                                    });

                                }, () => { });
                            });
                        } else if (this.state.post_image !== null) {
                            this.upload_image(this.state.post_image, 'post_image', () => {

                                feedEntry.load(parseInt(this.state.entry_id), (res_load) => {

                                    feedEntry.set_locally(this.state.entry_id, res_load.data.feedEntry)
                                    that.notification.current.open('success', 'Article saved');

                                    that.setState({
                                        loading: false
                                    });

                                }, () => { });

                            }, () => {

                                feedEntry.load(parseInt(this.state.entry_id), (res_load) => {

                                    feedEntry.set_locally(this.state.entry_id, res_load.data.feedEntry)
                                    that.notification.current.open('success', 'Article saved');

                                    that.setState({
                                        loading: false
                                    });

                                }, () => { });
                            });

                        } else {
                            feedEntry.load(parseInt(this.state.entry_id), (res_load) => {

                                feedEntry.set_locally(this.state.entry_id, res_load.data.feedEntry)
                                that.notification.current.open('success', 'Article saved');

                                that.setState({
                                    loading: false
                                });

                            }, () => { });
                        }

                    } else {

                        reload_content();

                    }

                }

                if (that.state.rules_updated === true) {

                    that.setState({
                        rules_updated: false
                    })

                    feedEntry.delete_rules(parseInt(that.state.entry_id), () => {

                        feedEntry.set_rules(parseInt(that.state.entry_id), rules, cb_update_rules, () => {
                        })

                    }, () => {
                    })

                } else {

                    cb_update_rules();

                }




            }

            let cb_error = () => {

                that.setState({
                    loading: false
                })

                that.notification.current.open('error', 'An error occured, please try again');

            }

            if (that.state.entry_id === 'new') {
                let data = {
                    visible: that.state.form.visible.value,
                    visibleFrom: formatDatetimeForDbSimple(new Date(calculateUtcTimezone(that.state.form.time.value))),
                    data: [
                        {
                            language: that.state.form.language.value,
                            title: that.state.form.title.value,
                            content: JSON.stringify(entry_content),
                            youTubeVideoID: this.state.youTubeVideoID,
                        }
                    ],
                    rules: rules
                }

                feedEntry.create(data, cb_success, cb_error)

            } else {

                let data = {
                    feedEntryId: parseInt(this.state.entry_id),
                    visibleFrom: formatDatetimeForDbSimple(new Date(calculateUtcTimezone(that.state.form.time.value))),
                    data: [
                        {
                            language: that.state.form.language.value,
                            title: that.state.form.title.value,
                            content: JSON.stringify(entry_content),
                            youTubeVideoID: this.state.youTubeVideoID,
                        }
                    ],
                    visible: this.state.form.visible.value
                }

                feedEntry.update(data, cb_success_updated, cb_error);

            }


        }).catch(() => {
        });

    }

    change_value = (key, value) => {

        let formCopy = this.state.form;

        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        if (formCopy[key].type !== undefined && formCopy[key].type === 'checkbox') {

            formCopy[key].value = !formCopy[key].value;

        } else {

            formCopy[key].value = value;
            formCopy[key].valid = validation(value, formCopy[key].rules);

        }

        if (key === 'only_members') {

            this.setState({
                rules_updated: true,
                form: formCopy
            });

        } else {

            this.setState({
                form: formCopy
            });

        }

    }

    changeLanguage = (language, id = null) => {

        if (id == null) {
            id = this.state.entry_id;
        }

        if (this.state.entry_id === 'new') {

            let formCopy = this.state.form;
            formCopy.language.value = language;
            formCopy.language.valid = true;

            this.setState({
                form: formCopy
            })

        } else {
            this.props.history.push('/cms/' + id + '/' + language);

            this.setState({
                redirecting: true
            })
        }
    }

    add_variation = () => {

        if (!this.state.form.new_language.valid) {

            this.setState({
                show_errors: true
            });

            return false;
        }

        const new_language = this.state.form.new_language.value;

        let feedEntry = new FeedUnit(this.props.com);

        let entryCopy = this.state.entry;

        let formCopy = this.state.form;

        entryCopy.data.push({
            content: "",
            language: new_language,
            title: "",
            locally: true
        });

        formCopy.language.value = new_language;
        formCopy.language.valid = true;

        formCopy.new_language.value = '';
        formCopy.new_language.valid = false;

        this.setState({
            entry: entryCopy,
            form: formCopy
        })

        let res = feedEntry.set_locally(this.state.entry_id, entryCopy);


        if (res) {
            this.popup.current.close();
            this.changeLanguage(new_language);
        }

    }

    delete_variant = () => {

        let feedEntry = new FeedUnit(this.props.com);

        let current_variant = null;

        this.state.entry.data.forEach(variant => {
            if (variant.language === this.state.form.language.value) {
                current_variant = variant;
            }
        });

        if (current_variant == null) {

            return false
        }

        this.setState({
            loading: true
        });


        const cb_success = () => {

            let new_language = null;

            this.state.languages.forEach(language => {
                if (language !== this.state.form.language.value) new_language = language;
            })

            feedEntry.load(parseInt(this.state.entry_id), (res_load) => {

                feedEntry.set_locally(this.state.entry_id, res_load.data.feedEntry);

                this.setState({
                    loading: false
                });

                this.changeLanguage(new_language);

                this.notification.current.open('success', 'Variant deleted');

            }, () => { });
        }

        const cb_error = () => {

            this.setState({
                loading: false
            })

        }

        feedEntry.delete_variant(parseInt(this.state.entry_id), this.state.form.language.value, cb_success, cb_error);
    }

    changeRegions = (value) => {

        let formCopy = this.state.form;
        let selectedRegions = formCopy.regions.value;
        let position = selectedRegions.indexOf(value);

        if (position > -1) {
            selectedRegions.splice(position, 1);
        } else {
            selectedRegions.push(value);
        }

        formCopy.regions.value = selectedRegions;

        this.setState({
            rules_updated: true,
            form: formCopy
        })

    }

    /**
     * cbForChangeingTheSubject
     * @param {int} value
     */
    changeVocations = (value) => {

        let formCopy = this.state.form;
        let selectedVocations = formCopy.vocations.value;
        let position = selectedVocations.indexOf(value);

        if (position > -1) {
            selectedVocations.splice(position, 1);
        } else {
            selectedVocations.push(value);
        }

        formCopy.vocations.value = selectedVocations;

        this.setState({
            rules_updated: true,
            form: formCopy
        })
    }

    /**
     * cbForChangeingTheSubject
     * @param {int} key
     */
    changeVocationsCategories = (key) => {

        let formCopy = this.state.form;
        let selectedVocations = formCopy.vocations.value;

        // DESELECT ALL

        let selected_all = true;

        if (!selectedVocations.includes(key)) {
            selected_all = false;
        }

        let new_selected_vocations = [];

        if (selected_all) {

            selectedVocations.forEach((mvzvCode) => {
                if (mvzvCode !== key) {
                    new_selected_vocations.push(key);
                }
            })
        } else {

            new_selected_vocations = selectedVocations;

            if (!new_selected_vocations.includes(key)) {
                new_selected_vocations.push(key);
            }
        }

        formCopy.vocations.value = new_selected_vocations;

        this.setState({
            rules_updated: true,
            form: formCopy,
        })


    }

    /**
     * cbAddAllSubjectsFromArea
     * @param {name: string, mvzvCode: number, children: {name: string, mvzvCode: number}} subjectsFromArea
     * */
    cbAddAllSubjectsFromArea = (subjectsFromArea) => {
        const newSubjects = [...this.state.form.vocations.value];

        !newSubjects.includes(subjectsFromArea.mvzvCode) && newSubjects.push(subjectsFromArea.mvzvCode);

        subjectsFromArea.children && subjectsFromArea.children.map((singleSubject) => {
            !newSubjects.includes(singleSubject.mvzvCode) && newSubjects.push(singleSubject.mvzvCode);
        });

        this.setState({
            rules_updated: true,
            form: {
                ...this.state.form,
                vocations: {
                    ...this.state.form.vocations,
                    value: newSubjects
                }
            }
        })
    }

    /**
     * cbRemoveAllSubjectsFromArea
     * @param {name: string, mvzvCode: number, children: Array<{name: string, mvzvCode: number}>} subjectsFromArea
     * */
    cbRemoveAllSubjectsFromArea = (subjectsFromArea) => {
        const elementsToRemove = [subjectsFromArea.mvzvCode];

        subjectsFromArea.children && subjectsFromArea.children.forEach((singleSubject) => {
            elementsToRemove.push(singleSubject.mvzvCode)
        })


        this.setState({
            rules_updated: true,
            form: {
                ...this.state.form,
                vocations: {
                    ...this.state.form.vocations,
                    value: this.state.form.vocations.value.filter((single) => !elementsToRemove.includes(single))
                }
            }
        })
    }

    handleAccordion = (panel) => {

        if (this.state.current_panel === panel) panel = null;

        this.setState({
            current_panel: panel
        })
    }

    switch_changed = (value) => {
        if (this.editor !== null) {
            this.editor.destroy();
            this.editor = null;
        }

        if (value === 'entry') {

            let content;
            try {
                content = JSON.parse(this.state.entry.data[this.state.version_index].content);
            } catch {
                content = { time: 1601035637587, blocks: [], version: "2.18.0" };
            }

            this._init_editor(content)

        }

        this.setState({
            switch: value
        })

    }

    open_reply_input = (comment_index) => {

        if (this.state.show_reply_form === comment_index) comment_index = null;

        let formCopy = this.state.form;
        formCopy.comment.value = '';

        this.setState({
            show_reply_form: comment_index,
            show_replies: null,
            form: formCopy,
            show_comment_form: null
        })

    }

    toggle_replies = (comment_index) => {

        if (this.state.show_replies === comment_index) comment_index = null;

        this.setState({
            show_reply_form: null,
            show_replies: comment_index,
            show_comment_form: null
        })

    }

    toggle_comment_form = () => {

        let formCopy = this.state.form
        formCopy.comment.value = '';
        let pre = this.state.show_comment_form;

        this.setState({
            show_reply_form: null,
            show_comment_form: !pre,
            show_replies: null,
            form: formCopy
        })

    }

    add_comment = (parent_id, is_reply = false) => {

        let feedEntry = new FeedUnit(this.props.com);
        let that = this;

        this.setState({
            show_errors: false
        })

        let isValid = true;

        let formCopy = this.state.form

        if (!formCopy.comment.valid || !formCopy.creator_name.valid) {
            isValid = false;
            this.setState({
                show_errors: true
            })
        }

        if (!isValid) return;


        that.setState({
            loading: true
        })

        let cb_success = () => {

            let open_replies = null;

            this._init();

            if (is_reply) {

                open_replies = parent_id;

            }

            // reset form
            formCopy.comment.value = '';
            formCopy.comment.valid = false;

            that.setState({
                form: formCopy,
                show_replies: open_replies,
                show_reply_form: null,
            });

            that.notification.current.open('success', 'Comment saved');

        }

        let cb_error = () => {

            that.setState({
                loading: false
            })

            that.notification.current.open('error', 'An error occured, please try again');

        }

        let data = {
            data:
                [
                    {
                        language: "de",
                        content: formCopy.comment.value
                    },
                ],
            parentId: parseInt(parent_id),
            creatorName: formCopy.creator_name.value
        }

        feedEntry.create_comment(data, cb_success, cb_error);

    }

    delete = (id, type, parent_id = null) => {

        let feedEntry = new FeedUnit(this.props.com);
        let that = this;

        let cb_success = () => {
        }
        let send_id = null;


        this.setState({
            loading: true
        });

        if (type === 'reply') {

            cb_success = () => {

                that._init();


                that.notification.current.open('success', 'Reply deleted');

            }

            send_id = this.state.entry.comments[parent_id].replies[id].feedEntryId;

        } else if (type === 'comment') {

            cb_success = () => {

                that._init();

                that.notification.current.open('success', 'Comment deleted');

            }

            send_id = this.state.entry.comments[id].feedEntryId;


        } else if (type === 'article') {

            cb_success = () => {

                that.setState({
                    loading: false,
                });
                that.notification.current.open('success', 'Article deleted');
                that.props.history.push('/cms');

            }

            send_id = id;
        }

        let cb_error = () => {

            that.setState({
                loading: false
            })

            that.notification.current.open('error', 'Please wait to delete this entry.');

        }

        if (send_id == null) {
            cb_error({});
            return false;
        }

        console.log(send_id)

        feedEntry.delete(parseInt(send_id), cb_success, cb_error)

    }

    setSelectedFile = (file) => {
        getBase64(file, false).then(base64 => {
            this.setState({
                post_image: file,
                post_image_changed: true,
                feedImageBase64: base64
            });

        }).catch(err => console.log("ERR", err))

    }

    toggleMobileNav = () => {
        this.sidebar_rightRef.current.classList.toggle('open')
        this.sidebar_right_burgerRef.current.classList.toggle('open')
    }

    //YouTube-AddOn
    changeYouTubeLink = (typeEvent) => {
        this.setState({
            youTubeLink: typeEvent.target.value,
        })
    }

    changeYouTubeLinkToID = () => {
        const video_id = this.state.youTubeLink.includes('you') ? this.state.youTubeLink.includes('watch?v=') ?
            this.state.youTubeLink.split('v=')[1] :
            this.state.youTubeLink.split('e/')[1] : undefined;

        this.setState({
            youTubeVideoID: video_id,
        })
    }

    //render
    render() {

        if (this.state.is_404) {
            return (
                <><Link
                    to={{
                        pathname: `/cms`,
                        state: { filter: this.state.filter || 'all' }
                    }}
                    className="go_back_button"
                >Go back</Link><br /><br />
                    <h3>Dieser User konnte nicht gefunden werden</h3></>
            );
        }

        return (
            <>
                <div
                    className="burger_right"
                    ref={this.sidebar_right_burgerRef}
                    onClick={() => {
                        this.toggleMobileNav()
                    }}
                />
                <Container fluid style={{ marginLeft: 0 }} className="middle_container">
                    <Row>
                        <Col md={12} style={{ paddingBottom: 20 }}>
                            <Link
                                to={{
                                    pathname: `/cms`,
                                    state: { filter: this.state.filter || 'all' }
                                }}
                                className="go_back_button"
                            // to="/cms"
                            >Go back</Link>
                            <br /><br />
                            <h1>{this.state.show_title}</h1>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: 50 }}>
                        <Col>
                            {this.state.entry_id !== 'new' ?
                                <>
                                    <Switch onChange={() => this.switch_changed('entry')} selected={this.state.switch === 'entry'} title="Entry" />
                                    <Switch onChange={() => this.switch_changed('comments')} selected={this.state.switch === 'comments'} title="Comments" />
                                </> : null}
                        </Col>
                    </Row>
                    {this.state.switch === 'entry' ?
                        <Row>
                            <Col md={12} style={{ paddingRight: 20 }}>
                                {this.state.entry_id !== 'new' ? <div class="add_button" style={{ marginRight: 20 }} onClick={() => this.popup.current.open()}>Add variant</div> : null}
                                {this.state.entry !== undefined && this.state.entry.data !== undefined && this.state.entry.data.length > 1 ? <div class="small_button" onClick={() => this.delete_variant()}>delete this variant</div> : null}

                                <br />
                                <br />

                                <TextField id="title" value={this.state.form.title.value} error={!this.state.form.title.valid && this.state.show_errors} label="Title" onChange={value => this.change_value('title', value)} />

                                <br />
                                <br />
                                <strong>Content</strong>
                                <div id="editorjs" className="editor"/>
                            </Col>
                        </Row>

                        :
                        <>
                            <Row>
                                <Col>
                                    <strong>Comments</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        this.state.entry.comments === undefined || this.state.entry.comments.length <= 0 ?
                                            <strong>No comments found for this article.</strong>

                                            :
                                            <>
                                                <TextField id="CreatorName" value={this.state.form.creator_name.value} error={!this.state.form.creator_name.valid && this.state.show_errors} label="Creator-Name" onChange={value => this.change_value('creator_name', value)} />

                                                {this.state.entry.comments.map((comment, comment_index) => {

                                                    return <><div className="article_comment_container">
                                                        {comment.data[0].content}
                                                    </div>
                                                        {this.state.show_replies === comment_index ?

                                                            comment.replies.map((reply, reply_index) => {
                                                                return <>
                                                                    <div className="article_comment_container reply">
                                                                        {reply.data[0].content}
                                                                    </div>
                                                                    <div className="article_comment_author"><span className="small_button" style={{ marginRight: 10 }} onClick={() => { this.delete(reply_index, 'reply', comment_index) }}>delete reply</span> {reply.creatorName}</div>
                                                                </>
                                                            })
                                                            : null}
                                                        <div className="article_comment_author"><span className="small_button" style={{ marginRight: 10 }} onClick={() => { this.delete(comment_index, 'comment') }}>delete</span>{comment.replies.length > 0 ? <span className="small_button" style={{ marginRight: 10 }} onClick={() => { this.toggle_replies(comment_index) }}>{this.state.show_replies !== comment_index ? 'show' : 'hide'} replies ({comment.replies.length})</span> : null}

                                                            {comment.creatorName}</div>

                                                    </>

                                                })
                                                }</>
                                    }
                                    <hr />
                                    <div className={!this.state.show_comment_form ? "button" : "small_button"} onClick={this.toggle_comment_form}>{!this.state.show_comment_form ? 'Show' : 'Hide'} comment form</div>
                                    {this.state.show_comment_form ? <div className="comment_form">
                                        <TextField id="Comment" style={{ width: 300, display: 'inline-block' }} value={this.state.form.comment.value} error={!this.state.form.comment.valid && this.state.show_errors} label="Comment" onChange={value => this.change_value('comment', value)} />
                                        <div style={{ marginTop: 11, marginLeft: 20 }} className="button" id="Comment" onClick={() => this.add_comment(this.state.entry_id)}>send comment</div>
                                    </div> : null}

                                </Col>
                            </Row>
                        </>
                    }
                </Container>
                <div className="sidebar_right" ref={this.sidebar_rightRef} style={{ paddingTop: 100 }}>
                    <div className="button" style={{ marginRight: 20 }} onClick={this.save}>save</div>
                    {this.state.entry_id !== 'new' ? <div className="small_button" onClick={() => { this.delete(this.state.entry_id, 'article') }}>delete</div> : null}<br />

                    <br />
                    <br />
                    <br />

                    {
                        this.state.timeChanging ?
                            <><Datetime dateFormat="DD.MM.YYYY" timeFormat={'HH:mm'} locale="de" value={this.state.form.time.value} onChange={(value) => {

                                if (Number.isInteger(Date.parse(new Date(value)))) {
                                    this.change_value('time', { target: { value: new Date(value) } })
                                }

                            }}

                                        renderInput={(props, openCalendar) =>
                                            <TextField id="time" onChange={(value) => {
                                                props.onChange(value)
                                            }} error={!this.state.form.time.valid && this.state.show_errors}
                                                       value={formatDatetime(this.state.form.time.value)} label="Time"
                                                       onClick={openCalendar}/>
                                        }

                            />
                                <div className="small_button" onClick={() => { this.setState({ timeChanging: false }) }}>set time</div>
                            </>
                            :
                            <>
                                <strong>Time</strong> {formatDatetime(this.state.form.time.value) + 'h'}
                                <div className="small_button" onClick={() => { this.setState({ timeChanging: true }) }}>change time</div>
                            </>
                    }

                    <br />
                    <br />
                    <br />

                    <FormControlLabel
                        value={true}
                        control={<CustomCheckbox checked={this.state.form.visible.value} />}
                        label={'Published'}
                        labelPlacement="end"
                        onChange={(event) => this.change_value('visible', event)}
                        style={{ marginBottom: 10 }}
                    />

                    <br />
                    <br />
                    <br />

                    <strong>Restrict publication</strong>

                    <br />
                    <br />

                    <FormControlLabel
                        value={true}
                        control={<CustomCheckbox checked={this.state.form.only_members.value} />}
                        label={'Only members'}
                        labelPlacement="end"
                        onChange={(event) => this.change_value('only_members', event)}
                        style={{ marginBottom: 10 }}
                    />

                    <br />
                    <br />
                    <br />

                    <Accordion expanded={this.state.current_panel === 'panel1'} onClick={() => this.handleAccordion('panel1')} title={"Regions (" + this.state.form.regions.value.length + ")"}>

                        {Object.keys(regions).map((key) => {
                            return <><FormControlLabel
                                value={key}
                                control={<CustomCheckbox checked={this.state.form.regions.value.includes(key)} />}
                                label={regions[key]}
                                labelPlacement="end"
                                onChange={() => this.changeRegions(key)}
                                style={{ marginBottom: 10 }}
                            /><br /></>
                        })}
                    </Accordion>
                    <br />
                    <br />
                    <br />

                    <VocationAccordion
                        value={this.state.form.vocations.value}
                        opened={this.state.current_panel === 'panel3'}
                        toggle={() => this.handleAccordion('panel3')}
                        changevalue={this.changeVocations} //getting mvzvCode
                        changeAllValues={this.changeVocationsCategories} //getting mvzvCode
                        removeAllSubjectsFromArea={this.cbRemoveAllSubjectsFromArea}
                        addAllSubjectsFromArea={this.cbAddAllSubjectsFromArea}
                    />

                    <br />
                    <br />
                    <br />
                    <div style={{flexDirection: 'column'}}>
                        <FormControlLabel
                            value={true}
                            control={<CustomCheckbox checked={this.state.pictureUpload} />}
                            label={'Image'}
                            labelPlacement="end"
                            onChange={() =>
                                this.setState({
                                    pictureUpload: true,
                                    youTubeUpload: false,
                                })}
                            style={{ width: '40%', marginBottom: 10}}
                        />
                        <FormControlLabel
                            value={true}
                            control={<CustomCheckbox checked={this.state.youTubeUpload} />}
                            label={'YouTube'}
                            labelPlacement="end"
                            onChange={() =>
                                this.setState({
                                    pictureUpload: false,
                                    youTubeUpload: true,}
                                )}
                            style={{ width: '40%', marginBottom: 10}}
                        />
                    </div>

                    <input onChange={(e) => this.setSelectedFile(e.target.files[0])} type="file" id="post_image" style={{ display: "none" }} />
                    {this.state.pictureUpload &&
                        <div>
                            <label
                                className={"upload_image_button" + (this.state.feedImageBase64 == null ? ' pre' : '')}
                                for="post_image"
                                style={
                                    this.state.feedImageBase64 !== null ? {
                                        backgroundImage: `url(${this.state.feedImageBase64 ? this.state.feedImageBase64 : this.state.post_image_path})`
                                    } : {}}>
                            </label>

                    {this.state.post_image_path !== null &&
                        <div>
                          <br/>
                          {this.state.feedImageBase64 == null ? "" :
                            <div>
                              <input type="color" id="backgroundColorPicker" /> Background<br/><br/>
                              <div className="button" style={{ marginRight: 20 }} onClick={this.autoFormat}>Auto-Format</div>
                              <div style={{ cursor: 'pointer' }} onClick={this.resetAutoFormat}>Reset Auto-Format</div>
                            </div>
                          }
                          <br/>
                          <br/>

                          <div style={{ cursor: 'pointer' }} onClick={() => {
                              this.setState({
                                  post_image: null,
                                  post_image_path: null,
                                  post_image_changed: true,
                                  feedImageBase64: null
                              });


                          }}>remove image</div>

                          <canvas style={{width: "0%"}} id="canvasAutoFormat"></canvas>
                        </div>
                    }
                        </div>
                    }
                    {this.state.youTubeUpload &&
                        <label>
                            YouTube-Link:
                            <input
                                type={"text"}
                                value={this.state.youTubeLink}
                                defaultValue={'please Enter YouTube-Link'}
                                onChange={this.changeYouTubeLink}
                                onBlur={this.changeYouTubeLinkToID}
                            />

                            {this.state.youTubeVideoID &&
                                <div>
                                    <br/>
                                    <div onClick={() => this.setState({
                                        youTubeVideoID: "",
                                        youTubeLink: "",
                                    })
                                    }>remove YouTube-Link
                                    </div>
                                    <br/>
                                    <YouTube
                                        className={"upload_image_button pre"}
                                        videoId={this.state.youTubeVideoID}
                                        opts={{
                                            playerVars: {
                                                autoplay: this.state.autoplayYouTube,
                                                mute: 1,
                                                fs: 0,
                                                iv_load_policy: 3,
                                            }
                                        }}
                                    />
                                </div>}
                        </label>
                    }

                    <br />
                    <br />
                    <br />

                </div>
                <Loading visible={this.state.loading} />
                <Popup ref={this.popup}>
                    <h3>Add variant</h3>

                    <InputLabel id="new_language">Current Variant</InputLabel>

                    <Select labelId="new_language" id="new_language" value={this.state.form.new_language.value} error={!this.state.form.new_language.valid && this.state.show_errors} onChange={(event) => this.change_value('new_language', event)}>
                        {Object.keys(languages).map((key) => {

                            if (this.state.languages.includes(key)) return null;

                            return <MenuItem value={key}>{languages[key].name}</MenuItem>

                        })}

                    </Select>

                    <div className="add_button" onClick={() => { this.add_variation() }}>Add variant</div>

                </Popup>
                <Notification ref={this.notification} />
            </>

        )
    }
}

export default CmsDetail;
