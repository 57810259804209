import React from "react";
import KeyTagUnit from "./units/KeyTagUnit";
import Loading from "../../helper/screens/Loading";
import {Col, Container, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";
import UserKeyTagTable from "./component/UserKeyTagTable";
import {wrlog} from "../../helper/misc";
import CustomCheckbox from "../../helper/screens/CustomCheckbox";

export default class KeyTagOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userKeyTags: [],
            search_value: '',
            show_errors: false,
            loading: true,
            actualItem: null,
            keyTagUnit: new KeyTagUnit(props.com),
            showOnlyLostKeyTags: false
        }
        this.popup = React.createRef();
        this.props.set_current_page('/keytags');
    }

    componentDidMount() {
        this.updateStoredKeys();
    }

    change_search_value = (value) => {
        this.setState({
            search_value: value.target.value
        })
    }

    updateStoredKeys = () => {
        this.state.keyTagUnit.getAllKeyTags(this.cbUpdateStoredUserKeys, this.cbError);
    }

    cbError = (error) => {
        wrlog('Error: ', error)
        this.setState({
            loading: false,
        })
    }

    cbUpdateStoredUserKeys = (response) => {
        this.setState({
            userKeyTags: response.data.userKeyTags,
            loading: false,
        })
    }

    listOnlyLostKeyTags() {
        this.setState({
            showOnlyLostKeyTags: !this.state.showOnlyLostKeyTags,
        })
    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row style={{paddingBottom: 50, marginTop: 44}}>
                        <Col md={8}>
                            <h1>Keys</h1>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: 50}}>
                        <Col md={8}/>
                        <Col md={4} style={{textAlign: 'right'}}>
                            <Row>
                                <TextField id="title" value={this.state.search_value}
                                           error={!this.state.search_value.valid && this.state.show_errors}
                                           label="Search Key ID"
                                           onChange={value => this.change_search_value(value)}/>
                            </Row>
                            <Row style={{height: '100%', alignItems: 'center'}}>
                                <CustomCheckbox
                                    checked={this.state.showOnlyLostKeyTags}
                                    onChange={() => {
                                        this.listOnlyLostKeyTags()
                                    }}
                                />
                                <div>
                                    show only lost key
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <UserKeyTagTable
                                userKeyTagsArray={this.state.userKeyTags}
                                searchValue={this.state.search_value}
                                showLostKeys={this.state.showOnlyLostKeyTags}
                            />
                        </Col>
                    </Row>
                </Container>
                <Loading visible={this.state.loading}/>
            </>
        );
    }
}
