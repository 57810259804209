import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import Overview from './screens/Overview';
import Login from './screens/Login';
import Communicator from './helper/Communicator';
import {has_permission, is_logged_in} from './helper/functions/auth';
import Loading from './helper/screens/Loading';
import AuthenticationUnit from './units/AuthenticationUnit';
import Push from './screens/Push';
import PushDetail from './screens/Push/PushDetail';
import Content from './screens/Content';
import ContentDetail from './screens/Content/ContentDetail';
import Modules from './screens/Modules';
import ModuleDetail from './screens/Modules/ModuleDetail';
import AdminUsers from './screens/AdminUsers';
import AdminUsersEdit from './screens/AdminUsers/Add_Edit';
import Cms from './screens/CMS';
import CmsDetail from './screens/CMS/CmsDetail';

import GlobalSettings from './screens/GlobalSettings';
import Statistics from './screens/Statistics';
import StatisticsWorkingTime from './screens/Statistics/Details/WorkingTime';
import StatisticsUsers from './screens/Statistics/Details/Users';
import ModuleVidaDialogerInnen from './screens/ModuleVidaDialogerInnen';
import NotFound from './screens/NotFound';
import UserUnit from './units/UserUnit';

import Interests from "./screens/Interests";

import logo from './assets/icons/vida_logo.png';
import ContentUnit from './units/ContentUnit';
import {BASENAME} from './store';
import StatisticsPushNotifications from './screens/Statistics/Details/PushNotifications';
import {setWorkingSubjectsAndAreas, wrlog} from './helper/misc';
import KeyTagOverview from "./screens/KeyTagOverview";

export default class App extends React.Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            show_page: true,
            current_page: '/',
            nav_open: false,
            tokenRefreshing: false
        }

        this.navRef = React.createRef()
        this.navRefBurger = React.createRef()

        this.com = new Communicator();

    }

    componentDidMount() {

        if (is_logged_in()) {

            this.refresh_token();

            if (sessionStorage.getItem('permissions') === null) {

                this.setState({
                    loading: true,
                    show_page: false
                });

            }

            let userUnit = new UserUnit(this.com);

            const cb_success = (res) => {

                wrlog('users permissions', res)

                this.setState({
                    loading: false,
                    show_page: true
                });

            }
            const cb_error = () => {
                localStorage.removeItem('token');
            }

            userUnit.get_permission_by_token(cb_success, cb_error);
        }


        const contentUnit = new ContentUnit(this.com);

        contentUnit.getAllVocatons(false, this.cbSuccessGetSubjectArea, this.cbErrorGetSubjectArea);


    }

    /**
     * Callback for
     * @param {action: "getSubjectAreas",data: {subjectAreas: Array<{children: Array<{name: string, mvzvCode: number}>, mvzvCode: number, name: string}>}} subjectsAndAreas
     * @returns void
     */
    cbSuccessGetSubjectArea = (subjectsAndAreas) => {
        const workAreaNames = subjectsAndAreas.data.subjectAreas.map(
            (singleElement) => {
                return {
                    name: singleElement.name,
                    mvzvCode: singleElement.mvzvCode,
                    children: singleElement.children && singleElement.children.map((singleSubject) => {
                        return {
                            name: singleSubject.name,
                            mvzvCode: singleSubject.mvzvCode
                        }
                    })
                }
            });

        setWorkingSubjectsAndAreas(subjectsAndAreas.data.subjectAreas).catch(error => console.log('SubjectAreaError: ', error));
    }

    cbErrorGetSubjectArea = (err) => {
        console.log('Error: ', err)
    }

    refresh_token = () => {

        this.refreshInterval = setInterval(() => {

            let token_expire_time = localStorage.getItem('token_expire_time');

            if (parseInt(token_expire_time) < new Date().getTime() && !this.state.tokenRefreshing) {

                wrlog("START REFRESH TOKEN");

                let auth = new AuthenticationUnit(this.com);

                this.setState({
                    tokenRefreshing: true
                })

                let refresh_token_success = (res) => {
                    wrlog("SUCCESS REFRESH TOKEN");

                    this.setState({
                        tokenRefreshing: false
                    })

                }

                auth.refresh_token(refresh_token_success, () => {
                    window.location.reload();
                });

            }

        }, 10 * 1000);

    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    toggleMobileNav = (state = null) => {
        if (state === null) {
            this.navRef.current.classList.toggle('open')
            this.navRefBurger.current.classList.toggle('open')
        } else {
            if (state === false) {
                this.navRefBurger.current.classList.remove('open')
                this.navRef.current.classList.remove('open')
            } else {
                this.navRefBurger.current.classList.add('open')
                this.navRef.current.classList.add('open')
            }
        }

    }

    logout = () => {

        let auth = new AuthenticationUnit(this.com);

        this.setState({ loading: true });

        localStorage.removeItem('token');
        this.setState({ loading: false });

        auth.logout();

    }

    set_current_page = (path) => {

        window.scrollTo(0, 0)

        this.setState({
            current_page: path
        });
    }

    render() {


        if (is_logged_in()) {

            return (
                <Router basename={BASENAME}>
                    <Loading visible={this.state.loading} />
                    {this.state.show_page ?
                        <div className="">
                            <div className="burger_left" ref={this.navRefBurger} onClick={
                                () => {
                                    this.toggleMobileNav()
                                }
                            }>
                                <div className="burder_line"></div>
                                <div className="burder_line"></div>
                                <div className="burder_line"></div>
                            </div>

                            <div className="sidebar" ref={this.navRef}>
                                <div className="logo">
                                    <img alt="Logo" src={logo} style={{ height: 33, width: 60, marginLeft: 40, marginRight: 10 }} /><strong style={{ lineHeight: '12px' }}>Dashboard</strong>
                                </div>
                                <nav>
                                    <ul>
                                        <details
                                            open={
                                                this.state.current_page === '/' ||
                                                this.state.current_page === '/statistics'
                                            }
                                            className="subnav_parent"
                                        >
                                            <summary>Statistics<div className="subnav_parent_arrow"></div></summary>
                                            <li className={this.state.current_page === '/' ? "current_page" : ''}>
                                                <Link onClick={() => this.toggleMobileNav(false)} to="/">Overview</Link>
                                            </li>

                                            <li className={this.state.current_page === '/statistics' ? "current_page" : ''}>
                                                <Link onClick={() => this.toggleMobileNav(false)} to="/statistics">Statistics</Link>
                                            </li>
                                        </details>

                                        <details
                                            open={
                                                this.state.current_page === '/push' || this.state.current_page === '/cms'
                                            }
                                            className="subnav_parent"
                                        >
                                            <summary>Notifications<div className="subnav_parent_arrow"></div></summary>
                                            {has_permission('pushnotification', 'canRead') ? <li className={this.state.current_page === '/push' ? "current_page" : ''}>
                                                <Link onClick={() => this.toggleMobileNav(false)} to="/push">Push-Notifications</Link>
                                            </li> : null}

                                            {has_permission('feed', 'canRead') ? <li className={this.state.current_page === '/cms' ? "current_page" : ''}>
                                                <Link onClick={() => this.toggleMobileNav(false)} to="/cms">Content Management-System</Link>
                                            </li> : null}
                                        </details>

                                        {
                                            has_permission('user', 'canRead') ||
                                                has_permission('staticcontent', 'canRead') ||
                                                has_permission('dynamicmoduleaccess', 'canRead') ||
                                                has_permission('generalsetting', 'canRead') ||
                                                has_permission('dynamicmoduleaccess', 'canRead') ?

                                                <details open={
                                                    this.state.current_page === '/content' ||
                                                    this.state.current_page === '/modules' ||
                                                    this.state.current_page === '/admin-users' ||
                                                    this.state.current_page === '/global-settings' ||
                                                    this.state.current_page === '/module-vida-dialogerinnen' ||
                                                    this.state.current_page === '/interests' ||
                                                    this.state.current_page === '/keytags'
                                                }
                                                    className="subnav_parent">
                                                    <summary>Administration<div className="subnav_parent_arrow"></div></summary>

                                                    {has_permission('user', 'canRead') ? <li className={this.state.current_page === '/admin-users' ? "current_page" : ''}>
                                                        <Link onClick={() => this.toggleMobileNav(false)} to="/admin-users">Usermanagement Dashboard</Link>
                                                    </li> : null}

                                                    {has_permission('dynamicmoduleaccess', 'canRead') ? <li className={this.state.current_page === '/module-vida-dialogerinnen' ? "current_page" : ''}>
                                                        <Link onClick={() => this.toggleMobileNav(false)} to="/module-vida-dialogerinnen">Module: vida DialogerInnen</Link>
                                                    </li> : null}

                                                    {has_permission('dynamicmoduleaccess', 'canRead') ? <li className={this.state.current_page === '/modules' ? "current_page" : ''}>
                                                        <Link onClick={() => this.toggleMobileNav(false)} to="/modules">Modules</Link>
                                                    </li> : null}

                                                    {has_permission('staticcontent', 'canRead') ?
                                                        <li className={this.state.current_page === '/content' ? "current_page" : ''}>
                                                            <Link onClick={() => this.toggleMobileNav(false)}
                                                                  to="/content">Static Content</Link>
                                                        </li> : null}

                                                    {has_permission('generalsetting', 'canRead') ?
                                                        <li className={this.state.current_page === '/global-settings' ? "current_page" : ''}>
                                                            <Link onClick={() => this.toggleMobileNav(false)}
                                                                  to="/global-settings">Chat - Destinations</Link>
                                                        </li> : null}

                                                    {has_permission('user', 'canRead') ?
                                                        <li className={this.state.current_page === '/interests' ? "current_page" : ''}>
                                                            <Link onClick={() => this.toggleMobileNav(false)}
                                                                  to="/interests">Interests</Link>
                                                        </li> : null
                                                    }

                                                    {has_permission('user', 'canRead') ?
                                                        <li className={this.state.current_page === '/keytags' ? "current_page" : ''}>
                                                            <Link onClick={() => this.toggleMobileNav(false)}
                                                                  to="/keytags">Keys</Link>
                                                        </li> : null
                                                    }
                                                </details>
                                                : null}

                                        <li style={{ marginTop: 50 }} >
                                            <div style={{ cursor: 'pointer', fontWeight: 300 }} onClick={() => {
                                                this.toggleMobileNav(false)
                                                this.logout();
                                            }}>Logout</div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="content_wrapper">
                                <Switch>

                                    <Route exact path='/' render={(props) => {
                                        return (<Overview {...props} com={this.com} set_current_page={this.set_current_page} />)
                                    }} />

                                    {has_permission('pushnotification', 'canRead') ? <Route exact path='/push' render={(props) => {
                                        return (<Push {...props} com={this.com} set_current_page={this.set_current_page} />)
                                    }} /> : null}
                                    {has_permission('pushnotification', 'canRead') ? <Route exact path='/push/:id' render={(props) => {
                                        return (<PushDetail {...props} com={this.com} set_current_page={this.set_current_page} />)
                                    }} /> : null}


                                    {has_permission('staticcontent', 'canRead') ? <Route exact path='/content' render={(props) => {
                                        return (<Content {...props} com={this.com} set_current_page={this.set_current_page} />)
                                    }} /> : null}
                                    {has_permission('staticcontent', 'canRead') ? <Route exact path='/content/:id/:language' render={(props) => {
                                        return (<ContentDetail {...props} com={this.com} set_current_page={this.set_current_page} />)
                                    }} /> : null}

                                    {has_permission('dynamicmoduleaccess', 'canRead') ? <Route exact path='/modules' render={(props) => {
                                        return (<Modules {...props} com={this.com} set_current_page={this.set_current_page} />)
                                    }} /> : null}
                                    {has_permission('dynamicmoduleaccess', 'canRead') ?
                                        <Route exact path='/modules/:id' render={(props) => {
                                            return (<ModuleDetail {...props} com={this.com}
                                                                  set_current_page={this.set_current_page}/>)
                                        }}/> : null}

                                    {has_permission('user', 'canRead') ?
                                        <Route exact path='/admin-users' render={(props) => {
                                            return (<AdminUsers {...props} com={this.com}
                                                                set_current_page={this.set_current_page}/>)
                                        }}/> : null}
                                    {has_permission('user', 'canRead') ?
                                        <Route exact path='/admin-users/:id' render={(props) => {
                                            return (<AdminUsersEdit {...props} com={this.com}
                                                                    set_current_page={this.set_current_page}/>)
                                        }}/> : null}

                                    {has_permission('user', 'canRead') ?
                                        <Route exact path='/interests' render={(props) => {
                                            return (<Interests {...props} com={this.com}
                                                               set_current_page={this.set_current_page}/>)
                                        }}/> : null}

                                    {has_permission('user', 'canRead') ?
                                        <Route exact path='/keytags' render={(props) => {
                                            return (<KeyTagOverview {...props} com={this.com}
                                                                    set_current_page={this.set_current_page}/>)
                                        }}/> : null}


                                    {// EDIT : has_permission cms
                                    }
                                    {has_permission('feed', 'canRead') ? <Route exact path='/cms' render={(props) => {
                                        return (
                                            <Cms {...props} com={this.com} set_current_page={this.set_current_page}/>)
                                    }}/> : null}
                                    {has_permission('feed', 'canRead') ?
                                        <Route exact path='/cms/:id' render={(props) => {
                                            return (<CmsDetail {...props} com={this.com}
                                                               set_current_page={this.set_current_page}/>)
                                        }}/> : null}
                                    {has_permission('feed', 'canRead') ? <Route exact path='/cms/:id/:language' render={(props) => {
                                        return (<CmsDetail {...props} com={this.com} set_current_page={this.set_current_page} />)
                                    }} /> : null}

                                    {has_permission('generalsetting', 'canRead') ? <Route exact path='/global-settings' render={(props) => {
                                        return (<GlobalSettings {...props} com={this.com} set_current_page={this.set_current_page} />)
                                    }} /> : null}



                                    <Route exact path='/statistics' render={(props) => {
                                        return (<Statistics {...props} com={this.com} set_current_page={this.set_current_page} />)
                                    }} />

                                    <Route exact path='/statistics/working' render={(props) => {
                                        return (<StatisticsWorkingTime {...props} com={this.com} set_current_page={this.set_current_page} />)
                                    }} />

                                    <Route exact path='/statistics/users' render={(props) => {
                                        return (<StatisticsUsers {...props} com={this.com} set_current_page={this.set_current_page} />)
                                    }} />

                                    {has_permission('generalsetting', 'canRead') ? <Route exact path='/statistics/push' render={(props) => {
                                        return (<StatisticsPushNotifications {...props} com={this.com} set_current_page={this.set_current_page} />)
                                    }} /> : null}


                                    {has_permission('dynamicmoduleaccess', 'canRead') ? <Route exact path='/module-vida-dialogerinnen' render={(props) => {
                                        return (<ModuleVidaDialogerInnen {...props} com={this.com} set_current_page={this.set_current_page} />)
                                    }} /> : null}

                                    <Route component={NotFound} />

                                </Switch>
                            </div>
                        </div> : null}
                </Router>
            );

        } else {

            return <Login com={this.com} />

        }
    }
}
