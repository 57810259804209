import {Table, TableBody, TableCell, TableHead, TableRow, TextField} from '@material-ui/core';
import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {calculateLocalTimezone, formatDatetime, formatTimeToUnix, wrlog} from '../../helper/misc';
import Loading from '../../helper/screens/Loading';
import PushUnit from '../../units/PushUnit';

import sent_icon from '../../assets/icons/sent.png';
import scheduled_icon from '../../assets/icons/scheduled.png';
import waiting_icon from '../../assets/icons/waiting.png';
import Switch from '../../helper/screens/Switch';
import {get_title} from '../../helper/functions/push';

export default class Push extends React.Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            show_errors: false,
            push_notifications: [],
            partial_loading: false,
            switch: 'draft',
            search_value: '',
        }

        this.notification = new React.createRef();
    }

    componentDidMount() {

        this.props.set_current_page(this.props.location.pathname);
        wrlog("PATH", this.props.location)
        if (this.props.location !== undefined &&
            this.props.location.state !== undefined &&
            this.props.location.state.filter !== undefined
        ) {
            this.setState({
                switch: this.props.location.state.filter
            })
        }

        let push_notifications = JSON.parse(sessionStorage.getItem('push_notification_list'));

        if (push_notifications === null) {

            this.setState({
                loading: true
            });

        } else {

            let notificationList = push_notifications.data.pushNotifications.sort((a, b) => {
                return b.id - a.id;
            })

            this.setState({
                push_notifications: notificationList,
            })

        }

        this.load_content();

    }

    change_search_value = (value) => {
        this.setState({
            search_value: value.target.value
        })
    }

    load_content = () => {

        let notifications = new PushUnit(this.props.com);

        this.setState({
            partial_loading: true
        });

        let cb_success = (res) => {

            let notificationList = res.data.pushNotifications.sort((a, b) => {
                return b.id - a.id;
            })

            this.setState({
                push_notifications: notificationList,
                loading: false,
                partial_loading: false
            });

        }

        let cb_error = (res) => {
            this.setState({
                loading: false,
                partial_loading: false
            });
        }

        notifications.list(cb_success, cb_error);

    }

    get_destination = (action) => {
        try {
            action = JSON.parse(action)
        } catch {
            return 'N/A';
        }


        if (action.type === 'feed') return 'Feedentry';

        if (action.type === 'module') {
            return 'module > ' + action.screen;
        }

        return 'open app'

    }

    switch_changed = (value) => {

        this.setState({
            switch: value
        })

    }

    render_list = () => {

        if (this.state.push_notifications.length < 1) return null;

        let filtered = this.state.push_notifications;


        filtered = this.state.push_notifications.filter(notification => {


            let type = 'draft';
            if (notification.sent === true) {
                type = 'sent';
            } else if (notification.time !== null) {
                type = 'scheduled';
            }

            let search_valid = true;

            if (this.state.search_value !== '') {
                search_valid = get_title(notification).toLowerCase().includes(this.state.search_value.toLowerCase()) ||
                    (notification.internalTitle !== null && notification.internalTitle.toLowerCase().includes(this.state.search_value.toLowerCase()))
            }

            return (this.state.switch === 'all' || this.state.switch === type) && search_valid;

        });

        filtered = filtered.sort((a, b) => {

            if (this.state.switch === 'scheduled') {

                if (a.time !== null && b.time !== null) {
                    return formatTimeToUnix(a.time) - formatTimeToUnix(b.time);
                }

            } if (this.state.switch === 'draft') {

                if (get_title(a) !== null && get_title(b) !== null) {
                    return get_title(a) - get_title(b)
                }

            } else {

                if (b.time !== null && a.time !== null) {
                    return formatTimeToUnix(b.time) - formatTimeToUnix(a.time);
                }

            }


            return b.id - a.id;

        })

        return filtered.map((notification, key) => {

            let type = 'draft';
            let icon = waiting_icon;
            let width = 18;
            if (notification.sent === true) {
                type = 'sent';
                icon = sent_icon;
                width = 20;
            } else if (notification.time !== null) {
                type = 'scheduled';
                icon = scheduled_icon;
                width = 25;
            }

            return <TableRow key={key}>
                <TableCell style={{ textAlign: 'center', width: 25 }}><img alt="pushnotificatio_state-icon" src={icon} style={{ width: width }} /></TableCell>
                <TableCell>{notification.internalTitle !== '' && notification.internalTitle !== null ? notification.internalTitle : get_title(notification)}</TableCell>
                <TableCell>{notification.time !== null ? type === 'sent' ? formatDatetime(notification.time) : calculateLocalTimezone(notification.time) : null}</TableCell>
                <TableCell>{notification.numberOfTargets}</TableCell>
                <TableCell>{this.get_destination(notification.action)}</TableCell>
                <TableCell className="align-right">
                    <Link
                        to={{
                            pathname: "/push/" + notification.id + "/",
                            state: { filter: this.state.switch }
                        }}
                    >{notification.sent === true ? 'show' : 'edit'}</Link>
                </TableCell>
            </TableRow >
        })

    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row style={{ paddingBottom: 50, marginTop: 44 }}>
                        <Col md={8}>
                            <h1>Push-Notifications</h1>
                        </Col>
                        <Col md={4} className="align_right_desktop">
                            <Link
                                to={{
                                    pathname: "/push/new/",
                                    state: { filter: this.state.switch }
                                }}
                                className="add_button">New Push-Notification</Link>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: 50 }}>
                        <Col md={8} style={{ paddingTop: 16 }}>
                            <Switch onChange={() => this.switch_changed('all')} selected={this.state.switch === 'all'} title="All" />
                            <Switch onChange={() => this.switch_changed('draft')} selected={this.state.switch === 'draft'} title="Draft" />
                            <Switch onChange={() => this.switch_changed('scheduled')} selected={this.state.switch === 'scheduled'} title="Scheduled" />
                            <Switch onChange={() => this.switch_changed('sent')} selected={this.state.switch === 'sent'} title="Sent" />
                        </Col>
                        <Col md={4} style={{ textAlign: 'right' }}>
                            <TextField id="title" value={this.state.search_value} error={!this.state.search_value.valid && this.state.show_errors} label="Search" onChange={value => this.change_search_value(value)} />
                        </Col>
                    </Row>


                    <Row>
                        <Col md={12}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Date - Time</TableCell>
                                        <TableCell>Targets</TableCell>
                                        <TableCell>Destination</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{this.state.partial_loading ? <div><Loading visible={true} relative style={{ width: 20, height: 20 }} container_style={{ width: 20, height: 20, display: 'inline-block', verticalAlign: 'middle', marginRight: 10 }} /> Synchronizing with server</div> : null}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>

                                    {this.render_list()}

                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                <Loading visible={this.state.loading} />
            </>

        )
    }
}
