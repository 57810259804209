import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Loading from '../../../../helper/screens/Loading';

import {FormControlLabel} from '@material-ui/core';

import CustomCheckbox from '../../../../helper/screens/CustomCheckbox';
import TimeTrackingUnit from '../../../../units/TimeTrackingUnit';
import {getWorkingSubjectsAndAreas, wrlog} from '../../../../helper/misc';

export default class StatisticsWorkingTime extends React.Component {

    constructor() {
        super();

        this.state = {
            loading: true,
            is_404: false,
            statistics: {},
            current_panel: 'panel1',
            form: {
                regions: {
                    value: []
                },
                vocations: {
                    value: []
                },
                timeperiod: {
                    value: 'full'
                }
            },
            work: 0,
            pause: 0,
            break: 0,
            overtime: 0,
            workSubjectAndAreas: null
        }

        this.sidebar_rightRef = new React.createRef();
        this.sidebar_right_burgerRef = new React.createRef();



    }

    componentDidMount() {

        const time_tracking_unit = new TimeTrackingUnit(this.props.com);
        this.props.set_current_page('/statistics');

        let cb_success = (res) => {

            wrlog(res.data.statistics);

            this.setState({
                statistics: res.data.statistics,
                loading: false,
                partial_loading: false
            });

            this.changePeriod("full")

        }

        let cb_error = (res) => {
            this.setState({
                loading: false,
                partial_loading: false
            });
        }

        time_tracking_unit.loadStatistics(cb_success, cb_error);

        this.getWorkSubjectAndAreas();

    }

    getWorkSubjectAndAreas = () => {
        getWorkingSubjectsAndAreas().then((result) => {
            this.setState({
                workSubjectAndAreas: JSON.parse(result.toString())
            })
        })
            .catch((error) => {
                wrlog('LoadingSubjectAreaError: ', error)
            })
    }

    toggleMobileNav = (force_action = false) => {

        if (force_action === 'close') {

            this.sidebar_rightRef.current.classList.remove('open')
            this.sidebar_right_burgerRef.current.classList.remove('open')

        } else if (force_action === 'open') {

            this.sidebar_rightRef.current.classList.add('open')
            this.sidebar_right_burgerRef.current.classList.add('open')

        } else {
            this.sidebar_rightRef.current.classList.toggle('open')
            this.sidebar_right_burgerRef.current.classList.toggle('open')
        }


    }

    changeRegions = (value) => {

        let formCopy = this.state.form;
        let selectedRegions = formCopy.regions.value;
        let position = selectedRegions.indexOf(value);

        if (position > -1) {
            selectedRegions.splice(position, 1);
        } else {
            selectedRegions.push(value);
        }

        formCopy.regions.value = selectedRegions;

        this.setState({
            form: formCopy
        })

    }

    /**
     * cbForChangeingTheSubject
     * @param {int} value
     */
    changeVocations = (value) => {

        let formCopy = this.state.form;
        let selectedVocations = formCopy.vocations.value;
        let position = selectedVocations.indexOf(value);

        if (position > -1) {
            selectedVocations.splice(position, 1);
        } else {
            selectedVocations.push(value);
        }

        formCopy.vocations.value = selectedVocations;

        this.setState({
            form: formCopy
        })
    }

    /**
     * cbForChangeingTheSubject
     * @param {int} key
     */
    changeVocationsCategories = (key) => {
        let formCopy = this.state.form;
        let selectedVocations = formCopy.vocations.value;

        // DESELECT ALL

        let selected_all = true;

        if (!selectedVocations.includes(key)) {
            selected_all = false;
        }

        let new_selected_vocations = [];

        if (selected_all) {

            selectedVocations.forEach((mvzvCode) => {
                if (mvzvCode !== key) {
                    new_selected_vocations.push(key);
                }
            })
        } else {

            new_selected_vocations = selectedVocations;

            if (!new_selected_vocations.includes(key)) {
                new_selected_vocations.push(key);
            }
        }

        formCopy.vocations.value = new_selected_vocations;

        this.setState({
            form: formCopy,
        })


    }

    changePeriod = (value) => {

        let formCopy = this.state.form;

        formCopy.timeperiod.value = value;

        this.setState({ form: formCopy });

        let year = new Date().getFullYear();
        let month = new Date().getMonth();
        let day = new Date().getDate();

        let output = {
            work: 0,
            pause: 0,
            break: 0,
            overtime: 0
        };

        switch (value) {
            case 'last_7_days':

                let today = new Date(year, month, day, 0, 0, 0).getTime();

                for (let i = 0; i < 7; i++) {

                    let new_day = new Date(today)


                    this.state.statistics.daily.forEach(date => {


                        if (new_day.getDate() === date.day && new_day.getMonth() + 1 === date.month && new_day.getFullYear() === date.year) {

                            console.log(date);

                            if (date.type === 'WORK') {
                                output.work = output.work + date.seconds;
                            } else if (date.type === 'PAUSE') {
                                output.pause = output.pause + date.seconds;
                            } else if (date.type === 'BREAK') {
                                output.break = output.break + date.seconds;
                            } else if (date.type === 'OVERTIME') {
                                output.overtime = output.overtime + date.seconds;
                            }
                        }

                    })

                    today = today - (1000 * 60 * 60 * 24);

                }


                break;

            case 'this_month':

                this.state.statistics.monthly.forEach(date => {
                    if (month + 1 === date.month && year === date.year) {
                        if (date.type === 'WORK') {
                            output.work = output.work + date.seconds;
                        } else if (date.type === 'PAUSE') {
                            output.pause = output.pause + date.seconds;
                        } else if (date.type === 'BREAK') {
                            output.break = output.break + date.seconds;
                        } else if (date.type === 'OVERTIME') {
                            output.overtime = output.overtime + date.seconds;
                        }
                    }
                })

                break;

            case 'last_month':
                this.state.statistics.monthly.forEach(date => {
                    if (month === date.month && year === date.year) {
                        if (date.type === 'WORK') {
                            output.work = output.work + date.seconds;
                        } else if (date.type === 'PAUSE') {
                            output.pause = output.pause + date.seconds;
                        } else if (date.type === 'BREAK') {
                            output.break = output.break + date.seconds;
                        } else if (date.type === 'OVERTIME') {
                            output.overtime = output.overtime + date.seconds;
                        }
                    }
                })

                break;

            case 'this_year':
                this.state.statistics.yearly.forEach(date => {
                    if (year === date.year) {
                        if (date.type === 'WORK') {
                            output.work = output.work + date.seconds;
                        } else if (date.type === 'PAUSE') {
                            output.pause = output.pause + date.seconds;
                        } else if (date.type === 'BREAK') {
                            output.break = output.break + date.seconds;
                        } else if (date.type === 'OVERTIME') {
                            output.overtime = output.overtime + date.seconds;
                        }
                    }
                })

                break;

            case 'last_year':
                this.state.statistics.yearly.forEach(date => {
                    if (year - 1 === date.year) {
                        if (date.type === 'WORK') {
                            output.work = output.work + date.seconds;
                        } else if (date.type === 'PAUSE') {
                            output.pause = output.pause + date.seconds;
                        } else if (date.type === 'BREAK') {
                            output.break = output.break + date.seconds;
                        } else if (date.type === 'OVERTIME') {
                            output.overtime = output.overtime + date.seconds;
                        }
                    }
                })

                break;

            default:

                this.state.statistics.full.forEach(date => {
                    if (date.type === 'WORK') {
                        output.work = output.work + date.seconds;
                    } else if (date.type === 'PAUSE') {
                        output.pause = output.pause + date.seconds;
                    } else if (date.type === 'BREAK') {
                        output.break = output.break + date.seconds;
                    } else if (date.type === 'OVERTIME') {
                        output.overtime = output.overtime + date.seconds;
                    }
                })

                break;

        }

        this.toggleMobileNav('close')

        this.setState({
            work: output.work,
            pause: output.pause,
            break: output.break,
            overtime: output.overtime,
        })

    }

    handleAccordion = (panel) => {

        if (this.state.current_panel === panel) panel = null;

        this.setState({
            current_panel: panel
        })
    }


    render() {

        let work_hours = parseInt(this.state.work / 60 / 60);
        let pause_hours = parseInt(this.state.pause / 60 / 60);
        let break_hours = parseInt(this.state.break / 60 / 60);
        let overtime_hours = parseInt(this.state.overtime / 60 / 60);

        return (
            <>

                <div
                    className="burger_right"
                    ref={this.sidebar_right_burgerRef}
                    onClick={() => {
                        this.toggleMobileNav()
                    }}
                />

                <Container fluid className="middle_container" style={{ marginLeft: 0 }}>
                    <Row>
                        <Col md={12} style={{ paddingBottom: 20 }}>
                            <Link className="go_back_button" to="/statistics">Go back</Link><br /><br />
                            <h1>Working time</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <h2>{(work_hours + break_hours)}</h2>
                            <strong>Worked hours</strong>
                        </Col>
                        <Col md={3}>
                            <h2>{pause_hours}</h2>
                            <strong>Pause hours</strong>
                        </Col>
                        <Col md={3}>
                            <h2>{overtime_hours}</h2>
                            <strong>Overtime hours</strong>
                        </Col>
                    </Row>

                </Container>
                <div className="sidebar_right" ref={this.sidebar_rightRef} style={{ paddingTop: 100, backgroundColor: 'white' }}>

                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'full'} />}
                        label={'All Time'}
                        labelPlacement="end"
                        onChange={(event) => this.changePeriod("full")}
                        style={{ marginBottom: 10 }}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'last_7_days'} />}
                        label={'Last 7 days'}
                        labelPlacement="end"
                        onChange={(event) => this.changePeriod("last_7_days")}
                        style={{ marginBottom: 10 }}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'this_month'} />}
                        label={'This Month'}
                        labelPlacement="end"
                        onChange={(event) => this.changePeriod("this_month")}
                        style={{ marginBottom: 10 }}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'last_month'} />}
                        label={'Last Month'}
                        labelPlacement="end"
                        onChange={(event) => this.changePeriod("last_month")}
                        style={{ marginBottom: 10 }}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'this_year'} />}
                        label={'This Year'}
                        labelPlacement="end"
                        onChange={(event) => this.changePeriod("this_year")}
                        style={{ marginBottom: 10 }}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'last_year'} />}
                        label={'Last Year'}
                        labelPlacement="end"
                        onChange={(event) => this.changePeriod("last_year")}
                        style={{ marginBottom: 10 }}
                    />

                    <br />
                    <br />
                    <br />

                </div>
                <Loading visible={this.state.loading} />
            </>

        )
    }
}
