import {FormControlLabel, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import {validation} from '../../../helper/validation';
import Notification from '../../../helper/screens/Notification';
import Popup from '../../../helper/screens/Popup';

import regions from '../../../helper/data/regions.json';
import CustomCheckbox from '../../../helper/screens/CustomCheckbox';
import DynamicModuleAccessUnit from '../../../units/DynamicModuleAccessUnit';
import VocationAccordion from '../../../helper/screens/VocationAccordion';
import {getWorkingSubjectsAndAreas, wrlog} from '../../../helper/misc';
import {v4 as uuidv4} from 'uuid';

export default class ModuleDetail extends React.Component {

    constructor() {

        super();

        this.state = {
            loading: true,
            show_errors: false,
            is_404: false,
            destinations: [],
            module_id: false,
            module_title: '',
            current_panel: null,
            rules: [],
            ruleSets: {},
            ruleType: 'single',
            removingGroupNames: [],
            form: {
                region: {
                    valid: true,
                    value: '',
                    rules: {
                    }
                },
                vocation: {
                    valid: true,
                    value: "",
                    rules: {
                    }
                },
                regions: {
                    valid: true,
                    value: Object.keys(regions),
                    rules: {
                    }
                },
                vocations: {
                    valid: true,
                    value: [],
                    rules: {}
                },
                ismember: {
                    valid: true,
                    value: false,
                    rules: {}
                },
            },
            workSubjectsAndAreas: null
        }

        this.notification = React.createRef();
        this.popup = React.createRef();

    }


    componentDidMount() {

        this.props.set_current_page("/modules");

        let dynamic_access_modules_unit = new DynamicModuleAccessUnit(this.props.com);

        let module_id = this.props.match.params.id;
        let module_title = dynamic_access_modules_unit.get_title(module_id);

        let is_404 = false;
        if (module_title === false) is_404 = true;

        getWorkingSubjectsAndAreas().then((result) => {
            this.cbLoadWorkingSubjectsAndAreas(result)
        }).catch((error) => wrlog('Error: ', error));

        this.setState({
            module_id: module_id,
            module_title: module_title,
            is_404: is_404
        })

        this.get_rules(true);

    }

    /**
     * loading SubjectsAndAreas from session-Storage
     * @param {name: string, mvzvCode: number, children?: {name: string, mvzvCode: number}} workSubjectsAndAreas
     */
    cbLoadWorkingSubjectsAndAreas = (workSubjectsAndAreas) => {
        this.setState({
            workSubjectsAndAreas: JSON.parse(workSubjectsAndAreas),
        })
    }

    groupRules = (rules) => {

        let groups = {};
        let singleRules = [];

        rules.forEach(rule => {

            if (rule.groupname === "") {

                singleRules.push(rule);

            } else {

                if (groups[rule.groupname] === undefined) {
                    groups[rule.groupname] = [];
                }

                groups[rule.groupname].push(rule);

            }

        })

        this.setState({
            ruleSets: groups,
            rules: singleRules
        })

    }

    get_rules = (init = false) => {

        let dynamic_access_modules_unit = new DynamicModuleAccessUnit(this.props.com);

        if (sessionStorage.getItem('dynamic_access_modules_list') !== null && init === true) {

            let rules = [];
            const check = JSON.parse(sessionStorage.getItem('dynamic_access_modules_list'));
            check && check.data && check.data.rules && check.data.rules.forEach((rule) => {
                if (rule && rule.module === this.state.module_id)
                    rules.push(rule);
                else
                    return;
            });


            this.groupRules(rules)

        }

        let cb_success = (res) => {

            let rules = [];

            res && res.data && res.data.rules && res.data.rules.forEach(rule => {
                if (rule && rule.module === this.state.module_id)
                    rules.push(rule);
                else
                    return;
            })

            this.groupRules(rules)

            this.setState({
                loading: false
            })

        }

        let eb_error = () => {

        }

        dynamic_access_modules_unit.get(cb_success, eb_error);

    }


    /* Add Destination*/

    add_rule = () => {

        let formCopy = this.state.form;
        let sendData = {};

        if (formCopy.region.value !== '') {
            sendData['region'] = formCopy.region.value;
        }

        if (formCopy.vocation.value !== '') {
            sendData['subjectArea'] = formCopy.vocation.value;
        }

        if (formCopy.ismember.value !== false) {
            sendData['ismember'] = formCopy.ismember.value;
        }

        sendData['module'] = this.state.module_id;

        this.setState({
            loading: true
        })

        let that = this;

        let dynamic_access_modules_unit = new DynamicModuleAccessUnit(this.props.com);

        let cb_success = () => {

            let rulesCopy = that.state.rules;

            rulesCopy.push(sendData);

            that.notification.current.open('success', 'Rule succesfully added');
            that.popup.current.close();

            this.setState({
                loading: false,
                rules: rulesCopy
            });

            that.get_rules();

        }

        let cb_error = () => {

            that.popup.current.close();

            that.setState({
                loading: false,
                show_errors: true
            })

            this.notification.current.open('error', 'An error occured, please try again');

        }

        dynamic_access_modules_unit.set(sendData, cb_success, cb_error);

    }


    combineRules = () => {

        const newUUID = uuidv4();

        let rules = [];
        const ismember = this.state.form.ismember.value;

        if (this.state.form.regions.value.length > 0) {

            this.state.form.regions.value.forEach(region => {

                if (this.state.form.vocations.value.length > 0) {

                    this.state.form.vocations.value.forEach(vocation => {

                        let rule = {
                            module: this.state.module_id,
                            groupname: newUUID
                        };

                        if (ismember === true) rule['ismember'] = true;

                        rule['region'] = region;
                        rule['subjectArea'] = vocation;

                        rules.push(rule);

                    })

                } else {

                    let rule = {
                        module: this.state.module_id,
                        groupname: newUUID
                    };

                    if (ismember === true) rule['ismember'] = true;

                    rule['region'] = region;

                    rules.push(rule);

                }

            })

        } else {

            if (this.state.form.vocations.value.length > 0) {

                this.state.form.vocations.value.forEach(vocation => {

                    let rule = {
                        module: this.state.module_id,
                        groupname: newUUID
                    };

                    if (ismember === true) rule['ismember'] = true;

                    rule['subjectArea'] = vocation;

                    rules.push(rule);

                })

            } else {

                if (ismember === true) rules.push({
                    module: this.state.module_id,
                    ismember: true,
                    groupname: newUUID
                });

            }

        }

        return rules;

    }

    addRuleSet = () => {

        let rules = this.combineRules();

        if (rules.length < 2) {

            this.notification.current.open('error', 'Please apply at least two rules or add a single rule');

            return false;
        }

        this.setState({
            loading: true
        })

        let that = this;

        let dynamic_access_modules_unit = new DynamicModuleAccessUnit(this.props.com);

        let cb_success = () => {

            that.notification.current.open('success', 'Rule succesfully added');
            that.popup.current.close();

            that.get_rules();
            that.resetForm();
        }

        let cb_error = () => {

            that.popup.current.close();

            that.setState({
                loading: false,
                show_errors: true
            })

            this.notification.current.open('error', 'An error occured, please try again');

        }

        dynamic_access_modules_unit.setMultiple(rules, cb_success, cb_error);

    }

    resetForm = () => {
        let formCopy = this.state.form;

        formCopy.vocations.value = [];
        formCopy.ismember.value = false;
        formCopy.regions.value = Object.keys(regions);

        this.setState({
            form: formCopy
        })

    }

    delete_rule = (key) => {

        let dynamic_access_modules_unit = new DynamicModuleAccessUnit(this.props.com);

        let rulesCopy = this.state.rules;

        let id = rulesCopy[key].id;

        rulesCopy[key].removing = true;

        this.setState({
            rules: rulesCopy
        })


        let cb_success = () => {

            if (this.notification.current !== null) {
                this.notification.current.open('success', 'Rule succesfully removed');
            }

            this.get_rules();

        }

        let cb_error = () => {

            this.setState({
                loading: false,
                show_errors: true
            })

            this.notification.current.open('error', 'An error occured, please try again');

        }

        dynamic_access_modules_unit.delete(id, cb_success, cb_error);

    }

    deleteRuleSet = (ruleGroupName) => {

        let ruleSet = this.state.ruleSets[ruleGroupName];
        let dynamic_access_modules_unit = new DynamicModuleAccessUnit(this.props.com);

        console.log("ruleSet", ruleSet);
        let ruleIds = [];
        ruleSet.forEach(rule => {
            ruleIds.push(rule.id)
        })
        console.log("ruleSet IDS", ruleIds);

        let removingGroupNames = this.state.removingGroupNames;
        removingGroupNames.push(ruleGroupName);
        this.setState({
            removingGroupNames: removingGroupNames
        });


        const cbSuccess = () => {

            if (this.notification.current !== null) {
                this.notification.current.open('success', 'Rule succesfully removed');
            }

            this.get_rules();

        }

        const cbError = () => {
            this.notification.current.open('error', 'An error occured, please try again');
        }

        dynamic_access_modules_unit.deleteMultiple(ruleIds, cbSuccess, cbError);

    }

    change_value = (key, value) => {

        let formCopy = this.state.form;

        if (key === 'ismember') {
            value = !this.state.form.ismember.value;
        }

        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }

    changeVocationValue = (key) => {

        let formCopy = this.state.form;

        if (formCopy.vocations.includes(key)) {
            formCopy.vocations.splice(key, 1);
        } else {
            formCopy.vocations.push(key);
        }

        this.setState({ form: formCopy })

    }

    changeRegionValue = (key) => {

        let formCopy = this.state.form;

        if (formCopy.regions.includes(key)) {
            formCopy.regions.splice(key, 1);
        } else {
            formCopy.regions.push(key);
        }

        this.setState({ form: formCopy })

    }

    _render_list = (rule) => {

        if (
            rule.subjectArea === undefined &&
            rule.region === undefined &&
            rule.ismember === undefined &&
            rule.appuser === undefined &&
            rule.user === undefined) {
            return 'Everybody';
        }

        const rules = [];

        if (rule.subjectArea !== undefined) {
            this.state.workSubjectsAndAreas && this.state.workSubjectsAndAreas.forEach((singleWorkArea) => {
                if (singleWorkArea.mvzvCode === rule.subjectArea) {
                    rules.push('Worksubject: ' + singleWorkArea.name + ' (' + singleWorkArea.mvzvCode + ')');
                } else {
                    singleWorkArea.children && singleWorkArea.children.forEach((singleWorkSubject) => {
                        singleWorkSubject.mvzvCode === rule.subjectArea && rules.push('Worksubject: ' + singleWorkSubject.name + ' (' + singleWorkSubject.mvzvCode + ')');
                    })
                }
            })
        }
        ;
        if (rule.region !== undefined) rules.push('Region: ' + regions[rule.region]);
        if (rule.ismember !== undefined) rules.push('Nur Mitglieder');
        if (rule.user !== undefined) rules.push('User: #' + rule.user.id + ' (' + rule.user.firstname + ' ' + rule.user.lastname + ', ' + rule.user.email + ')');
        if (rule.appuser !== undefined) rules.push('User: #' + rule.appuser.id + ' (' + rule.appuser.email + ')');

        return rules.join(', ');

    }

    //rendering for displaying of vacations!!!
    groupVocations = (ruleVocations) => {

        const output = [];
        this.state.workSubjectsAndAreas && this.state.workSubjectsAndAreas.forEach((workSubjectArea) => {
            ruleVocations.includes(workSubjectArea.mvzvCode) && output.push(workSubjectArea);

            workSubjectArea.children && workSubjectArea.children.forEach((workSubject) => {
                if (ruleVocations.includes(workSubject.mvzvCode)) {
                    output.push(workSubject);
                }
            })
        });


        return output.map((res) => res.name + ' (' + res.mvzvCode + ')').join(', ');
    }

    renderGroup = (ruleSet) => {

        let rules = {
            regions: [],
            vocations: [],
            isMember: false
        };

        if (ruleSet[0].ismember) rules.isMember = true;

        ruleSet.forEach(rule => {

            if (rule.subjectArea !== undefined && !rules.vocations.includes(rule.subjectArea)) rules.vocations.push(rule.subjectArea)
            if (rule.region !== undefined && !rules.regions.includes(regions[rule.region])) rules.regions.push(regions[rule.region])

        })

        return <>
            {rules.isMember === true ? <><strong>Only members</strong><br /></> : null}
            {rules.vocations.length > 0 ? <><strong>Vocations:</strong> {this.groupVocations(rules.vocations)}{rules.regions.length > 0 ? <br /> : null}</> : null}
            {rules.regions.length > 0 ? <><strong>Regions:</strong> {rules.regions.join(', ')}</> : null}
        </>

    }

    changeRegions = (value) => {

        let formCopy = this.state.form;
        let selectedRegions = formCopy.regions.value;
        let position = selectedRegions.indexOf(value);

        if (position > -1) {
            selectedRegions.splice(position, 1);
        } else {
            selectedRegions.push(value);
        }

        formCopy.regions.value = selectedRegions;

        this.setState({
            rules_updated: true,
            form: formCopy
        })

    }

    changeVocations = (value) => {

        let formCopy = this.state.form;
        let selectedVocations = formCopy.vocations.value;
        let position = selectedVocations.indexOf(value);

        if (position > -1) {
            selectedVocations.splice(position, 1);
        } else {
            selectedVocations.push(value);
        }

        formCopy.vocations.value = selectedVocations;

        this.setState({
            rules_updated: true,
            form: formCopy
        })

    }

    /**
     * cbForChangeingTheSubject
     * @param {int} key
     */
    changeVocationsCategories = (key) => {
        let formCopy = this.state.form;
        let selectedVocations = formCopy.vocations.value;

        // DESELECT ALL

        let selected_all = true;

        if (!selectedVocations.includes(key)) {
            selected_all = false;
        }

        let new_selected_vocations = [];

        if (selected_all) {
            selectedVocations.forEach((mvzvCode) => {
                if (mvzvCode !== key) {
                    new_selected_vocations.push(key);
                }
            })
        } else {
            new_selected_vocations = selectedVocations;

            if (!new_selected_vocations.includes(key)) {
                new_selected_vocations.push(key);
            }
        }

        formCopy.vocations.value = new_selected_vocations;

        this.setState({
            rules_updated: true,
            form: formCopy,
        })
    }

    /**
     * cbAddAllSubjectsFromArea
     * @param {name: string, mvzvCode: number, children: {name: string, mvzvCode: number}} subjectsFromArea
     * */
    cbAddAllSubjectsFromArea = (subjectsFromArea) => {
        const newSubjects = [...this.state.form.vocations.value];

        !newSubjects.includes(subjectsFromArea.mvzvCode) && newSubjects.push(subjectsFromArea.mvzvCode);

        subjectsFromArea.children && subjectsFromArea.children.map((singleSubject) => {
            !newSubjects.includes(singleSubject.mvzvCode) && newSubjects.push(singleSubject.mvzvCode);
        });

        this.setState({
            rules_updated: true,
            form: {
                ...this.state.form,
                vocations: {
                    ...this.state.form.vocations,
                    value: newSubjects
                }
            }
        })
    }

    /**
     * cbRemoveAllSubjectsFromArea
     * @param {name: string, mvzvCode: number, children: Array<{name: string, mvzvCode: number}>} subjectsFromArea
     * */
    cbRemoveAllSubjectsFromArea = (subjectsFromArea) => {
        const elementsToRemove = [subjectsFromArea.mvzvCode];

        subjectsFromArea.children && subjectsFromArea.children.forEach((singleSubject) => {
            elementsToRemove.push(singleSubject.mvzvCode)
        })


        this.setState({
            rules_updated: true,
            form: {
                ...this.state.form,
                vocations: {
                    ...this.state.form.vocations,
                    value: this.state.form.vocations.value.filter((single) => !elementsToRemove.includes(single))
                }
            }
        })
    }


    handleAccordion = (panel) => {

        if (this.state.current_panel === panel) panel = null;

        this.setState({
            current_panel: panel
        })
    }

    renderWorkSubject = () => {
        const renderArray = [];

        this.state.workSubjectsAndAreas && this.state.workSubjectsAndAreas.forEach((singleWorkArea) => {
            renderArray.push(
                <MenuItem
                    value={singleWorkArea.mvzvCode}
                >
                    <b>{singleWorkArea.name + ' (' + singleWorkArea.mvzvCode + ')'}</b>
                </MenuItem>
            );
            singleWorkArea.children && singleWorkArea.children.forEach((singleWorkSubject) => {
                renderArray.push(
                    <MenuItem
                        value={singleWorkSubject.mvzvCode}
                    >
                        {' - ' + singleWorkSubject.name + ' (' + singleWorkSubject.mvzvCode + ')'}
                    </MenuItem>
                )
            })
        })
        return renderArray;
    }

    render() {
        if (this.state.is_404) {
            return (
                <><Link className="go_back_button" to="/modules">Go back</Link><br /><br />
                    <h3>This Module was not found</h3></>
            );
        }

        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={8}>
                            <Link className="go_back_button" to="/modules">Go back</Link><br /><br />
                            <h1>{this.state.module_title}</h1>
                        </Col>
                        <Col md={4} className="align_right_desktop">
                            <div className="add_button" style={{ marginRight: 50 }} onClick={() => {

                                this.change_value('region', "");
                                this.change_value('vocation', "");
                                this.change_value('ismember', false)

                                this.setState({
                                    ruleType: 'single'
                                })

                                this.popup.current.open()


                            }}>Add rule</div>
                            <div className="add_button" onClick={() => {

                                this.resetForm();

                                this.setState({
                                    ruleType: 'group'
                                })

                                this.popup.current.open()


                            }}>Add rule set</div>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: 50 }}>
                        <Col md={12}>
                            <Table style={{ marginBottom: 50 }}><TableBody>
                                {this.state.rules !== undefined && this.state.rules.length > 0 ? this.state.rules.map((rule, key) => {

                                    if (rule.module !== this.state.module_id) return null;

                                    return <TableRow key={key} className={rule.removing === true ? 'disabled' : null}>
                                        <TableCell>

                                            {this._render_list(rule)}

                                        </TableCell>
                                        <TableCell className="align-right">{rule.id !== undefined ? <div className="small_button" onClick={() => this.delete_rule(key)}>remove</div> : null}</TableCell>
                                    </TableRow>

                                }) : null}

                            </TableBody></Table>

                            <Table><TableBody>
                                {this.state.ruleSets !== undefined ? <>
                                    <h4>Rule Sets</h4>
                                    {Object.keys(this.state.ruleSets).map((ruleGroupName) => {

                                        let ruleSet = this.state.ruleSets[ruleGroupName];

                                        return <TableRow key={ruleGroupName} className={this.state.removingGroupNames.includes(ruleGroupName) ? 'disabled' : null}>
                                            <TableCell>

                                                {this.renderGroup(ruleSet)}

                                            </TableCell>
                                            <TableCell className="align-right">{ruleGroupName !== undefined ? <div className="small_button" onClick={() => this.deleteRuleSet(ruleGroupName)}>remove</div> : null}</TableCell>
                                        </TableRow>

                                    })}
                                </>
                                    :
                                    null
                                }


                            </TableBody></Table>


                            {this.state.rules.length < 1 && Object.keys(this.state.ruleSets).length < 1 && !this.state.loading ? <strong>Currently this module is not visible to anyone.<br />To make it visible to everyone, just add an empty rule.</strong> : null}
                        </Col>
                    </Row>
                </Container>
                <Popup ref={this.popup}>

                    {
                        this.state.ruleType === 'single' ?
                            <>
                                <h3>Add Rule</h3>

                                <FormControlLabel
                                    value={"true"}
                                    control={<CustomCheckbox checked={this.state.form.ismember.value} />}
                                    label={'Only member'}
                                    labelPlacement="end"
                                    onChange={(event) => this.change_value('ismember', event)}
                                    style={{ marginBottom: 10 }}
                                />

                                <InputLabel id="regionsLabel">Region</InputLabel>
                                <Select labelId="regionsLabel" id="regions" value={this.state.form.region.value} onChange={(event) => this.change_value('region', event)}>

                                    <MenuItem value="">No region</MenuItem>

                                    {Object.keys(regions).map((key) => {
                                        return <MenuItem key={key} value={key}>{regions[key]}</MenuItem>
                                    })}

                                </Select>

                                <InputLabel id="vocations">Vocations / Branches</InputLabel>
                                <Select labelId="vocations" id="vocations" value={this.state.form.vocation.value}
                                        onChange={(event) => this.change_value('vocation', event)}>

                                    <MenuItem value="">No vocation</MenuItem>

                                    {
                                        this.renderWorkSubject()
                                        /*
                                        this.state.workSubjectsAndAreas && this.state.workSubjectsAndAreas.map((workSubjectArea) => {
                                            return workSubjectArea.children && workSubjectArea.children.map((workSubject) => {
                                                return (
                                                    <MenuItem
                                                        key={workSubject.mvzvCode}
                                                        value={workSubject.mvzvCode}
                                                    >
                                                        {workSubject.name + ' (' + workSubject.mvzvCode + ')'}
                                                    </MenuItem>
                                                )
                                            });
                                        })

                                         */
                                    }

                                </Select>

                                <div className="button" onClick={() => { this.add_rule() }}>Add Rule</div>

                            </>

                            :
                            <>
                                <h3>Add Rule Set</h3>

                                <Container fluid style={{ padding: 0 }}>
                                    <Row style={{ paddingTop: 10 }}>
                                        <Col md={12}>
                                            <FormControlLabel
                                                value={"true"}
                                                control={<CustomCheckbox checked={this.state.form.ismember.value} />}
                                                label={'Only member'}
                                                labelPlacement="end"
                                                onChange={(event) => this.change_value('ismember', event)}
                                                style={{ marginBottom: 10 }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingTop: 10 }}>
                                        <Col md={6}>
                                            <strong>Vocations</strong>
                                            <VocationAccordion
                                                value={this.state.form.vocations.value}
                                                hideTitle
                                                opened={true}
                                                toggle={() => {
                                                }}
                                                changevalue={this.changeVocations}
                                                changeAllValues={this.changeVocationsCategories}
                                                removeAllSubjectsFromArea={this.cbRemoveAllSubjectsFromArea}
                                                addAllSubjectsFromArea={this.cbAddAllSubjectsFromArea}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <strong>Regions</strong>
                                            <br />
                                            <br />

                                            {Object.keys(regions).map((key) => {
                                                return <><FormControlLabel
                                                    value={key}
                                                    control={<CustomCheckbox checked={this.state.form.regions.value.includes(key)} />}
                                                    label={regions[key]}
                                                    labelPlacement="end"
                                                    onChange={() => this.changeRegions(key)}
                                                    style={{ marginBottom: 10 }}
                                                /><br /></>
                                            })}


                                        </Col>
                                    </Row>
                                    <Row style={{ paddingTop: 10 }}>
                                        <Col md={12}>
                                            <div className="button" onClick={() => { this.addRuleSet() }}>Add Rule Set</div>
                                        </Col>
                                    </Row>
                                </Container>

                            </>

                    }

                </Popup>
                <Notification ref={this.notification} />
                <Loading visible={this.state.loading} />
            </>

        )
    }
}
