import {MenuItem, Select, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import {validation} from '../../helper/validation';
import GeneralSettingUnit from '../../units/GeneralSettingUnit';

import regions from '../../helper/data/regions.json';
import {getWorkingSubjectsAndAreas, removeA, wrlog} from '../../helper/misc';
import UserUnit from '../../units/UserUnit';
import Info from '../../helper/screens/Info';


export default class GlobalSettings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            show_errors: false,
            defaultemail_session: {},
            formattedDestinations: {},
            formattedUsers: [],
            disabledDestinationRows: [],
            currentKey: null,
            currentVocation: null,
            currentRegion: null,
            currentOpen: null,
            form: {
                defaultemail: {
                    valid: false,
                    value: '',
                    rules: {
                        isRequired: true
                    }
                },
                user: {
                    valid: false,
                    value: '',
                    rules: {
                        isRequired: true
                    }
                },
            },
            workSubjectAndAreas: null
        }

        this.notification = new React.createRef();
        this.settingsUnit = new GeneralSettingUnit(props.com);
        this.userUnit = new UserUnit(props.com);
    }

    componentDidMount() {
        // will be true
        this.props.set_current_page(this.props.location.pathname);

        let that = this;

        let cb_success = (res) => {

            let formCopy = this.state.form;
            formCopy.defaultemail = res.data;
            formCopy.defaultemail.valid = validation(formCopy.defaultemail.value, formCopy.defaultemail.rules);

            that.setState({
                form: formCopy,
                loading: false,
                defaultemail_session: res
            });
        }

        let cb_error = (res) => {
            that.setState({
                loading: false
            });
        }

        let defaultemail = JSON.parse(sessionStorage.getItem('defaultemail'));

        if (defaultemail === null) {
            that.setState({
                loading: true
            });
            this.settingsUnit.get_default_email(cb_success, cb_error);

        } else {

            let formCopy = this.state.form;
            formCopy.defaultemail.value = defaultemail.data.value;
            formCopy.defaultemail.valid = validation(formCopy.defaultemail.value, formCopy.defaultemail.rules);

            this.setState({
                form: formCopy,
                defaultemail_session: defaultemail
            })

        }

        getWorkingSubjectsAndAreas().then((result) => {
            this.setState({
                workSubjectAndAreas: JSON.parse(result.toString())
            })
        }).catch((error) => wrlog('LoadingError_GlobalSettings: ', error))

        this._init();

    }

    save_form = () => {

        this.setState({
            show_errors: false
        })

        let formCopy = this.state.form;
        let isValid = true;


        Object.keys(formCopy).forEach(key => {

            if (formCopy[key].valid === false) {

                isValid = false;

            }

        });

        if (!isValid) {

            this.setState({
                show_errors: true
            })

            return false;

        };

        this.setState({

            loading: true

        });


        let that = this;
        let cb_success = (res) => {

            that.notification.current.open('success', 'Settings saved');

            let defaultemail_sessionCopy = that.state.defaultemail_session;

            defaultemail_sessionCopy.data.value = formCopy.defaultemail.value;

            sessionStorage.setItem('defaultemail', JSON.stringify(defaultemail_sessionCopy));

            that.setState({
                loading: false
            });

        }

        let cb_error = (res) => {

            that.notification.current.open('error', 'An error occured, please try again');

            that.setState({
                loading: false
            });

        }

        this.settingsUnit.set_default_email(formCopy.defaultemail.value, cb_success, cb_error);

    }

    revokeChatDestination = (id, key) => {

        let disabledDestinationRows = this.state.disabledDestinationRows;
        disabledDestinationRows.push(key);

        this.setState({
            disabledDestinationRows: disabledDestinationRows
        })

        this.settingsUnit.delete_destination(id, (res) => {

            this.getSetEmails(() => {
                let disabledDestinationRows = this.state.disabledDestinationRows;
                disabledDestinationRows = removeA(disabledDestinationRows, key);
                this.setState({
                    disabledDestinationRows: disabledDestinationRows
                })
            })

        }, (err) => { wrlog(err) })


    }

    setChatDestination = (vocation, region, key) => {

        let formCopy = this.state.form;

        let data = {
            userId: parseInt(formCopy.user.value)
        }

        if (vocation !== null) {
            data.subjectArea = vocation;
        }

        if (region !== null) {
            data.region = region;
        }

        let disabledDestinationRows = this.state.disabledDestinationRows;
        disabledDestinationRows.push(key);

        formCopy.user.value = null;

        this.setState({
            disabledDestinationRows: disabledDestinationRows,
        })

        this.settingsUnit.set_destination(data, (res) => {

            this.getSetEmails(() => {
                let disabledDestinationRows = this.state.disabledDestinationRows;
                disabledDestinationRows = removeA(disabledDestinationRows, key);
                this.setState({
                    disabledDestinationRows: disabledDestinationRows
                })
            })


        }, (err) => { wrlog(err) })

    }

    renderCombinations = () => {

        //für DropDown!!!!
        if (this.state.formattedDestinations.length < 1) return <></>;

        return Object.keys(regions).map((regionIndex) => {

            let regionTitle = regions[regionIndex];

            const formattedKey = regionIndex;

            const formattedDestination = this.state.formattedDestinations[formattedKey];

            return <>
                <TableRow className={this.state.disabledDestinationRows.includes(formattedKey) ? 'disabled' : ''}>
                    <TableCell style={{position: 'relative', paddingLeft: 40}}>
                        <div
                            className={"accordion_arrow "} style={{
                            left: 10,
                            cursor: 'pointer',
                            top: '50%',
                            transform: (this.state.currentOpen === formattedKey ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)')
                        }}
                            onClick={() => {
                                this.setState({
                                    currentOpen: this.state.currentOpen === regionIndex ? null : regionIndex
                                })
                            }}
                        />
                        Fallback for {regionTitle}
                    </TableCell>
                    <TableCell>
                        {formattedDestination !== undefined ? `${formattedDestination.user.firstname} ${formattedDestination.user.lastname} (${formattedDestination.user.email})` :
                            <Select labelId={"users" + formattedKey} id={"users" + formattedKey}
                                    value={this.state.currentKey === formattedKey ? this.state.form.user.value : null}
                                    onChange={(event) => {

                                        this.setState({
                                            currentKey: formattedKey
                                        });

                                        this.change_value('user', event);

                            }}>
                                <MenuItem value={null}>Choose a user</MenuItem>
                                {this.state.formattedUsers.length > 0 ? this.state.formattedUsers.map((user, userKey) => {

                                    return <MenuItem value={user.id}
                                                     key={userKey}>{`${user.firstname} ${user.lastname} (${user.email})`}</MenuItem>

                                }) : null}

                            </Select>
                        }
                    </TableCell>
                    <TableCell>
                        {formattedDestination !== undefined ?
                            <div
                                className="small_button"
                                onClick={() => {
                                    this.revokeChatDestination(formattedDestination.id, formattedKey);
                                }}
                            >revoke</div>
                            :
                            this.state.currentKey === formattedKey ? <div
                                className="small_button"
                                onClick={() => {
                                    this.setChatDestination(null, regionIndex, formattedKey);
                                }}
                            >set</div> : null
                        }

                    </TableCell>
                </TableRow>
                {
                    this.state.currentOpen === regionIndex && this.render_userChatDestinations(regionIndex, regionTitle)
                }

            </>
        })

    }

    _init = () => {

        this.getUsers(this.getSetEmails);

    }

    getUsers = (cb) => {

        this.userUnit.get(({ data: { users: { data } } }) => {

            // FORMAT USERS

            let formattedUsers = [];

            data.forEach(user => {
                formattedUsers.push({
                    firstname: user.firstname.value,
                    lastname: user.lastname.value,
                    id: user.id.value,
                    email: user.email.value,
                })
            });

            this.setState({
                formattedUsers: formattedUsers
            })

            cb();

        }, (err) => {
            wrlog(err);
        });

    }

    getSetEmails = (cb = null) => {

        this.settingsUnit.get_destinations(({ data }) => {

            let formattedDestinations = {};

            data.chatDestination.forEach(destination => {

                let key = '';

                if (destination.subjectArea !== null) {
                    key = key + destination.subjectArea;
                }
                if (destination.region !== null) {

                    if (destination.subjectArea !== null) {
                        key = key + '__';
                    }

                    key = key + destination.region

                }

                formattedDestinations[key] = destination;

            });

            wrlog("DESTINATION", formattedDestinations);
            this.setState({
                formattedDestinations: formattedDestinations,
                loading: false
            })

            if (cb !== null) cb();

        }, (err) => {
            wrlog(err)
        });

    }

    change_value = (key, value) => {

        let formCopy = this.state.form;

        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }
        console.log('formCopy');

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({form: formCopy})

    }

    renderTableRow(workSubjectObject, regionTitle, regionIndex) {
        const contentToRender = [];
        const formattedKeyRegion = workSubjectObject.mvzvCode + (regionIndex ? '__' + regionIndex : '');
        const formattedDestinationRegion = this.state.formattedDestinations[formattedKeyRegion];

        contentToRender.push(
            <TableRow
                className={this.state.disabledDestinationRows.includes(formattedKeyRegion ? formattedKeyRegion : '') ? 'disabled' : ''}>

                <TableCell>
                    {
                        workSubjectObject ?
                            workSubjectObject.name + ' (' + workSubjectObject.mvzvCode + ') ' + 'in ' + regionTitle :
                            <div
                                className={"accordion_arrow "}
                                style={{
                                    left: 10,
                                    cursor: 'pointer',
                                    top: '50%',
                                    transform: (this.state.currentOpen === '' ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)')
                                }}
                                onClick={() => {
                                    this.setState({
                                        currentOpen: this.state.currentOpen === '' ? null : ''
                                    })
                                }}>
                                General Fallback
                            </div>
                    }
                </TableCell>

                <TableCell>
                    {formattedDestinationRegion !== undefined ? `${formattedDestinationRegion.user.firstname} ${formattedDestinationRegion.user.lastname} (${formattedDestinationRegion.user.email})` :
                        <Select
                            labelId={"users" + (formattedKeyRegion ? formattedKeyRegion : '')}
                            id={"users" + (formattedKeyRegion ? formattedKeyRegion : '')}
                            value={this.state.currentKey === formattedKeyRegion || this.state.currentKey === '' ? this.state.form.user.value : null}
                            onChange={(event) => {
                                this.setState({
                                    currentKey: formattedKeyRegion ? formattedKeyRegion : ''
                                })
                                this.change_value('user', event)
                            }}>
                            <MenuItem value={null}>Choose a user :)</MenuItem>
                            {this.state.formattedUsers.length > 0 ? this.state.formattedUsers.map((user, userKey) => {
                                return <MenuItem
                                    value={user.id}
                                    key={userKey}
                                >{`${user.firstname} ${user.lastname} (${user.email})`}</MenuItem>

                            }) : null}

                        </Select>
                    }
                </TableCell>

                <TableCell>
                    {formattedDestinationRegion !== undefined ?
                        <div
                            className="small_button"
                            onClick={() => {
                                this.revokeChatDestination(formattedDestinationRegion.id, formattedKeyRegion ? formattedKeyRegion : '');
                            }}
                        >revoke</div>
                        :
                        this.state.currentKey === formattedKeyRegion || '' ? <div
                            className="small_button"
                            onClick={() => {
                                this.setChatDestination(workSubjectObject ? workSubjectObject.mvzvCode : null, regionIndex ? regionIndex : null, formattedKeyRegion ? formattedKeyRegion : '');
                            }}
                        >set</div> : null
                    }
                </TableCell>

            </TableRow>
        );

        if (workSubjectObject.children)
            contentToRender.push(workSubjectObject.children.map((singleWorkSubject) =>
                this.renderTableRow(singleWorkSubject, regionTitle, regionIndex)))

        return contentToRender;
    }

    render_userChatDestinations = (regionIndex, regionTitle) => {
        return this.state.workSubjectAndAreas && this.state.workSubjectAndAreas.map((singleWorkArea) => {

            return this.renderTableRow(singleWorkArea, regionTitle, regionIndex);
        });
    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12} style={{paddingBottom: 50, marginTop: 44}}>
                            <h1>Chat Destinations <Info><h3>Chat Destinations</h3><p>Define which Dashboard User
                                receives chat-emails for specified vocations and/or regions<br/><br/>
                                <strong>Fallback</strong> is a savety email. If any definition eg. Koch in Wien is not
                                defined the chat message is sent to the defined fallback email for Wien</p></Info></h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Table>
                                <TableBody>
                                    <TableRow className={this.state.disabledDestinationRows.includes('') ? 'disabled' : ''}>
                                        <TableCell style={{position: 'relative', paddingLeft: 40}}>
                                            <div
                                                className={"accordion_arrow "}
                                                style={{
                                                    left: 10,
                                                    cursor: 'pointer',
                                                    top: '50%',
                                                    transform: (this.state.currentOpen === '' ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)')
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        currentOpen: this.state.currentOpen === '' ? null : ''
                                                    })
                                                }}
                                            />
                                            General Fallback
                                        </TableCell>
                                        <TableCell>
                                            {this.state.formattedDestinations[''] !== undefined ? `${this.state.formattedDestinations[''].user.firstname} ${this.state.formattedDestinations[''].user.lastname} (${this.state.formattedDestinations[''].user.email})` :
                                                <Select labelId={"users"} id={"users"}
                                                        value={this.state.currentKey === '' ? this.state.form.user.value : null}
                                                        onChange={(event) => {

                                                            this.setState({
                                                                currentKey: ''
                                                            });

                                                            this.change_value('user', event);

                                                        }}>
                                                    <MenuItem value={null}>Choose a user</MenuItem>
                                                    {this.state.formattedUsers.length > 0 ? this.state.formattedUsers.map((user, userKey) => {

                                                        return <MenuItem
                                                            value={user.id}>{`${user.firstname} ${user.lastname} (${user.email})`}</MenuItem>

                                                    }) : null}

                                                </Select>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {this.state.formattedDestinations[''] !== undefined ?
                                                <div
                                                    className="small_button"
                                                    onClick={() => {
                                                        this.revokeChatDestination(this.state.formattedDestinations[''].id, '');
                                                    }}
                                                >revoke</div>
                                                :
                                                this.state.currentKey === '' ? <div
                                                    className="small_button"
                                                    onClick={() => {
                                                        this.setChatDestination(null, null, '');
                                                    }}
                                                >set</div> : null
                                            }

                                        </TableCell>
                                    </TableRow>

                                    {
                                        this.state.currentOpen === '' && this.render_userChatDestinations(undefined, undefined)
                                    }

                                    {this.renderCombinations()}
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ paddingTop: 50 }}>
                            <div className="button" onClick={this.save_form}>Save</div>
                        </Col>
                    </Row>
                </Container>
                <Notification ref={this.notification} text="Settings saved" type="success" />
                <Loading visible={this.state.loading} />
            </>

        )
    }
}
