import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Loading from '../../../../helper/screens/Loading';


import CustomCheckbox from '../../../../helper/screens/CustomCheckbox';

import PushUnit from '../../../../units/PushUnit';
import {FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';

import {formatDatetime, formatTimeToUnix,} from '../../../../helper/misc';

import DatetimeRangePicker from 'react-datetime-range-picker';

import {get_title} from '../../../../helper/functions/push';

export default class StatisticsPushNotifications extends React.Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            is_404: false,
            push_notifications: [],
            current_panel: 'panel1',
            form: {
                regions: {
                    value: []
                },
                vocations: {
                    value: []
                },
                timeperiod: {
                    value: 'full'
                },
                start_date: {
                    value: new Date()
                },
                end_date: {
                    value: new Date()
                }
            },
            user: 0,
            workSubjectsAndAreas: null,
        }

        this.sidebar_rightRef = new React.createRef();
        this.sidebar_right_burgerRef = new React.createRef();

    }

    componentDidMount() {

        this.props.set_current_page('/statistics');

        let push_notifications = JSON.parse(sessionStorage.getItem('push_notification_list'));

        if (push_notifications === null) {

            this.load_content();

            this.setState({
                loading: true
            });

        } else {

            this.setState({
                push_notifications: push_notifications.data.pushNotifications
            })

        }

    }

    load_content = () => {

        let notifications = new PushUnit(this.props.com);

        this.setState({
            partial_loading: true
        });

        let cb_success = (res) => {

            let notificationList = res.data.pushNotifications.sort((a, b) => {
                return b.id - a.id;
            })

            this.setState({
                push_notifications: notificationList,
                loading: false,
                partial_loading: false
            });

        }

        let cb_error = () => {
            this.setState({
                loading: false,
                partial_loading: false
            });
        }

        notifications.list(cb_success, cb_error);

    }


    toggleMobileNav = (force_action = false) => {

        if (force_action === 'close') {

            this.sidebar_rightRef.current.classList.remove('open')
            this.sidebar_right_burgerRef.current.classList.remove('open')

        } else if (force_action === 'open') {

            this.sidebar_rightRef.current.classList.add('open')
            this.sidebar_right_burgerRef.current.classList.add('open')

        } else {
            this.sidebar_rightRef.current.classList.toggle('open')
            this.sidebar_right_burgerRef.current.classList.toggle('open')
        }


    }

    changeRegions = (value) => {

        let formCopy = this.state.form;
        let selectedRegions = formCopy.regions.value;
        let position = selectedRegions.indexOf(value);

        if (position > -1) {
            selectedRegions.splice(position, 1);
        } else {
            selectedRegions.push(value);
        }

        formCopy.regions.value = selectedRegions;

        this.setState({
            form: formCopy
        })

    }

    changeVocations = (value) => {

        let formCopy = this.state.form;
        let selectedVocations = formCopy.vocations.value;
        let position = selectedVocations.indexOf(value);

        if (position > -1) {
            selectedVocations.splice(position, 1);
        } else {
            selectedVocations.push(value);
        }

        formCopy.vocations.value = selectedVocations;

        this.setState({
            form: formCopy
        })

    }

    changePeriod = (value) => {

        let formCopy = this.state.form;

        formCopy.timeperiod.value = value;

        this.setState({ form: formCopy });

        this.toggleMobileNav('close')

    }

    handleAccordion = (panel) => {

        if (this.state.current_panel === panel) panel = null;

        this.setState({
            current_panel: panel
        })
    }

    change_date_range = (value) => {

        let form_copy = this.state.form;
        form_copy.start_date.value = value.start;
        form_copy.end_date.value = value.end;

        this.setState({
            form: form_copy
        })
    }

    render() {

        let today = new Date();

        today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 0);

        let last_month = today.getMonth() - 1;
        let last_month_year = today.getFullYear();

        if (last_month < 0) {
            last_month = 11;
            last_month_year = last_month_year - 1;
        }

        last_month = new Date(last_month_year, last_month, 1, 0, 0, 0);
        let last_month_last_day = new Date(last_month_year, last_month.getMonth(), new Date(last_month_year, last_month.getMonth() + 1, 0).getDate(), 23, 59, 0);

        let this_year_start = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0);

        let last_year_start = new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0);
        let last_year_end = new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 0);

        let filtered = this.state.push_notifications.filter(notification => {
            return notification.sent === true && (
                (this.state.form.timeperiod.value === 'full') ||
                (this.state.form.timeperiod.value === 'last_7_days' && formatTimeToUnix(notification.time) > today.getTime() - (1000 * 60 * 60 * 24 * 7) && formatTimeToUnix(notification.time) < today.getTime()) ||
                (this.state.form.timeperiod.value === 'this_month' && formatTimeToUnix(notification.time) > today.getTime() - (1000 * 60 * 60 * 24 * today.getDate()) && formatTimeToUnix(notification.time) < today.getTime()) ||
                (this.state.form.timeperiod.value === 'last_month' && formatTimeToUnix(notification.time) > last_month.getTime() && formatTimeToUnix(notification.time) < last_month_last_day.getTime()) ||
                (this.state.form.timeperiod.value === 'this_year' && formatTimeToUnix(notification.time) > this_year_start.getTime() && formatTimeToUnix(notification.time) < today.getTime()) ||
                (this.state.form.timeperiod.value === 'last_year' && formatTimeToUnix(notification.time) > last_year_start.getTime() && formatTimeToUnix(notification.time) < last_year_end.getTime()) ||
                (this.state.form.timeperiod.value === 'custom' && formatTimeToUnix(notification.time) > this.state.form.start_date.value.getTime() && formatTimeToUnix(notification.time) < this.state.form.end_date.value.getTime())
            )
        })

        filtered.sort((a, b) => {
            return formatTimeToUnix(b.time) - formatTimeToUnix(a.time)
        })

        let sent = 0;
        let clicked = 0;

        filtered.forEach(notification => {
            sent = sent + notification.numberOfTargets;
            clicked = clicked + (notification.openedCounter || 0);
        })

        let failed = sent - clicked;

        return (
            <>
                <div
                    className="burger_right"
                    ref={this.sidebar_right_burgerRef}
                    onClick={() => {
                        this.toggleMobileNav()
                    }}
                />

                <Container fluid style={{ marginLeft: 0 }} className="middle_container">
                    <Row>
                        <Col md={12} style={{ paddingBottom: 20 }}>
                            <Link className="go_back_button" to="/statistics">Go back</Link><br /><br />
                            <h1>Push Notifications</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <h2>{sent}</h2>
                            <strong>Sent</strong>
                        </Col>
                        <Col md={3}>
                            <h2>{clicked}</h2>
                            <strong>Clicked</strong>
                        </Col>
                        <Col md={3}>
                            <h2>{failed}</h2>
                            <strong>Pending</strong><br/>
                            <small><strong>(Not opened or received)</strong></small>
                        </Col>
                        <Col md={3}>
                            <h2>{sent !== 0 && clicked !== 0 ? Math.round(clicked / sent * 100) + '%' : 0} </h2>
                            <strong>Open-Rate</strong><br/>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 100 }}>
                        <Col md={12}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Time</TableCell>
                                        <TableCell>Sent</TableCell>
                                        <TableCell>Clicked</TableCell>
                                        <TableCell>Pending</TableCell>
                                        <TableCell>%</TableCell>
                                        <TableCell className="align-right">
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {filtered.map((notification, key) => {
                                        return <TableRow key={key}>
                                            <TableCell>{notification.internalTitle !== '' && notification.internalTitle !== null ? notification.internalTitle : get_title(notification)}</TableCell>
                                            <TableCell>{formatDatetime(notification.time)}</TableCell>
                                            <TableCell>{notification.numberOfTargets}</TableCell>
                                            <TableCell>{notification.openedCounter || 0}</TableCell>
                                            <TableCell>{notification.numberOfTargets - (notification.openedCounter || 0)}</TableCell>
                                            <TableCell>{notification.openedCounter !== undefined ? notification.numberOfTargets !== 0 ? Math.round(notification.openedCounter / notification.numberOfTargets * 100) + '%' : 'NA' : 'NA'}</TableCell>
                                            <TableCell className="align-right">
                                                <Link to={{
                                                    pathname: "/push/" + notification.id + "/",
                                                    state: {origin: '/statistics'}
                                                }}
                                                >show</Link>
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>



                </Container>
                <div className="sidebar_right" ref={this.sidebar_rightRef} style={{ paddingTop: 100, backgroundColor: 'white' }}>

                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'full'} />}
                        label={'All Time'}
                        labelPlacement="end"
                        onChange={() => this.changePeriod("full")}
                        style={{marginBottom: 10}}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'last_7_days'} />}
                        label={'Last 7 days'}
                        labelPlacement="end"
                        onChange={() => this.changePeriod("last_7_days")}
                        style={{marginBottom: 10}}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'this_month'} />}
                        label={'This Month'}
                        labelPlacement="end"
                        onChange={() => this.changePeriod("this_month")}
                        style={{marginBottom: 10}}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'last_month'} />}
                        label={'Last Month'}
                        labelPlacement="end"
                        onChange={() => this.changePeriod("last_month")}
                        style={{marginBottom: 10}}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'this_year'} />}
                        label={'This Year'}
                        labelPlacement="end"
                        onChange={() => this.changePeriod("this_year")}
                        style={{marginBottom: 10}}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'last_year'} />}
                        label={'Last Year'}
                        labelPlacement="end"
                        onChange={() => this.changePeriod("last_year")}
                        style={{marginBottom: 10}}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'custom'} />}
                        label={'Custom'}
                        labelPlacement="end"
                        onChange={() => this.changePeriod("custom")}
                        style={{marginBottom: 10}}
                    />
                    {
                        this.state.form.timeperiod.value === 'custom' ?
                            <DatetimeRangePicker
                                onChange={this.change_date_range}
                                startDate={this.state.form.start_date.value}
                                endDate={this.state.form.end_date.value}
                                timeFormat={false}
                                className={"datepicker_range"}
                                dateFormat={'DD.MM.YYYY'}
                                isValidEndDate={(date) => {
                                    return date <= new Date();
                                }}
                            />
                            : null
                    }

                    <br />
                    <br />
                    <br />

                </div>
                <Loading visible={this.state.loading} />
            </>

        )
    }
}
