import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import ContentUnit from '../../units/ContentUnit';
import languages from '../../helper/data/languages.json';
import Info from '../../helper/screens/Info';
import { wrlog } from '../../helper/misc';


export const translateName = (id) => {

    const list = {
        "terms_of_usage": "Terms of use",
        "gdpr": "GDPR (data protection)",
        "contact": "Contact",
        "about": "About",
        "imprint": "Imprint"
    }

    if (list[id] !== undefined) {
        return list[id];
    }

    return id;

}

export default class Content extends React.Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            show_errors: false,
            static_content: [],
            partial_loading: false
        }

        this.notification = new React.createRef();
    }

    componentDidMount() {

        this.props.set_current_page(this.props.location.pathname);

        let static_content = JSON.parse(sessionStorage.getItem('static_content'));

        if (static_content === null) {

            this.setState({
                loading: true
            });

        } else {

            let content = this.sort(static_content.data.staticContent);


            this.setState({
                static_content: content,
            })

        }

        this.load_content();

    }

    load_content = () => {

        let feed = new ContentUnit(this.props.com);

        this.setState({
            partial_loading: true
        });

        let cb_success = (res) => {

            let content = this.sort(res.data.staticContent);

            this.setState({
                static_content: content,
                loading: false,
                partial_loading: false
            });

        }

        let cb_error = (res) => {
            this.setState({
                loading: false,
                partial_loading: false
            });
        }

        feed.load(cb_success, cb_error);
    }

    sort = (elements) => {
        return elements.sort((a, b) => {
            return b.language < a.language;
        })
    }

    render_list = () => {

        if (this.state.static_content.length < 1) return;

        let page_indexesCheck = [];
        let page_indexes = [];

        this.state.static_content.forEach((page_parent) => {
            if (!page_indexesCheck.includes(page_parent.page)) {

                let outputPage = {
                    parent: page_parent.page,
                    language: page_parent.language,
                }

                this.state.static_content.forEach((page) => {

                    if (page.language === 'de' && page.page === page_parent.page) {
                        outputPage = {
                            parent: page.page,
                            language: page.language,
                        }
                    }

                })
                page_indexesCheck.push(page_parent.page);
                page_indexes.push(outputPage);
            }
        })

        wrlog("page_indexes", page_indexes);

        return page_indexes.map((page_parent, page_parent_key) => {

            return <><TableRow key={page_parent_key}>
                <TableCell><strong>{translateName(page_parent.parent)}</strong></TableCell>
                <TableCell className="align-right">
                    <Link to={"/content/" + page_parent.parent + "/" + page_parent.language}>edit</Link>
                </TableCell>
            </TableRow>{this.state.static_content.map((page, page_key) => {

                if (page.page !== page_parent.parent) return null;

                return <TableRow key={page_parent_key + '_' + page_key}>
                    <TableCell>{languages[page.language].name}</TableCell>
                    <TableCell className="align-right">
                    </TableCell>
                </TableRow>
            })}</>
        });

    }


    render() {
        return (
            <>
                <Container fluid>

                    <Row>
                        <Col md={12} style={{ paddingBottom: 50, marginTop: 44 }}>
                            <h1>Static Content <Info style={{ top: 4, marginLeft: 20 }}>
                                <h3>Static Content</h3>
                                <p>Page content like Imprint, About etc. that is loaded into the app and shown via the Profile menu.</p>
                            </Info></h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Title</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{this.state.partial_loading ? <div><Loading visible={true} relative style={{ width: 20, height: 20 }} container_style={{ width: 20, height: 20, display: 'inline-block', verticalAlign: 'middle', marginRight: 10 }} /> Synchronizing with server</div> : null}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{this.render_list()}</TableBody>
                            </Table>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} style={{ paddingBottom: 50, marginTop: 44 }}>
                            <h1>Registration & Recruitment Settings <Info style={{ top: 4, marginLeft: 20 }}>
                                <h3>Registration & Recruitment Settings</h3>
                                <p>Determines if the registration & recruitment is in app or uses a link</p>
                            </Info></h1>
                        </Col>
                    </Row>
                </Container>
                <Loading visible={this.state.loading} />
            </>

        )
    }
}
