import React from 'react';

import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { Col, Container, Row } from 'react-bootstrap';
import Loading from '../../helper/screens/Loading';
import { validation } from '../../helper/validation';
import Notification from '../../helper/screens/Notification';
import Popup from '../../helper/screens/Popup';

import RegisterUnit from '../../units/RegisterUnit';

import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { formatDatetime, formatDatetimeForDbSimple, formatTimeToUnix, getCurrentUnix } from '../../helper/misc';
import Switch from '../../helper/screens/Switch';


export default class ModuleVidaDialogerInnen extends React.Component {

    constructor() {

        super();

        this.state = {
            loading: false,
            partial_loading: false,
            show_errors: false,
            is_404: false,
            switch: 'future',
            destinations: [],
            module_id: false,
            module_title: '',
            recruitment_times: [],
            form: {
                email: {
                    valid: false,
                    value: "",
                    rules: {
                    }
                },
                valid_from: {
                    valid: false,
                    value: new Date(),
                    rules: {
                    }
                },
                valid_to: {
                    valid: false,
                    value: new Date(),
                    rules: {
                    }
                },

            }
        }

        this.notification = React.createRef();
        this.popup = React.createRef();

    }

    componentDidMount() {
        // will be true
        this.props.set_current_page('/module-vida-dialogerinnen');

        //let auth = new AuthenticationUnit(this.props.com);

        //auth.login('stadler@spectory.at', 'password');

        this.get_recruitment_times();

    }

    get_recruitment_times = () => {

        this.setState({
            partial_loading: true
        })

        if (sessionStorage.getItem('recruitment_access_list') !== null) {

            let recruitment_times = JSON.parse(sessionStorage.getItem('recruitment_access_list')).data.recruitmentTimes


            this.setState({
                recruitment_times: recruitment_times,
                loading: false,
            })

        } else {
            this.setState({
                loading: true
            })
        }

        this.reload();



    }

    reload = () => {

        let cb_success = (res) => {

            this.setState({
                recruitment_times: res.data.recruitmentTimes,
                loading: false,
                partial_loading: false
            })


        }

        let cb_error = () => {

        }

        let register_unit = new RegisterUnit(this.props.com);

        register_unit.get_recruitment_times(cb_success, cb_error)

    }

    add_rule = () => {

        let formCopy = this.state.form;
        let sendData = {};
        let isValid = true;

        if (formCopy.email.value !== '') {
            sendData['email'] = formCopy.email.value
        }else{
            this.notification.current.open('error', 'Please fill in an existing email');
            return;
        }

        if (formCopy.valid_from.value !== '') {
            sendData['from'] = formatDatetimeForDbSimple(formCopy.valid_from.value);
        }

        if (formCopy.valid_to.value !== false) {
            sendData['to'] = formatDatetimeForDbSimple(formCopy.valid_to.value);
        }

        if (formCopy.valid_to.value.getTime() <= formCopy.valid_from.value.getTime()) {
            this.notification.current.open('error', 'Start must be before end');
            return;
        }




        this.setState({
            loading: true
        })

        let that = this;

        let register_unit = new RegisterUnit(this.props.com);

        let cb_success = (res) => {

            let recruitment_timesCopy = that.state.recruitment_times;

            let new_timeslot = {
                appuser: {
                    email: sendData['email']
                },
                fromtime: sendData['from'],
                totime: sendData['to']
            }

            recruitment_timesCopy.push(new_timeslot);

            that.notification.current.open('success', 'Person succesfully added');
            that.popup.current.close();

            this.setState({
                loading: false,
                recruitment_times: recruitment_timesCopy
            });

            that.get_recruitment_times();

        }

        let cb_error = (err) => {

            // that.popup.current.close();

            that.setState({
                loading: false,
                show_errors: true
            })

            if (err.scd === 3529) {
                this.notification.current.open('error', 'The email is not assigned to an appuser.');
            } else {
                this.notification.current.open('error', 'An error occured, please try again');
            }

        }

        register_unit.add_recruitment_access(sendData, cb_success, cb_error);

    }


    delete_rule = (id) => {

        let register_unit = new RegisterUnit(this.props.com);

        let recruitment_timesCopy = this.state.recruitment_times;

        recruitment_timesCopy.forEach((timeslot, key) => {
            if (parseInt(timeslot.id) === parseInt(id)) {
                recruitment_timesCopy[key].removing = true;
            }
        })

        this.setState({
            recruitment_times: recruitment_timesCopy
        })


        let cb_success = (res) => {

            if (this.notification.current !== null) {
                this.notification.current.open('success', 'Rule succesfully removed');
            }

            this.reload();


        }

        let cb_error = (res) => {

            this.setState({
                loading: false,
                show_errors: true
            })

            this.notification.current.open('error', 'An error occured, please try again');

        }

        register_unit.delete_recruitment_time(id, cb_success, cb_error)

    }

    change_value = (key, value) => {

        let formCopy = this.state.form;

        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }

    _render_list = () => {

        if (this.state.recruitment_times !== undefined && this.state.recruitment_times.length > 0) {

            let timeslots = this.state.recruitment_times.filter(timeslot => {

                if (this.state.switch === 'future') {
                    return formatTimeToUnix(timeslot.totime) >= getCurrentUnix();
                } else if (this.state.switch === 'past') {
                    return formatTimeToUnix(timeslot.totime) < getCurrentUnix();
                } else {
                    return true;
                }

            });

            timeslots = timeslots.sort((a, b) => {

                return b.totime - a.totime;

            })

            return timeslots.map((timeslot, key) => {

                return <TableRow key={key} className={timeslot.removing === true ? 'disabled' : null}>
                    <TableCell>
                        {'User: #' + timeslot.appuser.id + ' (' + timeslot.appuser.email + ')'}
                    </TableCell>
                    <TableCell>
                        {formatDatetime(timeslot.fromtime)}
                    </TableCell>
                    <TableCell>
                        {formatDatetime(timeslot.totime)}
                    </TableCell>
                    <TableCell className="align-right">{timeslot.id !== undefined ? <div className="small_button" onClick={() => this.delete_rule(timeslot.id)}>remove</div> : null}</TableCell>
                </TableRow>

            })

        }

    }

    switch_changed = (value) => {

        this.setState({
            switch: value
        })

    }


    render() {
        return (
            <>
                <Container fluid>
                    <Row style={{ marginTop: 44, marginBottom: 50 }}>
                        <Col md={12}>
                            <h1>Module: Vida-DialogerInnen</h1>
                            <p>Give access to unique persons, not a group</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} style={{ paddingBottom: 50 }}>
                            <Switch onChange={() => this.switch_changed('future')} selected={this.state.switch === 'future'} title="Current &amp; future" />
                            <Switch onChange={() => this.switch_changed('past')} selected={this.state.switch === 'past'} title="Past" />
                        </Col>
                        <Col md={4} style={{ paddingBottom: 50 }} className="align_right_desktop">
                            <div className="add_button" onClick={() => {

                                this.change_value('valid_to', new Date());
                                this.change_value('valid_from', new Date());
                                this.change_value('email', "");

                                this.popup.current.open()

                            }}>New person</div>
                        </Col>
                    </Row>

                    <Row style={{ paddingBottom: 50 }}>
                        <Col md={12}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell cellSpan={4} style={{ textAlign: 'right' }}>{this.state.partial_loading ? <div><Loading visible={true} relative style={{ width: 20, height: 20 }} container_style={{ width: 20, height: 20, display: 'inline-block', verticalAlign: 'middle', marginRight: 10 }} /> Synchronizing with server</div> : null}</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>


                                    {this._render_list()}


                                </TableBody></Table>

                            {this.state.recruitment_times.length < 1 ? <strong>Currently this module is not visible to anyone.</strong> : null}

                        </Col>
                    </Row>
                </Container>
                <Popup ref={this.popup}>
                    <h3>Add Rule</h3>

                    <TextField id="standard-basicasd" value={this.state.form.email.value} error={!this.state.form.email.valid && this.state.show_errors} label="Email" type="email" onChange={value => this.change_value('email', value)} /><br />

                    <Datetime dateFormat="DD.MM.YYYY" timeFormat={'HH:mm'} locale="de" value={this.state.form.valid_from.value} onChange={(value) => {

                        if (Number.isInteger(Date.parse(new Date(value)))) {
                            this.change_value('valid_from', { target: { value: new Date(value) } })
                        }

                    }}

                        renderInput={(props, openCalendar, closeCalendar) =>
                            <TextField id="valid_from" onChange={(value) => { props.onChange(value) }} error={!this.state.form.valid_from.valid && this.state.show_errors} value={formatDatetime(this.state.form.valid_from.value)} label="Valid Start" onClick={openCalendar} />
                        }

                    />



                    <Datetime dateFormat="DD.MM.YYYY" timeFormat={'HH:mm'} locale="de" value={this.state.form.valid_to.value} onChange={(value) => {

                        if (Number.isInteger(Date.parse(new Date(value)))) {
                            this.change_value('valid_to', { target: { value: new Date(value) } })
                        }

                    }}

                        renderInput={(props, openCalendar, closeCalendar) =>
                            <TextField id="valid_to" onChange={(value) => { props.onChange(value) }} error={!this.state.form.valid_to.valid && this.state.show_errors} value={formatDatetime(this.state.form.valid_to.value)} label="Valid End" onClick={openCalendar} />
                        }

                    />
                    <div className="button" onClick={() => { this.add_rule() }}>Add person</div>

                </Popup>
                <Notification ref={this.notification} style={{zIndex: 9999999999}} />
                <Loading visible={this.state.loading} />
            </>
        )
    }
}