export default class Unit {

    constructor(communicator, name){
        
        this.name = name;
        this.communicator = communicator;

    }

    show_error = () => {
        
    }

    



}



