import {get_user_token} from '../../../helper/functions/auth';
import Unit from '../../../units/Unit';


export default class KeyTagUnit extends Unit {

    argsBase = {
        unit: this.name,
        token: get_user_token(),
    }

    constructor(communicator) {
        super(communicator, 'keytag');
    }

    /**
     * GetAllKeyTags for overview
     * @param {(response) => {}} cb_success
     * @param {(error) => {}} cb_error
     */
    getAllKeyTags = (cb_success, cb_error) => {
        const args = {
            ...this.argsBase,
            action: 'getAllKeyTags',
            data: {}
        }
        this.communicator.call(args, cb_success, cb_error)
    }

    /**
     * RemoveKeyTag from User
     */

    /**
     * FoundKeyTag for User
     */
}
