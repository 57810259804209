import React from 'react';


/**
 * Render Accordion Element
 */
export default class Accordion extends React.Component {

    render() {

        return (
            <div className={"accordion " + (this.props.expanded ? 'expanded' : '')}>
                <div className="accordion_title" onClick={this.props.onClick}>
                    <strong>{this.props.title}</strong>
                    <div className="accordion_arrow"></div>
                </div>
                

                {this.props.expanded ?

                    <div className="accordion_content">
                        {this.props.children}
                    </div>

                : null}

            </div>
        );

    }
}