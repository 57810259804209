import {get_user_token} from '../helper/functions/auth';
import Unit from './Unit';
import {wrlog} from "../helper/misc";

export default class PushUnit extends Unit {

    constructor(communicator) {

        super(communicator, 'pushnotification');

    }

    list = (cb_success, cb_error) => {
        let args = {
            token: get_user_token(),
            action: 'getAll',
            unit: this.name,
            data: {
            }
        }

        this.communicator.call(args, cb_success, cb_error, 'push_notification_list');

    }



    create = (data, cb_success, cb_error) => {

        let args = {
            data: data,
            action: 'insert',
            token: get_user_token(),
            unit: this.name
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    update = (data, cb_success, eb_error) => {

        let args = {
            token: get_user_token(),
            action: 'edit',
            unit: this.name,
            data: data
        }

        this.communicator.call(args, cb_success, eb_error);

    }

    get_potential_reach = (id, cb_success, cb_error) => {
        let args = {
            token: get_user_token(),
            action: 'getNumberOfAppUsersAffected',
            unit: this.name,
            data: {
                pushNotificationId: id
            }
        }

        this.communicator.call(args, cb_success, cb_error);
    }

    delete = (id, cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'delete',
            unit: this.name,
            data: {
                pushNotificationId: id
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    delete_variant = (id, language, cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'deleteTranslation',
            unit: this.name,
            data: {
                pushNotificationId: id,
                language: language,
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }


    send = (id, time, cb_success, cb_error) => {

        let data = {pushNotificationId: id}
        if (time !== null) {
            data.time = time
        }

        let args = {
            token: get_user_token(),
            action: 'send',
            unit: this.name,
            data: data
        }

        wrlog('send: ', args);

        this.communicator.call(args, cb_success, cb_error);

    }

    unschedule = (id, cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'unscheduleSend',
            unit: this.name,
            data: {
                pushNotificationId: id,
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }


    get = (id) => {

        let push_notifications = JSON.parse(sessionStorage.getItem('push_notification_list'));

        let return_entry = null;

        if (push_notifications == null) return null;


        push_notifications.data.pushNotifications.forEach(notification => {

            if (notification.id === id) {

                return_entry = notification;

            }

        })

        return return_entry;

    }


    get_rules = (id, cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'getPushNotificationAccess',
            unit: this.name,
            data: {
                pushNotificationId: id
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    delete_rules = (id, cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'deletePushNotificationAccessForPushNotification',
            unit: this.name,
            data: {
                pushNotificationId: id
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    set_rules = (id, rules, cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'addPushNotificationAccess',
            unit: this.name,
            data: {
                pushNotificationId: id,
                rules: rules
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }




}



