import { get_user_token } from '../helper/functions/auth';
import Unit from './Unit';
import axios from 'axios';
import { SERVER_HOST, UPLOAD_FEED_FILE_URL } from '../store';
import { getBase64, wrlog } from '../helper/misc';

export default class FeedUnit extends Unit {

    constructor(communicator) {

        super(communicator, 'feed');

    }

    list = (cb_success, cb_error) => {
        let args = {
            token: get_user_token(),
            action: 'getFlatFullFeed',
            unit: this.name,
            data: {
                skip: 0,
                take: 100000,
            }
        }

        this.communicator.call(args, cb_success, cb_error);
    }

    load = (id, cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'getFeedEntryById',
            unit: this.name,
            data: {
                feedEntryId: id,
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }



    create = (data, cb_success, cb_error) => {

        let args = {
            data: data,
            action: 'insertUserPost',
            token: get_user_token(),
            unit: this.name
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    update = (data, cb_success, eb_error) => {

        let args = {
            token: get_user_token(),
            action: 'editFeedEntry',
            unit: this.name,
            data: data,
        }

        this.communicator.call(args, cb_success, eb_error);

    }

    create_comment = (data, cb_success, cb_error) => {
        let args = {
            token: get_user_token(),
            action: 'insertUserComment',
            unit: this.name,
            data: data
        }

        this.communicator.call(args, cb_success, cb_error);
    }

    delete = (id, cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'deleteFeedEntry',
            unit: this.name,
            data: {
                feedEntryId: id
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    delete_variant = (id, language, cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'deleteTranslation',
            unit: this.name,
            data: {
                feedEntryId: id,
                language: language,
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    set_cache = (data) => {

        sessionStorage.setItem('feed_entries', JSON.stringify(data));

    }

    get = (id, full = false) => {

        let feed_entries = JSON.parse(sessionStorage.getItem('feed_entries'));

        let return_entry = null;
        let entry_index = null;

        if (feed_entries == null) return null;

        feed_entries.forEach((entry, entry_index_cur) => {

            if (parseInt(entry.feedEntryId) === parseInt(id)) {

                if (full) {
                    entry_index = entry_index_cur;
                } else {
                    return_entry = entry;
                }

            }

        })

        if (full) {

            if (entry_index === null || feed_entries === null) return false;

            return {
                cache: feed_entries,
                index: entry_index
            }

        }

        return return_entry;

    }

    add_locally = (new_entry) => {
        let feed_entries = JSON.parse(sessionStorage.getItem('feed_entries'));

        wrlog(feed_entries)
        wrlog(new_entry)

        if (feed_entries == null) feed_entries = [[]];

        feed_entries.unshift(new_entry);

        sessionStorage.setItem('feed_entries', JSON.stringify(feed_entries));

    }

    set_locally = (id, new_entry) => {

        let feed_entries = JSON.parse(sessionStorage.getItem('feed_entries'));

        let result = false;

        if (feed_entries == null) return null;


        feed_entries.forEach((entry, key) => {

            if (parseInt(entry.feedEntryId) === parseInt(id)) {
                feed_entries[key] = new_entry;
                result = true;
            }

        })


        if (result === true) {
            sessionStorage.setItem('feed_entries', JSON.stringify(feed_entries));
        }

        return result;

    }

    upload_image_as_base64 = async (base64, type, feedEntryId, cb_success, cb_error) => {

        // NEW SOCKET REQUEST
        let args = {
            token: get_user_token(),
            action: 'uploadFile',
            unit: this.name,
            data: {
                feedEntryId: parseInt(feedEntryId),
                meta: JSON.stringify({ type: type }),
                name: "image",
                contentType: base64.split(":")[1].split(";")[0],
                file: base64.split(",")[1]
            }
        }

        this.communicator.call(args, cb_success, cb_error);
    }


    upload_image = async (file, type, feedEntryId, cb_success, cb_error) => {

        // Update the formData object
        getBase64(file).then(base64 => {

            // NEW SOCKET REQUEST
            let args = {
                token: get_user_token(),
                action: 'uploadFile',
                unit: this.name,
                data: {
                    feedEntryId: parseInt(feedEntryId),
                    meta: JSON.stringify({ type: type }),
                    name: file.name,
                    contentType: file.type,
                    file: base64
                }
            }

            this.communicator.call(args, cb_success, cb_error);

        })

        // OLD HTTP REQUEST
        /*

        const formData = new FormData();

        formData.append(
            "file", file
        );

        formData.append(
            "meta", JSON.stringify({
                type: type
            })
        );

        formData.append(
            "feedEntryId", feedEntryId,
        );

        formData.append(
            "token", get_user_token(),
        );

        wrlog(UPLOAD_FEED_FILE_URL);

        const options = {
            method: 'POST',
            url: UPLOAD_FEED_FILE_URL,
            headers: {
                'Content-Type': 'multipart/form-data',
                'content-type': 'multipart/form-data; boundary=---011000010111000001101001'
            },
            data: formData
        };

        axios.request(options).then(function (res) {
            wrlog(res)
            cb_success(res)
        }).catch(function (err) {
            wrlog(err)
            cb_error(err)
        });


        */

    }

    downloadImage = (path, cbSuccess, cbError) => {

        let args = {
            token: get_user_token(),
            action: 'downloadFile',
            unit: this.name,
            data: {
                path: path
            }
        }

        this.communicator.call(args, cbSuccess, cbError);

    }

    delete_image = (internal_name, cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'deleteFeedEntryFile',
            unit: this.name,
            data: {
                internalName: internal_name
            }
        }

        this.communicator.call(args, cb_success, cb_error);
    }

    get_rules = (id, cb_success, cb_error) => {
        let args = {
            token: get_user_token(),
            action: 'getFeedEntryAccess',
            unit: this.name,
            data: {
                feedEntryId: id
            }
        }

        this.communicator.call(args, cb_success, cb_error);
    }

    delete_rules = (id, cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'deleteFeedEntryAccessForFeedEntry',
            unit: this.name,
            data: {
                feedEntryId: id
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    set_rules = (id, rules, cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'addFeedEntryAccess',
            unit: this.name,
            data: {
                feedEntryId: id,
                rules: rules
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }

}



