import { get_user_token } from '../helper/functions/auth';
import Unit from './Unit';

export default class RoleUnit extends Unit {

    constructor(communicator) {

        super(communicator, 'role');

    }

    create = (data, cb_success, cb_error) => {
        let args = {
            token: get_user_token(),
            action: 'create',
            unit: this.name,
            data: data
        }

        this.communicator.call(args, cb_success, cb_error);
    }

    list = (cb_success, cb_error) => {
        let args = {
            token: get_user_token(),
            action: 'read',
            unit: this.name,
            data: {}
        }

        this.communicator.call(args, cb_success, cb_error);
    }

}



