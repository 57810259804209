import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import Notification from '../../../helper/screens/Notification';
import { validation } from '../../../helper/validation';
import ContentUnit from '../../../units/ContentUnit';

// Import Editor
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import List from '@editorjs/list';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import { InputLabel, MenuItem, Select } from '@material-ui/core';

import languages from '../../../helper/data/languages.json';
import Popup from '../../../helper/screens/Popup';
import { removeA } from '../../../helper/misc';

class ContentDetail extends React.Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            is_404: false,
            show_errors: false,
            content: {},
            content_id: null,
            languages: [],
            redirecting: false,
            current_panel: null,
            currently_new: false,
            form: {
                language: {
                    value: '',
                    valid: false,
                    rules: {
                        isRequired: true
                    }
                },
            }
        }

        this.notification = new React.createRef();
        this.popup = new React.createRef();

        this.editor = null;

    }

    componentDidMount() {

        this._init();

    }

    componentDidUpdate() {

        if (!this.state.redirecting) return;

        this.setState({
            redirecting: false
        })

        if (this.editor !== null) this.editor.destroy();
        this.editor = null;
        this._init();

    }

    _init_editor = (data = null) => {

        if (this.editor !== null) {
            return;
        }

        if (data == null || data.blocks === undefined) {
            data = { time: 1601035637587, blocks: [], version: "2.18.0" };
        }

        this.editor = new EditorJS({
            holder: 'editorjs',
            tools: {
                header: {
                    class: Header,
                    inlineToolbar: ['link']
                },
                quote: Quote,
                list: List,
                // image: {
                //     class: ImageTool,
                //     config: {
                //         uploader: {
                //             uploadByFile: this.upload_image
                //         }
                //     }
                // },
                table: {
                    class: Table,
                }
            },
            placeholder: 'Let`s write an awesome story!',
            data: data
        });
    }

    _init = () => {

        let content_id = this.props.match.params.id;
        let language = this.props.match.params.language;

        this.props.set_current_page('/content');

        if (content_id !== null && language !== null) {

            let contentUnit = new ContentUnit(this.props.com);
            let that = this;

            let content_result = contentUnit.get(content_id, language);

            let current_index = content_result.content_index;
            let content = content_result.contents;

            if (content == null) {

                this.setState({
                    content_id: content_id,
                    loading: true
                });

                let cb_success = (res) => {

                    this.setState({
                        content: res.data.staticContent,
                        loading: false,
                        currently_new: false,
                    });

                }

                let cb_error = (res) => {

                    that.setState({
                        loading: false
                    });
                }

                contentUnit.load(content_id, cb_success, cb_error);

            } else {

                // IS NEW VARIATION / LANGUAGE
                if (current_index == null) {

                    let formCopy = this.state.form;
                    let current_languages = [];

                    content.forEach(data => {

                        if (data.page === content_id) {
                            current_languages.push(data.language);
                        }

                    })

                    formCopy.language.value = language;
                    formCopy.language.valid = true;

                    this.setState({
                        currently_new: true,
                        content_id: content_id,
                        current_index: null,
                        content: content,
                        show_title: 'New variation',
                        languages: current_languages,
                        form: formCopy
                    })

                    try {

                        this._init_editor()

                    } catch (error) {
                        console.error(error)
                    }


                } else {

                    let current_languages = [];

                    content.forEach(data => {

                        if (data.page === content[current_index].page) {
                            current_languages.push(data.language);
                        }

                    })

                    let formCopy = this.state.form;

                    formCopy.language.value = content[current_index].language;
                    formCopy.language.valid = true;

                    this.setState({
                        content_id: content_id,
                        current_index: current_index,
                        content: content,
                        show_title: content !== undefined ? 'Edit "' + content[current_index].page + '"' : '',
                        languages: current_languages,
                        form: formCopy,
                        currently_new: false,
                    })


                    let editorContent = null;
                    try {
                        editorContent = JSON.parse(content[current_index].content)
                    } catch {
                        editorContent = { time: 1601035637587, blocks: [], version: "2.18.0" };
                    }

                    this._init_editor(editorContent);

                }


            }

        } else {

            this.setState({
                is_404: true,
            })

        }

    }

    upload_image = (file) => {

        let contentUnit = new ContentUnit(this.props.com);

        contentUnit.upload_image(file, this.state.content_id);

    }

    save = () => {

        let contentUnit = new ContentUnit(this.props.com);
        let that = this;

        this.setState({
            show_errors: false
        })

        let isValid = true;

        if (!this.state.form.language.valid) {
            isValid = false;
            this.setState({
                show_errors: true
            })
        }

        if (!isValid) return;

        this.editor.save().then((content_content) => {

            that.setState({
                loading: true
            })

            let cb_success = (res) => {

                that.setState({
                    loading: false
                });


                let cache = JSON.parse(sessionStorage.getItem('static_content'));

                if (this.state.languages.includes(that.state.form.language.value)) {

                    cache.data.staticContent[that.state.current_index].content = JSON.stringify(content_content);
                    cache.data.staticContent[that.state.current_index].language = this.state.form.language.value;
                    cache.data.staticContent[that.state.current_index].page = this.state.content_id;

                } else {

                    cache.data.staticContent.push({
                        content: JSON.stringify(content_content),
                        page: that.state.content_id,
                        language: that.state.form.language.value
                    })

                }

                sessionStorage.setItem('static_content', JSON.stringify(cache));

                that.notification.current.open('success', 'Page content saved');

            }

            let cb_error = (res) => {

                that.setState({
                    loading: false
                })

                that.notification.current.open('error', 'An error occured, please try again');

            }

            let data = {
                language: that.state.form.language.value,
                page: that.state.content_id,
                content: JSON.stringify(content_content)
            }

            contentUnit.set(data, cb_success, cb_error);

        }).catch((error) => {
        });

    }

    change_value = (key, value) => {

        let formCopy = this.state.form;

        if(typeof value === 'object' && value.target !== undefined){
            value = value.target.value;
        }

        if (formCopy[key].type !== undefined && formCopy[key].type === 'checkbox') {

            formCopy[key].value = !formCopy[key].value;

        } else {

            formCopy[key].value = value;
            formCopy[key].valid = validation(value, formCopy[key].rules);

        }


        this.setState({ form: formCopy });

    }

    changeLanguage = (language, id = null) => {

        if (id == null) {
            id = this.state.content_id;
        }

        this.props.history.push('/content/' + id + '/' + language);

        this.setState({
            redirecting: true
        })

    }

    add_variation = () => {

        let contentCopy = this.state.content;

        contentCopy.push({
            content: "",
            language: this.state.form.language.value,
            page: ""
        });

        this.setState({
            content: contentCopy,
            currently_new: true
        })


        this.popup.current.close();
        this.changeLanguage(this.state.form.language.value);

    }

    delete = () => {

        let that = this;
        let contentUnit = new ContentUnit(this.props.com);
        let cache = JSON.parse(sessionStorage.getItem('static_content'));

        if (cache.data.staticContent.length <= 1) return false;

        const cb_success = () => {

            that.setState({
                loading: false
            });

            let language_key = null;

            cache.data.staticContent.forEach((content, key) => {

                if (content) language_key = key;

            })

            cache.data.staticContent.splice(language_key, 1);

            let languages = this.state.languages;

            languages = removeA(languages, this.state.current_language);

            sessionStorage.setItem('static_content', JSON.stringify(cache));
            this.changeLanguage(languages[0]);

        }

        const cb_error = () => {
            that.setState({
                loading: false
            });
        }


        if (this.state.currently_new) {

            cb_success();

        } else {

            that.setState({
                loading: true
            });
            contentUnit.delete(this.state.content_id, that.state.form.language.value, cb_success, cb_error);

        }

    }


    render() {

        if (this.state.is_404) {
            return (
                <><Link className="go_back_button" to="/content">Go back</Link><br /><br />
                    <h3>This page could not be found</h3></>
            );
        }

        return (
            <>
                <Container fluid style={{ marginLeft: 0 }}>
                    <Row>
                        <Col md={12} style={{ paddingBottom: 50 }}>
                            <Link className="go_back_button" to="/content">Go back</Link><br /><br />
                            <h1>{this.state.show_title}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ paddingRight: 20 }}>
                            <Container fluid>
                                <Row>
                                    <Col md={8} style={{ padding: 0 }}>
                                        <div style={{ display: 'inline-block', width: 300, marginRight: 20 }}>
                                            <InputLabel id="language">Current language</InputLabel>
                                            <Select labelId="language" id="language" value={this.state.form.language.value} onChange={(event) => this.changeLanguage(event.target.value)}>
                                                {Object.keys(languages).map((key) => {

                                                    if (this.state.languages !== undefined && !this.state.languages.includes(key) && key !== this.state.form.language.value) return null;

                                                    return <MenuItem value={key}>{languages[key].name}</MenuItem>
                                                })}

                                            </Select>
                                        </div>
                                        {this.state.content_id !== 'new' ? <div class="add_button" style={{ marginRight: 10 }} onClick={() => this.popup.current.open()}>Add language</div> : null}

                                    </Col>
                                    <Col md={4} style={{ textAlign: "right", padding: 0, paddingTop: 11 }}>
                                        <div className="button" onClick={this.save}>save</div><br /><br />
                                        {this.state.languages.length > 1 ? <div class="small_button" onClick={() => this.delete()}>delete language</div> : null}
                                    </Col>
                                </Row>
                            </Container>

                            <br />
                            <br />

                            <strong>Content</strong>
                            <div id="editorjs" className="editor"></div>
                        </Col>
                    </Row>
                </Container>

                <Loading visible={this.state.loading} />
                <Popup ref={this.popup}>
                    <h3>Add language</h3>

                    <InputLabel id="language">Current language</InputLabel>
                    <Select labelId="language" id="language" value={this.state.form.language.value} onChange={(event) => this.change_value('language', event)}>
                        {Object.keys(languages).map((key) => {

                            if (this.state.languages !== undefined && this.state.languages.includes(key)) return null;
                            return <MenuItem value={key}>{languages[key].name}</MenuItem>

                        })}

                    </Select>

                    <div className="add_button" onClick={() => { this.add_variation() }}>Add language</div>

                </Popup>
                <Notification ref={this.notification} />
            </>

        )
    }
}


export default ContentDetail;