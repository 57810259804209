import { v4 as uuidv4 } from 'uuid';
import { DEBUG, SERVER_HOST, SERVER_WEBSOCKET_URL } from '../store';
import { get_user_token } from './functions/auth';
import { wrlog } from './misc';

/**
 * Communication with server via Sessions
 */
export default class Communicator {

    /**
     * Start connection process 
     * set global variables.
     */
    constructor() {

        this.map_cb = {};

        this.socket = null;

        this.connect = this.connect.bind(this);

        this.connect();

        this.retries = 0;

        this.is_connected = false;
        this.tried_connect = false;

        this.calls_memory = [];
    }

    /**
     * Opens connection to server
     * 
     * Listens to incoming messages and handles messages
     * 
     * On incoming Message callback funcitons are fired or if scd === 705 (invalid token) user automatically is re-logged-in
     * 
     * @return {void} or false
     */
    connect = () => {

        wrlog(this.tried_connect);

        if (this.tried_connect) return false;

        this.tried_connect = true;

        this.socket = new WebSocket(SERVER_WEBSOCKET_URL);

        wrlog('CONNECT');
        wrlog(this.socket);

        let that = this;

        this.socket.onopen = function (event) {

            wrlog('CONNECTED');
            this.retries = 0;
            this.is_connected = true;

        };

        this.socket.onmessage = function (event) {

            let data = JSON.parse(event.data);

            wrlog("RESPONSE FROM SERVER: ", event);
            wrlog("DATA FROM SERVER: ", data);

            if (data.scd === 0) { // SUCCESS

                if (that.map_cb[data.id].do_cache !== false) {
                    that.cache(data, that.map_cb[data.id].do_cache);
                }

                if (that.map_cb[data.id] !== undefined) {
                    that.map_cb[data.id].success(data);
                }

            } else {

                wrlog(data.stx);

                if (data.scd === 705 && !that.map_cb[data.id].is_refresh) { // Invalid Token --> Refresh || Logout // scd = 705

                    wrlog(data.stx + ' REFRESH');

                    that.call({
                        token: get_user_token(),
                        action: 'refreshToken',
                        unit: 'authentication'
                    }, (res) => {

                        wrlog("REFRESH SUCCESS")
                        wrlog("refresh", res);
                        localStorage.setItem('token', res.data.token);

                        that.call(that.map_cb[data.id].args, that.map_cb[data.id].cb_success, that.map_cb[data.id].cb_error, that.map_cb[data.id].do_cache, false)

                    }, () => {
                        wrlog("REFRESH FAILED")
                        localStorage.removeItem('token');
                        alert("Your session expired, you will be redirected to login")
                        window.location.reload();
                    }, false, true)

                } else if (data.scd === 705) {
                    localStorage.removeItem('token');
                    alert("Your session expired, you will be redirected to login")
                    window.location.reload();
                } else {
                    that.map_cb[data.id].error(data);
                }

            }



        };

        this.socket.onclose = function (event) {
            that.is_connected = false;
            that.tried_connect = false;
            wrlog('CONNECTION CLOSED');
            wrlog('CONNECTION CLOSED REFRESH', event);

            if (event.code === 705) {
                wrlog("REFRESH FAILED")
                localStorage.removeItem('token');
                alert("Your session expired, you will be redirected to login")
                window.location.reload();
            } else if (event.code === 1006) {
                wrlog("REFRESH FAILED 1")
                window.location.reload();
            }
        }

    }

    /**
     * Closes connection to server
     * 
     * @return {void}
     */
    close = () => {
        this.socket.close();
    }

    /**
     * perform the call to server and sets Communicator cache to be able 
     * to call callback functions when message comes from server
     * 
     * @param {Object} args 
     * @param {Function} cbSuccess 
     * @param {Function} cbError 
     * @param {Boolean} doCache 
     * @param {Boolean} tryAgain 
     * 
     * @return {void}
     */
    call = (args, cb_success = () => { }, cb_error = () => { }, do_cache = false, is_refresh = false) => {

        let uuid = uuidv4();
        let that = this;
        args.id = uuid;
        args.client = {
            id: 'oegb-life-app',
            name: 'oegb-life-app',
        };
        args.timestamp = new Date().getTime().toString();

        wrlog(args);
        wrlog(JSON.stringify(args));

        this.waitForConnection(function () {
            that.socket.send(JSON.stringify(args));
            that.map_cb[uuid] = {};
            that.map_cb[uuid].args = args;
            that.map_cb[uuid].success = cb_success;
            that.map_cb[uuid].error = cb_error;
            that.map_cb[uuid].do_cache = do_cache;
            that.map_cb[uuid].is_refresh = is_refresh;
        }, 1000);

    }

    waitForConnection = (callback, interval) => {

        wrlog("SOCKET", this.socket);

        if (this.socket.readyState === 1) {
            callback();
        } else {
            var that = this;
            this.retries = this.retries + 1;
            wrlog("retries", this.retries);
            this.connect();
            // optional: implement backoff for interval here
            if (this.retries < 10) {
                setTimeout(function () {
                    that.waitForConnection(callback, interval);
                }, interval);
            } else {
                localStorage.removeItem('token');
                alert("ERROR: The connection to the server was refused.");
                window.location.reload();
            }
        }
    };

    cache = (data, key) => {

        sessionStorage.setItem(key, JSON.stringify(data));

    }


}


