import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {calculateUtcTimezone, formatDatetime, getWorkingSubjectsAndAreas, removeA, wrlog} from '../../../helper/misc';
import Loading from '../../../helper/screens/Loading';
import Notification from '../../../helper/screens/Notification';
import {validation} from '../../../helper/validation';
import PushUnit from '../../../units/PushUnit';

import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

import {
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';

import languages from '../../../helper/data/languages.json';
import CustomCheckbox from '../../../helper/screens/CustomCheckbox';
import Popup from '../../../helper/screens/Popup';

import regions from '../../../helper/data/regions.json';
import Accordion from '../../../helper/screens/Accordion';
import FeedUnit from '../../../units/FeedUnit';
import {get_title} from '../../../helper/functions/push';
import VocationAccordion from '../../../helper/screens/VocationAccordion';
import moment from "moment";

export default class PushDetail extends React.Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            loading_rules: true,
            is_404: false,
            show_errors: false,
            notification: {},
            notification_id: null,
            current_variation: null,
            redirecting: false,
            languages: [],
            feed: [],
            filter: 'draft',
            origin: '',
            potential_reach: null,
            potential_reach_loading: false,
            rules_updated: false,
            selected_vocation_categories: [],
            sentVocationsShow: '',
            form: {
                title: {
                    value: '',
                    valid: false,
                    rules: {
                        isRequired: true
                    }
                },
                optional_title: {
                    value: '',
                    valid: false,
                    rules: {
                        isRequired: true
                    }
                },
                destination_type: {
                    value: '',
                    valid: false,
                    rules: {
                        isRequired: true
                    }
                },
                destination_screen: {
                    value: '',
                    valid: false,
                    rules: {
                        isRequired: true
                    }
                },
                schedule_datetime: {
                    value: new Date(),
                    valid: true,
                    rules: {
                    }
                },
                only_members: {
                    type: 'checkbox',
                    value: false,
                    valid: true,
                    rules: {
                    }
                },
                regions: {
                    type: 'checkbox_array',
                    value: [],
                    valid: true,
                    rules: {
                    }
                },
                vocations: {
                    type: 'checkbox_array',
                    value: [],
                    valid: true,
                    rules: {
                    }
                },
                language: {
                    value: 'DE',
                    valid: false,
                    rules: {
                        isRequired: true
                    }
                },
                language_title: {
                    value: '',
                    valid: false,
                    rules: {
                        isRequired: true
                    }
                },
                language_body: {
                    value: '',
                    valid: false,
                    rules: {
                        isRequired: true
                    }
                },

            },
            workSubjectsAndAreas: null
        }

        this.notification = new React.createRef();
        this.popup = new React.createRef();
        this.popup_update = new React.createRef();
        this.popup_send = new React.createRef();
        this.popup_schedule = new React.createRef();
        this.sidebar_rightRef = new React.createRef();
        this.sidebar_right_burgerRef = new React.createRef();

        this.pushUnit = '';

    }

    componentDidMount() {

        this.pushUnit = new PushUnit(this.props.com);

        if (this.props.location !== undefined &&
            this.props.location.state !== undefined) {

            if (this.props.location.state.filter !== undefined) {
                this.setState({
                    filter: this.props.location.state.filter
                })
            }

            if (this.props.location.state.origin !== undefined) {
                this.setState({
                    origin: this.props.location.state.origin
                })
            }

        }

        let feed = new FeedUnit(this.props.com);

        let cb_success = (res) => {

            let entries = res.data.feed;

            this.setState({
                feed: entries
            });

        }

        let cb_error = (res) => {

        }


        feed.list(cb_success, cb_error);

        this._init();

    }

    componentDidUpdate() {

        if (!this.state.redirecting) return;

        this.setState({
            redirecting: false
        })

        this._init();
    }

    toggleMobileNav = () => {
        this.sidebar_rightRef.current.classList.toggle('open')
        this.sidebar_right_burgerRef.current.classList.toggle('open')
    }

    _init = () => {

        let notification_id = this.props.match.params.id;

        if (this.props.location !== undefined &&
            this.props.location.state !== undefined &&
            this.props.location.state.origin !== undefined) {

            this.props.set_current_page('/statistics');

        } else {
            this.props.set_current_page('/push');
        }
        if (Number.isInteger(parseInt(notification_id))) {

            let that = this;

            let notification = this.pushUnit.get(notification_id);

            if (notification == null) {

                this.setState({
                    notification_id: notification_id,
                    loading: true
                });

                let cb_success = (res) => {

                    let notification = null;
                    res.data.pushNotifications.forEach(loaded_notification => {

                        if (parseInt(notification_id) === loaded_notification.id) notification = loaded_notification;

                    })

                    if (notification === null) {

                        this.setState({
                            is_404: true
                        })

                        return;
                    }

                    let languages = [];
                    notification.data.forEach(translation => {
                        languages.push(translation.language)
                    })

                    let formCopy = this.state.form;

                    let action = JSON.parse(notification.action);

                    formCopy.destination_type.value = action.type
                    formCopy.destination_screen.value = action.screen

                    formCopy.destination_type.valid = true
                    formCopy.destination_screen.valid = true

                    formCopy.optional_title.value = notification.internalTitle;
                    formCopy.optional_title.valid = true;



                    this.setState({
                        notification: notification,
                        loading: false,
                        languages: languages,
                        form: formCopy
                    });

                    this.groupVocationsSent();


                    this.setState({
                        potential_reach_loading: true
                    })

                    this.pushUnit.get_potential_reach(parseInt(notification_id), (res) => {

                        this.setState({
                            potential_reach_loading: false,
                            potential_reach: res.data.numberOfAppUsersAffected
                        })

                    }, () => {

                        this.setState({
                            potential_reach_loading: false
                        })

                    })

                }

                let cb_error = (res) => {

                    that.setState({
                        loading: false
                    });

                }

                this.pushUnit.list(cb_success, cb_error);

            } else {

                let languages = [];

                notification.data.forEach(translation => {
                    languages.push(translation.language);
                });

                this.setState({
                    potential_reach_loading: true
                })

                this.pushUnit.get_potential_reach(parseInt(notification_id), (res) => {

                    this.setState({
                        potential_reach_loading: false,
                        potential_reach: res.data.numberOfAppUsersAffected
                    })

                }, () => {

                    this.setState({
                        potential_reach_loading: false
                    })

                })

                let formCopy = this.state.form;

                let action = JSON.parse(notification.action);

                formCopy.destination_type.value = action.type
                formCopy.destination_screen.value = action.screen

                formCopy.destination_type.valid = true;
                formCopy.destination_screen.valid = true;

                formCopy.optional_title.value = notification.internalTitle;
                formCopy.optional_title.valid = true;

                this.setState({
                    notification_id: notification_id,
                    notification: notification,
                    languages: languages,
                    form: formCopy
                });

            }

            // GET RULES

            const cb_success_rules = (res) => {

                let formCopy = this.state.form;

                let regions = [];
                let vocations = [];

                res.data.rules.forEach(rule => {

                    if (rule.member === true) {
                        formCopy.only_members.value = true;
                    }
                    if (rule.region !== undefined && !regions.includes(rule.region)) {
                        regions.push(rule.region);
                    }
                    if (rule.subjectArea !== undefined && !vocations.includes(rule.subjectArea)) {
                        vocations.push(rule.subjectArea);
                    }

                });

                if (regions.length > 0) {
                    formCopy.regions.value = regions;
                }

                if (vocations.length > 0) {
                    formCopy.vocations.value = vocations;
                }

                this.setState({
                    loading_rules: false,
                    form: formCopy
                })

                this.groupVocationsSent();

            }
            const cb_error_rules = () => {



            }



            this.pushUnit.get_rules(parseInt(notification_id), cb_success_rules, cb_error_rules);

        } else if (notification_id === 'new') {

            this.setState({
                notification_id: notification_id,
                notification: { data: [] },
                loading_rules: false,
            })

        } else {

            this.setState({
                is_404: true,
            })

        }

        getWorkingSubjectsAndAreas().then((result) => {
            this.setState({
                workSubjectsAndAreas: JSON.parse(result.toString()),
            })
        }).catch((error) => wrlog('ErrorInLoadingDetail ', error))
    }

    save = (cb = null) => {

        let that = this;

        this.setState({
            show_errors: false
        })

        let isValid = true;

        let formCopy = this.state.form;

        if (!formCopy.destination_type.valid || !formCopy.destination_screen.valid || this.state.notification.data.length < 1) {
            isValid = false;
            this.setState({
                show_errors: true
            })

            if (this.state.notification.data.length < 1) {
                this.notification.current.open('error', 'Please provide at least one Variant');
            }
        }


        if (!isValid) return;

        let rules = [];
        const only_members = this.state.form.only_members.value;

        if (that.state.form.regions.value.length > 0) {

            that.state.form.regions.value.forEach(region => {

                if (that.state.form.vocations.value.length > 0) {

                    that.state.form.vocations.value.forEach(vocation => {

                        let rule = {};

                        if (only_members === true) rule['ismember'] = true;

                        rule['region'] = region;
                        rule['subjectArea'] = vocation;

                        rules.push(rule);

                    })

                } else {

                    let rule = {};

                    if (only_members === true) rule['ismember'] = true;

                    rule['region'] = region;

                    rules.push(rule);

                }

            })

        } else {

            if (that.state.form.vocations.value.length > 0) {

                that.state.form.vocations.value.forEach(vocation => {

                    let rule = {};

                    if (only_members === true) rule['ismember'] = true;

                    rule['subjectArea'] = vocation;

                    rules.push(rule);

                })

            } else {

                if (only_members === true) rules.push({ ismember: true });

            }

        }

        that.setState({
            loading: true
        })

        let cb_success_add = (res) => {

            that.pushUnit.list((res_list) => {

                let notification = null;

                res_list.data.pushNotifications.forEach(loaded_notification => {
                    if (parseInt(loaded_notification.id) === parseInt(res.data.id)) notification = loaded_notification;
                })

                that.setState({
                    notification: notification,
                    notification_id: res.data.id
                })

            })

            this.setState({
                potential_reach_loading: true
            })

            this.pushUnit.get_potential_reach(parseInt(res.data.id), (res_potential) => {

                this.setState({
                    potential_reach_loading: false,
                    potential_reach: res_potential.data.numberOfAppUsersAffected
                })

            }, () => {

                this.setState({
                    potential_reach_loading: false
                })

            })

            if (cb === null) {

                that.setState({
                    loading: false,
                    notification_id: res.data.id,
                    redirecting: true
                });

                this.props.history.push('/push/' + res.data.id);

                that.notification.current.open('success', 'Push-Notification created');

            } else {
                cb(res.data.id);
            }

        }


        let cb_success_update = (res) => {

            let cb_update_rules = (res_update_rules = null) => {

                that.pushUnit.list((res_list) => {

                    let notification = null;

                    res_list.data.pushNotifications.forEach(loaded_notification => {
                        if (parseInt(loaded_notification.id) === parseInt(that.state.notification_id)) notification = loaded_notification;
                    })

                    that.setState({
                        notification: notification
                    })

                })

                this.setState({
                    potential_reach_loading: true
                })

                this.pushUnit.get_potential_reach(parseInt(this.state.notification_id), (res) => {

                    this.setState({
                        potential_reach_loading: false,
                        potential_reach: res.data.numberOfAppUsersAffected
                    })

                }, () => {

                    this.setState({
                        potential_reach_loading: false
                    })

                })

                if (cb === null) {

                    that.setState({
                        loading: false,
                    });

                    that.notification.current.open('success', 'Push-Notification updated');

                } else {

                    cb(this.state.notification_id);

                }

            }

            if (that.state.rules_updated === true) {

                that.setState({
                    rules_updated: false
                })

                that.pushUnit.delete_rules(parseInt(that.state.notification_id), () => {

                    that.pushUnit.set_rules(parseInt(that.state.notification_id), rules, cb_update_rules, (err) => { })

                }, (err) => { })

            } else {

                cb_update_rules();

            }



        }


        let cb_error = (res) => {

            that.setState({
                loading: false
            })

            that.notification.current.open('error', 'An error occured, please try again');

        }

        if (rules.length < 1) rules = [{}];

        if (that.state.notification_id === 'new') {

            let data = {
                action: JSON.stringify({
                    type: formCopy.destination_type.value,
                    screen: formCopy.destination_screen.value
                }),
                internalTitle: formCopy.optional_title.value,
                data: this.state.notification.data,
                rules: rules
            }

            this.pushUnit.create(data, cb_success_add, cb_error)

        } else {

            let data = {
                pushNotificationId: parseInt(this.state.notification_id),
                internalTitle: formCopy.optional_title.value,
                action: JSON.stringify({
                    type: formCopy.destination_type.value,
                    screen: formCopy.destination_screen.value
                }),
                data: this.state.notification.data
            }

            this.pushUnit.update(data, cb_success_update, cb_error);

        }

    }

    deliver = (id = null) => {

        const cb_success = (res) => {

            this.pushUnit.list((res_list) => {

                let notification = null;

                res_list.data.pushNotifications.forEach(loaded_notification => {
                    if (parseInt(loaded_notification.id) === parseInt(id)) notification = loaded_notification;
                })

                this.setState({
                    redirecting: true,
                    notification: notification,
                    loading: false
                })

                this.props.history.push('/push/' + id);
                //window.location.reload();

            })

            this.notification.current.open('success', 'Push-Notification sent');

        }

        const cb_error = (err) => {
        }

        this.pushUnit.send(parseInt(id), null, cb_success, cb_error);

    }

    schedule = (id = null) => {

        if (id == null) {
            id = this.state.notification_id;
        }

        let formCopy = this.state.form;

        if (!formCopy.schedule_datetime.valid) {

            this.setState({
                loading: false,
            });

            this.notification.current.open('error', 'Push-Notification saved but not scheduled. Please review your schedule-date inputs.1');
            return false;

        }

        const cb_success = (res) => {

            this.pushUnit.list((res_list) => {

                let notification = null;

                res_list.data.pushNotifications.forEach(loaded_notification => {
                    if (parseInt(loaded_notification.id) === parseInt(id)) notification = loaded_notification;
                })

                this.props.history.push('/push/' + id);

                this.setState({
                    loading: false,
                    notification: notification,
                    redirecting: true
                })

                this.notification.current.open('success', 'Push-Notification scheduled');

            })



        }

        const cb_error = (err) => {

            this.notification.current.open('error', 'Push-Notification saved but not scheduled. Please review your schedule-date inputs.');

            this.setState({
                loading: false,
            });
        }

        this.pushUnit.send(parseInt(id), calculateUtcTimezone(formCopy.schedule_datetime.value), cb_success, cb_error);

    }

    unschedule = () => {

        const cb_success = (res) => {

            this.pushUnit.list((res_list) => {

                let notification = null;

                res_list.data.pushNotifications.forEach(loaded_notification => {
                    if (parseInt(loaded_notification.id) === parseInt(this.state.notification_id)) notification = loaded_notification;
                })

                this.setState({
                    notification: notification,
                    loading: false,
                })

                this.notification.current.open('success', 'Push-Notification unscheduled');

            })


        }

        const cb_error = (err) => {

            this.notification.current.open('error', 'Push-Notification saved but not scheduled. Please review your schedule-date inputs.');

            this.setState({
                loading: false,
            });
        }

        this.setState({
            loading: true,
        });

        this.pushUnit.unschedule(parseInt(this.state.notification_id), cb_success, cb_error);

    }

    change_value = (key, value) => {

        let formCopy = this.state.form;

        if (typeof value === 'object' && value.target !== undefined) {
            value = value.target.value;
        }

        if (formCopy[key].type !== undefined && formCopy[key].type === 'checkbox') {

            formCopy[key].value = !formCopy[key].value;

        } else {

            formCopy[key].value = value;
            formCopy[key].valid = validation(value, formCopy[key].rules);

        }

        if (key === 'only_members') {

            this.setState({
                rules_updated: true,
                form: formCopy
            });

        } else {

            this.setState({
                form: formCopy
            });

        }

    }

    add_variation = () => {

        let notificationCopy = this.state.notification;
        let formCopy = this.state.form;

        if (!this.state.form.language_title.valid || !this.state.form.language.valid || !this.state.form.language_body.valid) {
            this.setState({
                show_errors: true
            })
            return false;
        }

        notificationCopy.data.push({
            content: this.state.form.language_body.value,
            language: this.state.form.language.value,
            title: this.state.form.language_title.value,
            only_locally: true
        });
        let languages = this.state.languages;
        languages.push(formCopy.language.value);

        formCopy.language.value = "";
        formCopy.language_title.value = "";
        formCopy.language_body.value = "";

        formCopy.language.valid = false;
        formCopy.language_title.valid = false;
        formCopy.language_body.valid = false;


        this.setState({
            notification: notificationCopy,
            form: formCopy,
            languages: languages
        })

        this.popup.current.close();

    }

    update_variation = () => {

        let notificationCopy = this.state.notification;
        let formCopy = this.state.form;

        if (!this.state.form.language_title.valid || !this.state.form.language_body.valid) {
            this.setState({
                show_errors: true
            })
            return false;
        }
        let languages = this.state.languages;

        languages = removeA(languages, notificationCopy.data[this.state.current_variation].language);

        notificationCopy.data[this.state.current_variation].content = this.state.form.language_body.value
        notificationCopy.data[this.state.current_variation].language = this.state.form.language.value
        notificationCopy.data[this.state.current_variation].title = this.state.form.language_title.value

        languages.push(formCopy.language.value);

        formCopy.language.value = "";
        formCopy.language_title.value = "";
        formCopy.language_body.value = "";

        formCopy.language.valid = false;
        formCopy.language_title.valid = false;
        formCopy.language_body.valid = false;


        this.setState({
            notification: notificationCopy,
            form: formCopy,
            languages: languages
        });

        this.popup_update.current.close();

    }

    remove_variation = (key) => {

        let notification_copy = this.state.notification;

        let curr_translation = notification_copy.data[key];

        const cb_success = () => {
            let languages = this.state.languages;

            languages = removeA(languages, curr_translation.language);

            notification_copy.data.splice(key, 1);

            this.setState({
                notification: notification_copy,
                languages: languages,
                loading: false
            });
        }

        const cb_error = () => {

        }



        if (curr_translation.only_locally === undefined) {

            this.setState({
                loading: true
            });

            this.pushUnit.delete_variant(parseInt(this.state.notification_id), curr_translation.language, cb_success, cb_error)

        } else {
            cb_success();
        }



    }

    changeRegions = (value) => {

        let formCopy = this.state.form;
        let selectedRegions = formCopy.regions.value;
        let position = selectedRegions.indexOf(value);

        if (position > -1) {
            selectedRegions.splice(position, 1);
        } else {
            selectedRegions.push(value);
        }

        formCopy.regions.value = selectedRegions;

        this.setState({
            rules_updated: true,
            form: formCopy
        })

    }

    changeVocations = (value) => {

        let formCopy = this.state.form;
        let selectedVocations = formCopy.vocations.value;
        let position = selectedVocations.indexOf(value);

        if (position > -1) {
            selectedVocations.splice(position, 1);
        } else {
            selectedVocations.push(value);
        }

        formCopy.vocations.value = selectedVocations;

        this.setState({
            rules_updated: true,
            form: formCopy
        })

    }

    changeVocationsCategories = (key) => {

        let formCopy = this.state.form;
        let selectedVocations = formCopy.vocations.value;

        // DESELECT ALL

        let selected_all = true;

        if (!selectedVocations.includes(key)) {
            selected_all = false;
        }

        let new_selected_vocations = [];

        if (selected_all) {
            selectedVocations.forEach((mvzvCode) => {
                if (mvzvCode !== key) {
                    new_selected_vocations.push(key);
                }
            })
        } else {
            new_selected_vocations = selectedVocations;

            if (!new_selected_vocations.includes(key)) {
                new_selected_vocations.push(key);
            }
        }

        formCopy.vocations.value = new_selected_vocations;

        this.setState({
            rules_updated: true,
            form: formCopy,
        })


    }

    /**
     * cbAddAllSubjectsFromArea
     * @param {name: string, mvzvCode: number, children: {name: string, mvzvCode: number}} subjectsFromArea
     * */
    cbAddAllSubjectsFromArea = (subjectsFromArea) => {
        const newSubjects = [...this.state.form.vocations.value];

        !newSubjects.includes(subjectsFromArea.mvzvCode) && newSubjects.push(subjectsFromArea.mvzvCode);

        subjectsFromArea.children && subjectsFromArea.children.map((singleSubject) => {
            !newSubjects.includes(singleSubject.mvzvCode) && newSubjects.push(singleSubject.mvzvCode);
        });

        this.setState({
            rules_updated: true,
            form: {
                ...this.state.form,
                vocations: {
                    ...this.state.form.vocations,
                    value: newSubjects
                }
            }
        })
    }

    /**
     * cbRemoveAllSubjectsFromArea
     * @param {name: string, mvzvCode: number, children: Array<{name: string, mvzvCode: number}>} subjectsFromArea
     * */
    cbRemoveAllSubjectsFromArea = (subjectsFromArea) => {
        const elementsToRemove = [subjectsFromArea.mvzvCode];

        subjectsFromArea.children && subjectsFromArea.children.forEach((singleSubject) => {
            elementsToRemove.push(singleSubject.mvzvCode)
        })


        this.setState({
            rules_updated: true,
            form: {
                ...this.state.form,
                vocations: {
                    ...this.state.form.vocations,
                    value: this.state.form.vocations.value.filter((single) => !elementsToRemove.includes(single))
                }
            }
        })
    }

    handleAccordion = (panel) => {

        if (this.state.current_panel === panel) panel = null;

        this.setState({
            current_panel: panel
        })

    }

    delete = () => {

        let that = this;

        let cb_success = (res) => {

            that.pushUnit.list((res) => {

                that.props.history.push('/push');

            }, () => { })

        }

        let cb_error = (res) => {

            that.setState({
                loading: false
            });

            that.notification.current.open('error', 'Please wait to delete this entry.');

        }

        if (this.state.notification_id === 'new') {
            cb_success()
        } else {
            this.setState({
                loading: true
            })
            this.pushUnit.delete(parseInt(this.state.notification_id), cb_success, cb_error)
        }

    }

    groupVocationsSent = () => {

        let output = [];

        this.state.workSubjectsAndAreas && this.state.workSubjectsAndAreas.forEach((singleWorkArea) => {
            if (this.state.form.vocations.value.includes(singleWorkArea.mvzvCode))
                output.push(singleWorkArea.name + ' (' + singleWorkArea.mvzvCode + ')')
            singleWorkArea.children && singleWorkArea.children.forEach((singleWorkSubject) => {
                if (this.state.form.vocations.value.includes(singleWorkSubject.mvzvCode))
                    output.push(singleWorkSubject.name + ' (' + singleWorkSubject.mvzvCode + ')')
            })
        })

        this.setState({
            sentVocationsShow: output.length > 1 ? output.join(', ') : output.length > 0 ? output : '',
        })

    }

    get_destination = (action) => {

        try {
            action = JSON.parse(action);
        } catch {
            return 'N/A';
        }


        if (action.type === 'feed'){

            let feedEntryTitle = '';

            this.state.feed.forEach(feedEntry => {
                if(feedEntry.feedEntryId === action.screen){
                    feedEntryTitle = ' > ' + feedEntry.title
                }
            })

            return 'Feedentry' + feedEntryTitle

        }

        if (action.type === 'module') {
            return 'module > ' + action.screen;
        }

        return 'open app'

    }

    render() {

        if (this.state.is_404) {
            return (
                <><Link className="go_back_button"
                    to={{
                        pathname: this.state.origin + `/push`,
                        state: { filter: this.state.filter || 'all' }
                    }}
                >Go back</Link><br /><br />
                    <h3>The Content was not found</h3></>
            );
        }

        if (this.state.notification !== undefined && this.state.notification !== null && this.state.notification.sent === true) {

            return (

                <>
                    <Container fluid >
                        <Row>
                            <Col md={12} style={{ paddingBottom: 20 }}>
                                <Link className="go_back_button"
                                    to={{
                                        pathname: `${this.state.origin}/push`,
                                        state: { filter: this.state.filter || 'all' }
                                    }}
                                >Go back</Link><br /><br />
                                <h1>{this.state.show_title}</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{ paddingRight: 20 }}>
                                <h1>
                                    {this.state.form.optional_title.value}
                                </h1>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4}>
                                <strong>Date - Time</strong>
                                <br />
                                {formatDatetime(this.state.notification.time)}h
                                <br />
                                <br />
                                <br />
                                <strong>Destination</strong>
                                <br />
                                {this.get_destination(this.state.notification.action)}

                            </Col>
                            <Col md={8}>
                                <strong>Targets</strong> ({this.state.notification.numberOfTargets} {this.state.notification.numberOfTargets !== 1 ? 'devices' : 'device'})
                                <br />
                                {this.state.loading_rules ? <>
                                    <Loading skeleton visible={this.state.loading_rules} relative style={{}} />
                                    <Loading skeleton visible={this.state.loading_rules} relative style={{}} />
                                </> :
                                    this.state.form.vocations.value.length < 1 && this.state.form.regions.value.length < 1 && this.state.form.only_members.value === false ? 'Sent to everybody' :
                                        (
                                            (this.state.form.vocations.value.length > 0 ? "Vocations: \n \n" + this.state.sentVocationsShow + '\n\n' : '') +
                                            (this.state.form.regions.value.length > 0 ? "Regions: \n" + this.state.form.regions.value.map(region => { return regions[region] }).join(', ') + '\n\n' : '') +
                                            (this.state.form.only_members.value === true ? "Only Members" : '')
                                        )
                                }
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 30 }}>
                            <Col md={12}>

                                <strong>Variants</strong>
                                <Table style={{ marginTop: 0 }}>
                                    <TableBody>
                                        {this.state.notification !== null && this.state.notification.data !== undefined ? this.state.notification.data.map((translation, key) => {

                                            return <TableRow key={key} style={{ verticalAlign: 'top' }}>
                                                <TableCell style={{ width: 240 }}>
                                                    {languages[translation.language].name}
                                                </TableCell>
                                                <TableCell>
                                                    <strong>{translation.title}</strong>
                                                    <p>{translation.content}</p>
                                                </TableCell>
                                            </TableRow>

                                        }) : null}
                                    </TableBody>
                                </Table>
                            </Col>
                        </Row>

                    </Container>
                    <Notification ref={this.notification} />
                </>



            )

        } else {

            return (
                <>
                    <div
                        className="burger_right"
                        ref={this.sidebar_right_burgerRef}
                        onClick={() => {
                            this.toggleMobileNav()
                        }}
                    ></div>
                    <Container fluid className="middle_container" >
                        <Row>
                            <Col md={12} style={{ paddingBottom: 20 }}>
                                <Link className="go_back_button"
                                    to={{
                                        pathname: `${this.state.origin}/push`,
                                        state: { filter: this.state.filter || 'all' }
                                    }}
                                >Go back</Link><br /><br />

                                <h1>{this.state.show_title}</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{ paddingRight: 20 }}>

                                <TextField id="title" value={this.state.form.optional_title.value} error={!this.state.form.optional_title.valid && this.state.show_errors} label="Internal-Title (not public)" onChange={value => this.change_value('optional_title', value)} />
                                <br />
                                <br />
                                <br />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} style={{ paddingRight: 20 }}>

                                <strong>Destination</strong><br /><br />
                                <InputLabel id="destination_type">Type</InputLabel>
                                <Select labelId="destination_type" id="destination_type" value={this.state.form.destination_type.value} error={!this.state.form.destination_type.valid && this.state.show_errors} onChange={(event) => this.change_value('destination_type', event)}>

                                    <MenuItem value={'module'}>Module</MenuItem>
                                    <MenuItem value={'feed'}>FeedEntry</MenuItem>

                                </Select>

                                <br /><br />
                                {this.state.form.destination_type.value === 'module' ?
                                    <><InputLabel id="destination_screen">Select Module</InputLabel>
                                        <Select labelId="destination_screen" id="destination_screen" value={this.state.form.destination_screen.value} error={!this.state.form.destination_screen.valid && this.state.show_errors} onChange={(event) => this.change_value('destination_screen', event)}>

                                            <MenuItem value={'feed'}>Feed</MenuItem>
                                            <MenuItem value={'chat'}>Chat</MenuItem>
                                            <MenuItem value={'timetracking'}>Timetracking</MenuItem>

                                        </Select></> : null
                                }

                                {this.state.form.destination_type.value === 'feed' ?
                                    <>
                                        <InputLabel id="destination_screen">Select FeedEntry</InputLabel>
                                        <Select labelId="destination_screen" id="destination_screen" value={this.state.form.destination_screen.value} onChange={(event) => this.change_value('destination_screen', event)}>

                                            {this.state.feed !== [] ? this.state.feed.map((entry) => {
                                                if (entry === undefined) return null;

                                                return <MenuItem value={entry.feedEntryId}>{get_title(entry)}</MenuItem>

                                            }) : null}


                                        </Select>
                                    </> : null
                                }

                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{ paddingRight: 20 }}>

                                <Table style={{ marginTop: 10 }}>
                                    <TableHead>
                                        <TableRow style={{ verticalAlign: 'bottom' }}>
                                            <TableCell>
                                                <strong>Variants</strong>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'right' }} colSpan={3}>
                                                <div className="add_button" style={{ marginTop: 50 }} onClick={() => {

                                                    let formCopy = this.state.form;

                                                    formCopy.language.value = "";
                                                    formCopy.language_title.value = "";
                                                    formCopy.language_body.value = "";

                                                    formCopy.language.valid = false;
                                                    formCopy.language_title.valid = false;
                                                    formCopy.language_body.valid = false;

                                                    this.setState({
                                                        form: formCopy,
                                                        current_variation: null
                                                    });

                                                    this.popup.current.open()
                                                }}>Add variant</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.notification !== null && this.state.notification.data !== undefined ? this.state.notification.data.map((translation, key) => {

                                            return <TableRow key={key} style={{ verticalAlign: 'top' }}>
                                                <TableCell style={{ width: 240 }}>
                                                    {languages[translation.language].name}
                                                </TableCell>
                                                <TableCell>
                                                    <strong>{translation.title}</strong>
                                                    <p>{translation.content}</p>
                                                </TableCell>
                                                <TableCell style={{ width: 100 }}>
                                                    <div className="small_button" onClick={
                                                        () => {

                                                            let formCopy = this.state.form;

                                                            formCopy.language.value = this.state.notification.data[key].language;
                                                            formCopy.language_title.value = this.state.notification.data[key].title;
                                                            formCopy.language_body.value = this.state.notification.data[key].content;

                                                            formCopy.language.valid = true;
                                                            formCopy.language_title.valid = true;
                                                            formCopy.language_body.valid = true;

                                                            this.setState({
                                                                form: formCopy,
                                                                current_variation: key
                                                            });
                                                            this.popup_update.current.open()
                                                        }
                                                    }>edit</div>
                                                </TableCell>
                                                <TableCell style={{ width: 100 }}>
                                                    <div className="small_button" onClick={() => { this.remove_variation(key) }}>remove</div>
                                                </TableCell>
                                            </TableRow>

                                        }) : null}
                                    </TableBody>
                                </Table>
                                {/* {if (!this.state.languages.includes(key) && this.state.notification_id !== 'new') return;

                                        return <MenuItem value={key}>{languages[key].name}</MenuItem>
                                    }) : null } */}

                                <br />
                                <br />

                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                {this.state.notification.sent !== true && (this.state.notification.time === undefined || this.state.notification.time === null) ? <div className="button" style={{ marginRight: 20 }} onClick={() => this.popup_send.current.open()}>Send Push-Notification</div> : 'To immediately send you first need to unschedule.'}
                                {this.state.notification.sent !== true && (this.state.notification.time !== undefined && this.state.notification.time !== null) ? <div className="small_button" style={{ marginLeft: 20 }} onClick={() => { this.unschedule() }}>Unschedule Push-Notification</div> : null}
                                {this.state.notification.sent !== true && (this.state.notification.time === undefined || this.state.notification.time === null) ? <div className="small_button" style={{ marginLeft: 20 }} onClick={() => { this.popup_schedule.current.open() }}>Schedule Push-Notification</div> : null}
                            </Col>
                        </Row>

                    </Container>
                    <div className="sidebar_right" ref={this.sidebar_rightRef} style={{paddingTop: 100}}>

                        <div className="button" style={{marginRight: 20}} onClick={() => {
                            this.save()
                        }}>Save
                        </div>
                        {this.state.notification_id !== 'new' ? <div className="small_button" onClick={() => {
                            this.delete()
                        }}>delete</div> : null}<br/>

                        <br/><br/>

                        <strong>Time</strong>
                        {
                            this.state.notification.time !== undefined && this.state.notification.time !== null ?
                                formatDatetime(this.state.notification.time) + '' : 'N/A'
                        }

                        <br/>
                        <br/>
                        <br/>

                        <strong>Potential Reach</strong><br/>
                        {
                            (this.state.potential_reach === null || this.state.notification_id === 'new') && !this.state.potential_reach_loading ?
                                <span
                                    className="red">Notification needs to be saved before potential reach is loaded</span> : null
                        }
                        <Loading skeleton visible={this.state.potential_reach_loading} relative style={{}}/>
                        <Loading skeleton visible={this.state.potential_reach_loading} relative style={{}} />

                        {
                            this.state.potential_reach !== null && !this.state.potential_reach_loading ? '~ ' + this.state.potential_reach + ' apps' : null
                        }
                        <br />
                        <br />
                        <br />

                        <strong>Restrict publication</strong>

                        <br />
                        <br />
                        {this.state.loading_rules ? <>
                            <Loading skeleton visible={this.state.loading_rules} relative style={{}} />
                            <Loading skeleton visible={this.state.loading_rules} relative style={{}} />
                        </> : <>
                            <FormControlLabel
                                value={true}
                                control={<CustomCheckbox checked={this.state.form.only_members.value} />}
                                label={'Only members'}
                                labelPlacement="end"
                                onChange={(event) => this.change_value('only_members', event)}
                                style={{ marginBottom: 10 }}
                            />

                            <br />
                            <br />
                            <br />

                            <Accordion expanded={this.state.current_panel === 'panel1'} onClick={() => this.handleAccordion('panel1')} title={"Regions (" + this.state.form.regions.value.length + ")"}>

                                {Object.keys(regions).map((key) => {
                                    return <><FormControlLabel
                                        value={key}
                                        key={key}
                                        control={<CustomCheckbox checked={this.state.form.regions.value.includes(key)} />}
                                        label={regions[key]}
                                        labelPlacement="end"
                                        onChange={() => this.changeRegions(key)}
                                        style={{ marginBottom: 10 }}
                                    /><br /></>
                                })}
                            </Accordion>
                            <br />
                            <br />
                            <br/>
                            <VocationAccordion
                                value={this.state.form.vocations.value}
                                opened={this.state.current_panel === 'panel3'}
                                toggle={() => this.handleAccordion('panel3')}
                                changevalue={this.changeVocations}
                                changeAllValues={this.changeVocationsCategories}
                                removeAllSubjectsFromArea={this.cbRemoveAllSubjectsFromArea}
                                addAllSubjectsFromArea={this.cbAddAllSubjectsFromArea}
                            />



                            <br />
                            <br />
                            <br />
                        </>}
                    </div>
                    <Loading visible={this.state.loading} />
                    <Popup ref={this.popup}>

                        <InputLabel id="language">Current Variant</InputLabel>
                        <Select labelId="language" id="language" value={this.state.form.language.value} error={!this.state.form.language.valid && this.state.show_errors} onChange={(event) => this.change_value('language', event)}>
                            {Object.keys(languages).map((key) => {

                                if (this.state.languages.includes(key)) return null;

                                return <MenuItem key={key} value={key}>{languages[key].name}</MenuItem>

                            })}

                        </Select>

                        <TextField id="language_title" max={60} value={this.state.form.language_title.value} error={!this.state.form.language_title.valid && this.state.show_errors} label="Title (max. 60 Characters)" onChange={value => this.change_value('language_title', value)} />
                        <TextField id="language_body" max={60} value={this.state.form.language_body.value} error={!this.state.form.language_body.valid && this.state.show_errors} label="Description (max. 130 Characters)" onChange={value => this.change_value('language_body', value)} />

                        <div className="button" onClick={() => { this.add_variation() }}>Add variation</div>

                    </Popup>
                    <Popup ref={this.popup_update}>

                        <InputLabel id="language">Current Variant</InputLabel>
                        <Select labelId="language" id="language" value={this.state.form.language.value} error={!this.state.form.language.valid && this.state.show_errors} onChange={(event) => this.change_value('language', event)}>
                            {Object.keys(languages).map((key) => {

                                if (this.state.languages.includes(key) && key !== this.state.form.language.value) return null;

                                return <MenuItem key={key} value={key}>{languages[key].name}</MenuItem>

                            })}

                        </Select>

                        <TextField id="language_title" max={60} value={this.state.form.language_title.value} error={!this.state.form.language_title.valid && this.state.show_errors} label="Title (max. 60 Characters)" onChange={value => this.change_value('language_title', value)} />
                        <TextField id="language_body" max={60} value={this.state.form.language_body.value} error={!this.state.form.language_body.valid && this.state.show_errors} label="Description (max. 130 Characters)" onChange={value => this.change_value('language_body', value)} />

                        <div className="button" onClick={() => { this.update_variation() }}>Save variation</div>

                    </Popup>
                    <Popup ref={this.popup_schedule}>
                        <br />
                        <br/>
                        <Datetime dateFormat="DD.MM.YYYY" timeFormat={'HH:mm'} locale="de"
                                  value={this.state.form.schedule_datetime.value} onChange={(value) => {

                            if (Number.isInteger(Date.parse(new Date(value)))) {
                                this.change_value('schedule_datetime', {target: {value: new Date(value)}})
                            }

                        }}

                                  renderInput={(props, openCalendar, closeCalendar) => {
                                      const dateTime = moment(this.state.form.schedule_datetime.value).format('YYYY-MM-DD HH:mm:ss.0');
                                      return <TextField id="schedule_datetime"
                                                        onChange={(value) => {
                                                            props.onChange(value)
                                                        }}
                                                        error={!this.state.form.language_title.valid && this.state.show_errors}
                                                        value={formatDatetime(dateTime)}
                                                        label="Datetime to send" onClick={openCalendar}
                                      />
                                  }
                                  }

                        />
                        <br/>

                        <div className="button" onClick={() => { this.popup_schedule.current.close(); this.save(this.schedule) }}>Schedule</div>

                    </Popup>
                    <Popup ref={this.popup_send}>
                        <h3>Are you sure?</h3>
                        <strong>You are about to send the message. Are you sure?</strong><br /><br />
                        <div className="button" style={{ marginRight: 30 }} onClick={() => { this.popup_send.current.close() }}>No, I want to edit</div>
                        <div className="small_button" onClick={() => { this.popup_send.current.close(); this.save(this.deliver) }}>Yes, send Push-Notification</div>

                    </Popup>
                    <Notification ref={this.notification} />
                </>

            )
        }
    }
}
