import {get_user_token} from '../../../helper/functions/auth';
import Unit from '../../../units/Unit';


export default class InterestUnit extends Unit {

    argsBase = {
        unit: this.name,
        token: get_user_token(),
    }

    constructor(communicator) {
        super(communicator, 'interest');
    }

    /**
     * AddInterests to overview
     * @param {interrests: string} singleInterest
     * @param {(response) => {}} cb_success
     * @param {(error) => {}} cb_error
     */
    addSingleInterest = (singleInterest, cb_success, cb_error) => {
        const args = {
            ...this.argsBase,
            action: 'addInterests',
            data: {
                interests: [singleInterest]
            }
        }

        this.communicator.call(args, cb_success, cb_error)
    }

    /**
     * AddInterests Array to overview
     * @param {interrests: Array<string>} interestArray
     * @param {(response) => {}} cb_success
     * @param {(error) => {}} cb_error
     */
    addMultiInterest = (interestArray, cb_success, cb_error) => {
        const args = {
            ...this.argsBase,
            action: 'addInterests',
            data: {
                interests: interestArray
            }
        }

        this.communicator.call(args, cb_success, cb_error)
    }

    /**
     * GetInterestsAll
     * @param {(response) => {}} cb_success
     * @param {(error) => {}} cb_error
     * @return {}
     */
    getInterests = (cb_success, cb_error) => {
        const args = {
            ...this.argsBase,
            action: 'getInterests',
        }
        this.communicator.call(args, cb_success, cb_error)
    }

    /**
     * GetUserInterests
     * @param {(response) => {}} cb_success
     * @param {(error) => {}} cb_error
     */
    getUserInterest = (cb_success, cb_error) => {
        const args = {
            ...this.argsBase,
            action: 'getInterestOfUser'
        }
        this.communicator.call(args, cb_success, cb_error)
    }

    /**
     * RemoveInterests
     * @param {Array<number>} interestToRemoveIDArray
     * @param {(response) => {}} cb_success
     * @param {(error) => {}} cb_error
     * @return {}
     */
    removeInterests = (interestToRemoveIDArray, cb_success, cb_error) => {
        const args = {
            ...this.argsBase,
            action: 'removeInterests',
            data: {
                interests: interestToRemoveIDArray,
            }
        }

        this.communicator.call(args, cb_success, cb_error)
    }

    /**
     * RemoveSingleInterest
     * @param {int} interestToRemoveID
     * @param {(response) => {}} cb_success
     * @param {(error) => {}} cb_error
     * @return {}
     */
    removeSingleInterest = (interestToRemoveID, cb_success, cb_error) => {
        const args = {
            ...this.argsBase,
            action: 'removeInterests',
            data: {
                interests: [interestToRemoveID]
            }
        }

        this.communicator.call(args, cb_success, cb_error)
    }

    /**
     * AddInterestToUser - Adding one or more interests to the actual user, interests are in data-Array as number
     * @param {interests: Array<number>} data
     * @param {(response) => {}} cb_success
     * @param {(error) => {}} cb_error
     * @return {}
     */
    addInterestToUser = (data, cb_success, cb_error) => {
        const args = {
            ...this.argsBase,
            action: 'addInterestsToUser',
            data: data
        }

        this.communicator.call(args, cb_success, cb_error)
    }

    /**
     * RemoveInterestFromUsers - Remove one or more interests from the actual user, interests are in data-Array as number
     * @param {interests: Array<number>} data
     * @param {(response) => {}} cb_success
     * @param {(error) => {}} cb_error
     * @return {}
     */
    removeInterestFromUser = (data, cb_success, cb_error) => {
        const args = {
            ...this.argsBase,
            action: 'removeInterestsFromUser',
            data: data
        }

        this.communicator.call(args, cb_success, cb_error)
    }

}
