import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatDatetime, formatDatetimeForDb, formatDatetimeForDbSimple, formatTimeToUnix, wrlog } from '../../helper/misc';
import Loading from '../../helper/screens/Loading';
import AppUserUnit from '../../units/AppUserUnit';
import PushUnit from '../../units/PushUnit';
import { get_title } from '../../helper/functions/push';


export default class Overview extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            show_errors: false,
            push_notifications: [],
            app_users: {
                createdAll: null,
                createdThisYear: null,
                createdThisMonth: null,
                createdLastDay: null,
            },
            partial_loading: false
        }

        this.notification = new React.createRef();
        this.appUserUnit = new AppUserUnit(props.com);
    }

    componentDidMount() {

        this.props.set_current_page('/');

        let push_notifications = JSON.parse(sessionStorage.getItem('push_notification_list'));

        if (push_notifications === null) {

            this.setState({
                loading: true
            });

        } else {

            this.setState({
                push_notifications: push_notifications.data.pushNotifications,
            })

        }

        this.load_content();
        this.loadUsersYesterday();
        this.loadUsersThisMonth();
        this.loadUsersThisYear();

    }

    loadUsersYesterday = () => {
        const success = (res) => {
            const appusersState = { ...this.state.app_users };
            appusersState.createdLastDay = res.data.statistics.createdInTimeFrame
            appusersState.createdAll = res.data.statistics.createdAll
            this.setState({ app_users: appusersState })
            wrlog("appusersState", appusersState);
        }
        const error = (err) => {
        }

        let today = new Date();
        const yesterdayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0);
        const yesterdayEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 23, 59, 0);


        this.appUserUnit.loadStatisticsForTimeRange(
            formatDatetimeForDbSimple(yesterdayStart),
            formatDatetimeForDbSimple(yesterdayEnd),
            success,
            error
        )
    }

    loadUsersThisMonth = () => {
        const success = (res) => {
            const appusersState = { ...this.state.app_users };
            appusersState.createdThisMonth = res.data.statistics.createdInTimeFrame;
            this.setState({ app_users: appusersState })
            wrlog("appusersState", appusersState);
        }
        const error = (err) => {
        }

        let today = new Date();
        const thisMonthStart = new Date(today.getTime() - (1000 * 60 * 60 * 24 * new Date().getDate()));


        this.appUserUnit.loadStatisticsForTimeRange(
            formatDatetimeForDbSimple(thisMonthStart),
            formatDatetimeForDbSimple(today),
            success,
            error
        )

    }

    loadUsersThisYear = () => {
        const success = (res) => {
            const appusersState = { ...this.state.app_users };
            appusersState.createdThisYear = res.data.statistics.createdInTimeFrame
            this.setState({ app_users: appusersState })
            wrlog("appusersState", appusersState);
        }
        const error = (err) => {
        }

        const thisYearStart = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0);
        const today = new Date();


        this.appUserUnit.loadStatisticsForTimeRange(
            formatDatetimeForDbSimple(thisYearStart),
            formatDatetimeForDbSimple(today),
            success,
            error
        )
    }

    load_content = () => {

        let notifications = new PushUnit(this.props.com);

        this.setState({
            partial_loading: true
        });

        let cb_success_notifications = (res) => {
            this.setState({
                push_notifications: res.data.pushNotifications,
                loading: false,
                partial_loading: false
            });

        }

        let cb_error_notifications = (res) => {
            this.setState({
                loading: false,
                partial_loading: false
            });
        }

        // let cb_success_app_users = (res) => {
        //     this.setState({
        //         app_users: res.data.statistics,
        //         loading: false,
        //         partial_loading: false
        //     });
        // }

        let cb_error_app_users = (res) => {
            this.setState({
                loading: false,
                partial_loading: false
            });
        }

        notifications.list(cb_success_notifications, cb_error_notifications);
        // app_users.loadStatistics(cb_success_app_users, cb_error_app_users);

    }

    render() {

        let push_notifications = [];

        this.state.push_notifications.sort((a, b) => {

            if (a.time !== null && b.time !== null) {
                return formatTimeToUnix(b.time) - formatTimeToUnix(a.time);
            }

            return b.time - a.id;
        })

        for (let i = 0; i < 3; i++) {
            if (this.state.push_notifications[i] !== undefined) {
                push_notifications.push(this.state.push_notifications[i]);
            }
        }

        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12} style={{ paddingBottom: 50, marginTop: 44 }}>
                            <h1>Overview</h1>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <div className="card">

                                <Container fluid>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Statistics</strong>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Link to="/statistics" className="small_button">to all statistics</Link>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} style={{ padding: 0, marginTop: 20 }}>
                                            {
                                            this.state.app_users.createdAll !== null && 
                                            this.state.app_users.createdThisYear !== null && 
                                            this.state.app_users.createdThisMonth !== null && 
                                            this.state.app_users.createdLastDay !== null
                            
                                            ?
                                                <Table>
                                                    <TableHead>
                                                        <TableRow className="no_background">
                                                            <TableCell>
                                                                All App-Users
                                                            </TableCell>
                                                            <TableCell>
                                                                {this.state.app_users.createdAll || 0}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow className="no_background">
                                                            <TableCell>
                                                                New yesterday
                                                            </TableCell>
                                                            <TableCell>
                                                                {this.state.app_users.createdLastDay || 0}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className="no_background">
                                                            <TableCell>
                                                                New this month
                                                            </TableCell>
                                                            <TableCell>
                                                                {this.state.app_users.createdThisMonth || 0}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className="no_background">
                                                            <TableCell>
                                                                New this year
                                                            </TableCell>
                                                            <TableCell>
                                                                {this.state.app_users.createdThisYear || 0}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                                :
                                                <Loading relative container_style={{ height: 200 }} visible={true} />
                                            }
                                        </Col>
                                    </Row>
                                </Container>

                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="card">

                                <Container fluid>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Push-Notifications</strong>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Link to="/push" className="small_button">show all</Link>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} style={{ padding: 0, marginTop: 20 }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow className="no_background">
                                                        <TableCell>
                                                            Title
                                                        </TableCell>
                                                        <TableCell>
                                                            Date
                                                        </TableCell>
                                                        <TableCell>
                                                            Targets
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        push_notifications.map((push, key) => {

                                                            return <TableRow key={key} className="no_background">
                                                                <TableCell>
                                                                    {push.internalTitle !== '' && push.internalTitle !== null ? push.internalTitle : get_title(push)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {formatDatetime(push.time) + 'h'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {push.numberOfTargets !== null ? push.numberOfTargets + ' persons' : 'N/A'}
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Container>

                            </div>
                        </Col>
                    </Row>
                </Container>


            </>
        )
    }
}