import {get_user_token} from '../helper/functions/auth';
import Unit from './Unit';


export default class DynamicModuleAccessUnit extends Unit {

    constructor(communicator) {

        super(communicator, 'dynamicmoduleaccess');

        this.module_list = [
            {
                id: 'feed',
                label: 'Feed'
            },
            {
                id: 'chat',
                label: 'Vida-Chat'
            },
            {
                id: 'keytags',
                label: 'Schlüssel'
            },
            /*{
                id : 'timetrackingbasic',
                label : 'Zeitaufzeichnung (manuell)'
            },
            {
                id : 'timetrackinggeofencing',
                label : 'Zeitaufzeichnung (GeoFence)'
            },
            {
                id : 'timetrackinggeotracking',
                label : 'Zeitaufzeichnung (GPS-Tracking)'
            },
            {
                id : 'timetrackingcloud',
                label : 'Zeitaufzeichnung (Cloud-Speicherung)'
            },
            {
                id : 'timetrackingpdfexport',
                label : 'Zeitaufzeichnung (PDF-Export)'
            },*/
            {
                id : 'attraction',
                label : 'Jetzt Mitglied werben'
            }
        ];

    }

    set = (rules, cb_success, cb_error) => {

        let args = {
            data: {rules : [rules]},
            action: 'set',
            token : get_user_token(),
            unit: this.name
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    setMultiple = (rules, cb_success, cb_error) => {

        let args = {
            data: {rules : rules},
            action: 'set',
            token : get_user_token(),
            unit: this.name
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    get = (cb_success, eb_error) => {

        let args = {
            token : get_user_token(),
            action: 'getAll',
            unit: this.name
        }

        this.communicator.call(args, cb_success, eb_error, 'dynamic_access_modules_list');

    }

    get_modules = () => {
        return this.module_list;
    }

    get_title = (id) => {

        let output = false;

        this.module_list.forEach(value => {
            if(value.id === id) output = value.label;
        })

        return output;

    }

    delete = (id, cb_success, cb_error) => {

        let args = {
            token : get_user_token(),
            action: 'delete',
            unit: this.name,
            data: {
                id : id
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    deleteMultiple = (ids, cb_success, cb_error) => {

        let args = {
            token : get_user_token(),
            action: 'deleteMultiple',
            unit: this.name,
            data: {
                ids : ids
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }



}



