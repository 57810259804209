import React from "react";
import InterestUnit from "./units/InterestUnit";
import {wrlog} from "../../helper/misc";
import Loading from "../../helper/screens/Loading";
import {Col, Container, Row} from "react-bootstrap";
import {InputLabel, TextField} from "@material-ui/core";
import InterestTable from "./component/InterestTable";
import Popup from "../../helper/screens/Popup";


export default class Interests extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            interests: [],
            search_value: '',
            show_errors: false,
            loading: true,
            actualItem: null,
            interestUnit: new InterestUnit(props.com)
        }
        this.popup = React.createRef();
        this.props.set_current_page('/interests');
    }

    componentDidMount() {
        this.updateInterests();
    }

    itemPressed = (item) => {
        this.setState({
            actualItem: item,
        })
        this.popup.current.open();
    }

    change_interest = (value) => {
        this.setState({
            actualItem: {
                ...this.state.actualItem,
                name: value.target.value,
            }
        })

    };

    addInterest = () => {
        const newInterests = this.state.actualItem.name.split(';');

        newInterests.length > 1 ?
            this.cropStartAndEndAndAddInterests(newInterests) :
            this.state.interestUnit.addSingleInterest(this.state.actualItem.name, this.updateInterests, this.cbError);

        this.setState({
            actualItem: {id: undefined, name: ''},
        })
        this.popup.current.close();
    }

    cropStartAndEndAndAddInterests(interestArray) {
        this.state.interestUnit.addMultiInterest(interestArray.map((singleInterest) => {
            singleInterest.trimStart();
            singleInterest.trimEnd();
            return singleInterest;
        }), this.updateInterests, this.cbError)
    }

    updateInterests = () => {
        this.state.interestUnit.getInterests(this.cbAddInterests, (error) => wrlog('InterestLoadingError: ', error));
        this.setState({
            loading: true,
        })
    }

    deleteInterest = (deleteInterestID) => {
        this.state.interestUnit.removeSingleInterest(deleteInterestID.id, this.updateInterests, this.cbError)
    }

    deleteMultipleInterests = (deleteInterestIdArray) => {
        this.state.interestUnit.removeInterests(deleteInterestIdArray, this.updateInterests, this.cbError)
    }

    cbError = (error) => {
        wrlog('Error: ', error);
        this.setState({
            loading: false,
        })
    }

    cbAddInterests = (response) => {
        this.setState({
            interests: response.data.interests,
            loading: false,
        })
    }

    change_search_value = (value) => {
        this.setState({
            search_value: value.target.value
        })
    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row style={{paddingBottom: 50, marginTop: 44}}>
                        <Col md={8}>
                            <h1>Interests</h1>
                        </Col>
                        <Col md={4} className="align_right_desktop">
                            <div className="add_button" onClick={() => this.popup.current.open()}>New Interest</div>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: 50}}>
                        <Col md={8} style={{paddingTop: 16}}>
                        </Col>
                        <Col md={4} style={{textAlign: 'right'}}>
                            <TextField id="title" value={this.state.search_value}
                                       error={!this.state.search_value.valid && this.state.show_errors} label="Search"
                                       onChange={value => this.change_search_value(value)}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <InterestTable
                                interests={this.state.interests}
                                deleteItem={this.deleteInterest.bind(this)}
                                deleteMultiple={this.deleteMultipleInterests.bind(this)}
                                searchValue={this.state.search_value}
                            />
                        </Col>
                    </Row>
                </Container>
                <Popup ref={this.popup}>
                    <h3>Interest</h3>

                    <TextField value={this.state.actualItem && this.state.actualItem.name}
                               onChange={(value) => this.change_interest(value)}/>
                    <InputLabel id="regions">Interest</InputLabel>
                    <br/>
                    <br/>
                    <div className="button" onClick={() => {
                        this.addInterest()
                    }}>Add Interest
                    </div>
                </Popup>
                <Loading visible={this.state.loading}/>
            </>
        );
    }
}
