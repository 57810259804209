import {get_user_token} from '../helper/functions/auth';
import {wrlog} from '../helper/misc';
import Unit from './Unit';


export default class UserUnit extends Unit {

    constructor(communicator) {

        super(communicator, 'user');
    }

    add = (user_data, cb_success, cb_error) => {
        const args = {
            data: {
                user: {
                    ...user_data,
                    username: user_data.email.trim().toLowerCase()
                }

            },
            action: 'create',
            unit: this.name,
            token: get_user_token(),
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    delete = (cb) => {

        let args = {
            token : get_user_token(),
            action: 'logout',
            unit: this.name
        }

        this.communicator.call(args, cb, cb);

    }

    update = (user_data, cb_success, cb_error) => {
        let args = {
            token : get_user_token(),
            action: 'update',
            unit: this.name,
            data: {
                users : [
                    user_data
                ]
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    get = (cb_success, cb_error) => {
        let args = {
            token : get_user_token(),
            action: 'readList',
            data: {
                searchAfter: 0,
                size: 200
            },
            unit: this.name
        }

        this.communicator.call(args, cb_success, cb_error, 'admin_users_list');

    }

    get_permission_by_token = (cb_success, cb_error) => {

        let args = {
            token : get_user_token(),
            action: 'permissions',
            unit: this.name
        }

        this.communicator.call(args, cb_success, cb_error, 'permissions');

    }

    get_permissions = () => {
        let args = {
            token : get_user_token(),
            action: 'permissions',
            unit: this.name
        }

        this.communicator.call(args, (res) => {
            wrlog(res)
        }, () => {

        }, 'admin_users_permissions');
    }

}



