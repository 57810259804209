import {Table, TableBody, TableCell, TableHead, TableRow, TextField} from '@material-ui/core';
import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {formatTimeFromDb, formatTimeToUnix, wrlog} from '../../helper/misc';
import Loading from '../../helper/screens/Loading';
import Switch from '../../helper/screens/Switch';
import FeedUnit from '../../units/FeedUnit';
import UserUnit from "../../units/UserUnit";


export default class Cms extends React.Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            show_errors: false,
            entries: [],
            partial_loading: false,
            switch: 'all',
            search_value: '',
            userList: [],
        }

        this.entry = new React.createRef();
    }

    componentDidMount() {

        if (this.props.location !== undefined &&
            this.props.location.state !== undefined &&
            this.props.location.state.filter !== undefined &&
            (this.props.location.state.filter === 'all' || this.props.location.state.filter === 'visible' || this.props.location.state.filter === 'hidden')
        ) {
            this.setState({
                switch: this.props.location.state.filter
            })
        }

        this.props.set_current_page(this.props.location.pathname);

        let feed_entries = JSON.parse(sessionStorage.getItem('feed_entries'));

        wrlog(feed_entries);

        if (feed_entries === null) {

            this.setState({
                loading: true
            });

        } else {

            this.setState({
                entries: feed_entries,
                partial_loading: true
            })

        }

        this.load_users();

    }

    getUserList = (dataIn) => {
        const userList = dataIn.data.users.data.map((singleUserElement) => {
            return {
                firstname: singleUserElement.firstname.value,
                lastname: singleUserElement.lastname.value,
                ldap: singleUserElement.ldap,
                userId: singleUserElement.id.value
            }
        });

        this.setState({
            userList: userList
        }, this.load_feed)
    }

    change_search_value = (value) => {
        this.setState({
            search_value: value.target.value
        })
    }


    load_feed = () => {
        let feed = new FeedUnit(this.props.com);

        let cb_success = (res) => {

            let entries = res.data.feed;

            this.setState({
                entries: entries,
                loading: false,
                partial_loading: false
            });

            feed.set_cache(res.data.feed);

        }

        let cb_error = (res) => {

            this.setState({
                loading: false,
                partial_loading: false
            });

        }

        feed.list(cb_success, cb_error);

    }

    load_users = () => {
        const user = new UserUnit(this.props.com);

        user.get(this.getUserList, (error) => console.log('error: ', error));
    }

    switch_changed = (value) => {

        this.setState({
            switch: value
        })

    }


    render_list = () => {

        if (this.state.entries.length < 1) return null;

        let filtered;


        filtered = this.state.entries.filter(entry => {

            let search_valid = true;

            if (this.state.search_value !== '' /*&& entry.data !== undefined*/) {
                search_valid =
                    entry.title.toLowerCase().includes(this.state.search_value.toLowerCase())
            }

            return (this.state.switch === 'all' || (this.state.switch === 'visible' && entry.visible === true) || (this.state.switch === 'hidden' && entry.visible === false)) && search_valid;

        });

        filtered = filtered.sort((a, b) => {

            if (a.displayTime !== null && b.displayTime !== null) {
                return formatTimeToUnix(b.displayTime) - formatTimeToUnix(a.displayTime)
            }

            return b.id - a.id;

        })

        return filtered.map((entry, key) => {

            const userInfo = this.state.userList.filter((single) => {
                    return single.userId === entry.creatorId
                }
            )[0];

            return <TableRow key={key}>
                <TableCell>{entry.title}</TableCell>
                <TableCell style={{
                    borderLeftWidth: '1px',
                    borderLeftColor: 'lightgrey',
                    borderLeftStyle: 'solid'
                }}>{userInfo && userInfo.firstname} {userInfo && userInfo.lastname}</TableCell>
                {/*
                <TableCell>{formatTimeFromDb(entry.displayTime).date}</TableCell>
                <TableCell style={{
                    borderLeftWidth: '1px',
                    borderLeftColor: 'lightgrey',
                    borderLeftStyle: 'solid'
                }}>{userInfo && userInfo.firstname + ' ' + userInfo.lastname}</TableCell>
                */}
                <TableCell>{formatTimeFromDb(entry.displayTime).date} {formatTimeFromDb(entry.displayTime).time}</TableCell>
                <TableCell style={{
                    borderLeftWidth: '1px',
                    borderLeftColor: 'lightgrey',
                    borderLeftStyle: 'solid'
                }}>{entry.lastEditName}</TableCell>
                <TableCell>{formatTimeFromDb(entry.lastEditTime).date} {formatTimeFromDb(entry.lastEditTime).time}</TableCell>
                <TableCell className="align-right"
                           style={{borderLeftWidth: '1px', borderLeftColor: 'lightgrey', borderLeftStyle: 'solid'}}>
                    <Link to={{
                        pathname: "/cms/" + entry.feedEntryId,
                        state: {filter: this.state.switch}
                    }}>edit</Link>
                </TableCell>
            </TableRow>
        })

    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={8} style={{ paddingBottom: 50, marginTop: 44 }}>
                            <h1>Content management system</h1>
                        </Col>
                        <Col md={4} style={{ paddingBottom: 50 }} className="align_right_desktop">
                            <Link to={"/cms/new"} className="add_button">New article</Link>
                        </Col>

                    </Row>
                    <Row style={{ paddingBottom: 50 }}>
                        <Col md={8} style={{ paddintTop: 16 }}>
                            <Switch onChange={() => this.switch_changed('all')} selected={this.state.switch === 'all'} title="All" />
                            <Switch onChange={() => this.switch_changed('visible')} selected={this.state.switch === 'visible'} title="Published" />
                            <Switch onChange={() => this.switch_changed('hidden')} selected={this.state.switch === 'hidden'} title="Draft" />
                        </Col>
                        <Col md={4} style={{ textAlign: 'right' }}>
                            <TextField id="title" value={this.state.search_value} error={!this.state.search_value.valid && this.state.show_errors} label="Search" onChange={value => this.change_search_value(value)} />
                        </Col>

                    </Row>
                    <Row>
                        <Col md={12}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell colSpan={2} align={'center'}>Released</TableCell>
                                        {/*<TableCell colSpan={2} align={'center'}>last Change</TableCell> todo: implementing if be is ready*/}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Title</TableCell>
                                        <TableCell style={{
                                            borderLeftWidth: '1px',
                                            borderLeftColor: 'lightgrey',
                                            borderLeftStyle: 'solid'
                                        }}>Original author</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell style={{
                                            borderLeftWidth: '1px',
                                            borderLeftColor: 'lightgrey',
                                            borderLeftStyle: 'solid'
                                        }}>Last author</TableCell>
                                        <TableCell>Date of last change</TableCell>
                                        {/*<TableCell style={{
                                            borderLeftWidth: '1px',
                                            borderLeftColor: 'lightgrey',
                                            borderLeftStyle: 'solid'
                                        }}>Autor</TableCell>
                                        <TableCell>Date</TableCell>

                                        <TableCell style={{textAlign: 'right'}} style={{
                                            borderLeftWidth: '1px',
                                            borderLeftColor: 'lightgrey',
                                            borderLeftStyle: 'solid'
                                        }}>*/}
                                        {this.state.partial_loading ?
                                            <div><Loading visible={true} relative style={{width: 20, height: 20}}
                                                          container_style={{
                                                              width: 20,
                                                              height: 20,
                                                              display: 'inline-block',
                                                              verticalAlign: 'middle',
                                                              marginRight: 10
                                                          }}/> Synchronizing with server
                                            </div> : null}{/*}</TableCell>*/}
                                    </TableRow>
                                </TableHead>

                                <TableBody>

                                    {
                                        this.render_list()
                                    }

                                </TableBody>
                            </Table>
                        </Col>
                    </Row>

                </Container>
                <Loading visible={this.state.loading} />
            </>

        )
    }
}
