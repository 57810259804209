import { get_user_token } from '../helper/functions/auth';
import { formatDatetimeForDbSimple } from '../helper/misc';
import Unit from './Unit';

export default class AppUserUnit extends Unit {

    constructor(communicator) {

        super(communicator, 'appuser');

    }

    loadStatistics = (cb_success, cb_error) => {
        let args = {
            token: get_user_token(),
            action: 'loadStatistics',
            unit: this.name,
            data: {}
        }

        this.communicator.call(args, cb_success, cb_error);
    }

    loadFilteredStatistics = (from, to, vocation, region, cb_success, cb_error) => {

        let data = {};

        if(from !== ''){
            data.from = from;
        }
        if(to !== ''){
            data.to = to;
        }
        if(vocation !== ''){
            data.vocation = vocation;
        }
        if(region !== ''){
            data.region = region;
        }


        let args = {
            token: get_user_token(),
            action: 'loadMixedTimeTrackingStatistics',
            unit: this.name,
            data: data
        }

        this.communicator.call(args, cb_success, cb_error);
    }

    loadStatisticsForTimeRange = (from, to, cb_success, cb_error) => {

        let data = {};

        if(from !== null){
            data.from = formatDatetimeForDbSimple(from);
        }
        if(to !== null){
            data.to = formatDatetimeForDbSimple(to);
        }
        // if(vocation !== ''){
        //     data.vocation = vocation;
        // }
        // if(region !== ''){
        //     data.region = region;
        // }

        const newCb = (res) => {
            cb_success(res, from, to);
        }


        let args = {
            token: get_user_token(),
            action: 'loadStatisticsForTimeRange',
            unit: this.name,
            data: data
        }

        this.communicator.call(args, newCb, cb_error);
    }

}



