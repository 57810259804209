import React from 'react';


export default class NotFound extends React.Component {

    componentDidMount() {
        // will be true
        //let auth = new AuthenticationUnit(this.props.com);

        //auth.login('stadler@spectory.at', 'password');

    }


    render() {
        return (
            <h1>404 - Not Found</h1>
        )
    }
}