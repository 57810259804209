import { get_user_token } from '../helper/functions/auth';
import Unit from './Unit';

export default class RegisterUnit extends Unit {

    constructor(communicator) {

        super(communicator, 'register');

    }

    add_recruitment_access = (data, cb_success, cb_error) => {
        let args = {
            token: get_user_token(),
            action: 'addRecruitmentTime',
            unit: this.name,
            data: data
        }

        this.communicator.call(args, cb_success, cb_error);
    }

    get_recruitment_times = (cb_success, cb_error) => {
        
        let args = {
            token: get_user_token(),
            action: 'getRecruitmentTimes',
            unit: this.name,
            data: {}
        }

        this.communicator.call(args, cb_success, cb_error, 'recruitment_access_list');

    }

    delete_recruitment_time = (id, cb_success, cb_error) => {

        let args = {
            token: get_user_token(),
            action: 'deleteRecruitmentTime',
            unit: this.name,
            data: {
                id: id
            }
        }

        this.communicator.call(args, cb_success, cb_error);

    }

}



