export const DEBUG = false;

export const SHOW_LOGS = false;

// DEBUG CONFIG
export const LOCAL_HOST_IP = 'localhost';
export const LOCAL_PORT = '8443';
export const LOCAL_PORT_FILES = '8080';

// LIFE CONFIG
export const SERVER_HOST_URL = 'www.vidalife.at'; //'milkyway.huemer-connect.com'; //www.vidalife.at''; //'';
export const SERVER_PORT = '443';
export const SERVER_PORT_FILES = '443';

//CONFIG
export const SERVER_HOST = DEBUG ? LOCAL_HOST_IP : SERVER_HOST_URL; // Locally: 192.168.1.17 //'212.241.73.23'; // dev.spacetechnology.net
export const DOWNLOAD_URL = 'https://' + SERVER_HOST + ':' + (DEBUG ? LOCAL_PORT_FILES : SERVER_PORT);
export const SERVER_WEBSOCKET_URL = 'wss://' + SERVER_HOST + ':' + (DEBUG ? LOCAL_PORT : SERVER_PORT) + '/api/websocket';
export const UPLOAD_FEED_FILE_URL = 'https://' + SERVER_HOST + ':' + (DEBUG ? LOCAL_PORT_FILES : SERVER_PORT) + '/upload/feedentryfile';

// Basename of the application, neccessary if app is hosted in subfolder
export const BASENAME = '/dashboard';
