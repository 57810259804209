import React from "react";
import {Link, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import CustomCheckbox from "../../../helper/screens/CustomCheckbox";

export default class InterestTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedNamesToDelete: [],
        }
    }

    prepareToDeleteMultipleElements(itemIdIn) {

        const newItemToDeleteArray =
            [...this.state.selectedNamesToDelete].includes(itemIdIn) ?
                [...this.state.selectedNamesToDelete].filter((singleIDs) => singleIDs !== itemIdIn) :
                [...this.state.selectedNamesToDelete, itemIdIn];

        this.setState({
            selectedNamesToDelete: newItemToDeleteArray
        })
    }

    onPressMultipleInterestsDelete = () => {
        this.props.deleteMultiple(this.state.selectedNamesToDelete);

        this.setState({
            selectedNamesToDelete: []
        })
    }

    renderTableElement(item) {
        return (
            <TableRow key={item.id}>
                <TableCell style={{textAlign: 'center', width: 25}}>
                    <CustomCheckbox
                        onChange={() => {
                            this.prepareToDeleteMultipleElements(item.id)
                        }}
                    />
                </TableCell>
                <TableCell>
                    {item.name}
                </TableCell>
                <TableCell className="align-right">
                    {this.state.selectedNamesToDelete.length === 0 &&
                        <Link color={'secondary'} onClick={() => this.props.deleteItem(item)}>delete</Link>
                    }
                </TableCell>
            </TableRow>
        );
    }

    render() {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell>Title</TableCell>
                        <TableCell>
                            {this.state.selectedNamesToDelete.length > 0 &&
                                <Link
                                    color={'secondary'}
                                    onClick={this.onPressMultipleInterestsDelete}
                                >
                                    delete chosen interests
                                </Link>
                            }
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.props.interests.filter((singleInterest) =>
                            singleInterest.name.toLowerCase().includes(this.props.searchValue.toLowerCase())
                        ).map((singleInterest) => this.renderTableElement(singleInterest))
                    }
                </TableBody>
            </Table>
        );
    }
}
