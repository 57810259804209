import {
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField
} from '@material-ui/core';
import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import UserUnit from '../../../units/UserUnit';
import Loading from '../../../helper/screens/Loading';
import {validation} from '../../../helper/validation';
import GeneralSettingUnit from '../../../units/GeneralSettingUnit';
import Notification from '../../../helper/screens/Notification';
import Popup from '../../../helper/screens/Popup';

import regions from '../../../helper/data/regions.json';
import CustomCheckbox from '../../../helper/screens/CustomCheckbox';
import RoleUnit from '../../../units/RoleUnit';
import {getWorkingSubjectsAndAreas, wrlog} from "../../../helper/misc";


export default class AdminUsersEdit extends React.Component {

    constructor() {

        super();

        this.state = {
            loading: false,
            show_errors: false,
            is_404: false,
            user_id: null,
            destinations: [],
            loading_responsibility: false,
            roles: [],
            redirecting: false,
            form: {
                firstname: {
                    valid: false,
                    value: '',
                    rules: {
                        isRequired: true
                    }
                },
                lastname: {
                    valid: false,
                    value: '',
                    rules: {
                        isRequired: true
                    }
                },
                email: {
                    valid: false,
                    value: '',
                    rules: {
                        isRequired: true,
                        isEmail: true
                    }
                },
                password: {
                    valid: false,
                    value: '',
                    rules: {
                        isRequired: true,
                        isPassword: true
                    }
                },
                region: {
                    valid: true,
                    value: 'AT-1',
                    rules: {
                        isRequired: true
                    }
                },
                vocation: {
                    valid: false,
                    value: '',
                    rules: {
                        isRequired: true
                    }
                },
                OegbUserRole: {
                    label: 'Admin-Users',
                    valid: true,
                    value: false,
                    rules: {}
                },
                OegbFeedRole: {
                    label: 'CMS',
                    valid: true,
                    value: false,
                    rules: {}
                },
                OegbDynamicAccessRole: {
                    label: 'Modules',
                    valid: true,
                    value: false,
                    rules: {}
                },
                OegbContentRole: {
                    label: 'Static Content',
                    valid: true,
                    value: false,
                    rules: {}
                },
                OegbGeneralSettingsRole: {
                    label: 'Access Global Settings',
                    valid: true,
                    value: false,
                    rules: {}
                },
                OegbPushRole: {
                    label: 'Access Push Notifications',
                    valid: true,
                    value: false,
                    rules: {}
                },
            },
            permissions_grouped: [
                [
                    'OegbUserRole',
                ],
                [
                    'OegbFeedRole',
                ],
                [
                    'OegbDynamicAccessRole',
                ],
                [
                    'OegbContentRole',
                ],
                [
                    'OegbGeneralSettingsRole',
                ],
                [
                    'OegbPushRole',
                ]
            ],
            permissions: [],
            workSubjectsAndAreas: null

        }

        let permissions_gerneral = [];

        this.state.permissions_grouped.forEach(group => {
            group.forEach(permission => {
                permissions_gerneral.push(permission)
            })
        })

        this.state.permissions = permissions_gerneral;

        this.success_notification = React.createRef();
        this.error_notification = React.createRef();
        this.popup = React.createRef();

    }

    componentDidMount() {

        this.get_permissions();

        this.props.set_current_page('/admin-users');

        this._init();

        getWorkingSubjectsAndAreas().then(this.cbSuccessLoadWorkSubjectAndAreas.bind(this)).catch((error) => {
            wrlog('LoadingErrorIn_Add_Edit: ', error)
        })

    }

    cbSuccessLoadWorkSubjectAndAreas = (result) => {
        this.setState({
            workSubjectsAndAreas: JSON.parse(result.toString())
        })
    }

    componentDidUpdate() {

        if (!this.state.redirecting) return;

        this.setState({
            redirecting: false
        })

        this._init();

    }

    _init = () => {

        let user_id = this.props.match.params.id;

        if (Number.isInteger(parseInt(user_id))) {

            let user_data = JSON.parse(sessionStorage.getItem('admin_users_list')).data.users.data;

            let current_user_data = null;

            user_data.forEach(user => {
                if (parseInt(user.id.value) === parseInt(user_id)) current_user_data = user;
            })

            if (current_user_data == null) {
                this.setState({
                    is_404: true
                })
                return;
            }

            let formCopy = this.state.form;

            formCopy.firstname.value = current_user_data.firstname.value;
            formCopy.lastname.value = current_user_data.lastname.value;
            formCopy.email.value = current_user_data.email.value;

            formCopy.firstname.valid = validation(formCopy.firstname.value, formCopy.firstname.rules);
            formCopy.lastname.valid = validation(formCopy.lastname.value, formCopy.lastname.rules);
            formCopy.email.valid = validation(formCopy.email.value, formCopy.email.rules);

            formCopy.password.rules = {}
            formCopy.password.valid = true


            current_user_data.roles.forEach(role => {

                if (formCopy[role.name] !== undefined) {

                    formCopy[role.name].value = true;

                }

            })

            this.setState({
                form: formCopy,
                user_id: parseInt(user_id)
            });

        } else if (user_id === 'new') {

            this.setState({
                user_id: user_id
            });

        } else {

            this.setState({
                is_404: true
            });

        }

        this.setState({
            loading_responsibility: true
        })

        this.get_destinations(() => {
            this.setState({
                loading_responsibility: false
            })
        });

    }

    get_permissions = () => {

        const that = this;

        let role_unit = new RoleUnit(this.props.com);

        let roles = sessionStorage.getItem('admin_users_roles');

        let role_titles = [
            'OegbUserRole',
            'OegbPushRole',
            'OegbContentRole',
            'OegbGeneralSettingsRole',
            'OegbFeedRole',
            'OegbDynamicAccessRole',
        ];



        if (roles !== null) {

            roles = JSON.parse(roles);

            that.setState({
                roles: roles
            })

        }

        const cb_success = (res) => {

            let dashboard_roles = [];

            res.data.roles.data.forEach(role => {

                if (role_titles.includes(role.name)) {

                    dashboard_roles.push({

                        title: role.name,
                        id: role.id

                    });

                }

            })

            that.setState({
                roles: dashboard_roles
            })


            sessionStorage.setItem('admin_users_roles', JSON.stringify(dashboard_roles));

        }

        role_unit.list(cb_success, (err) => {
        });

    }

    get_destinations = (cb = null) => {

        let generalSettingsUnit = new GeneralSettingUnit(this.props.com);

        if (sessionStorage.getItem('admin_users_destinations') !== null) {

            this.setState({
                destinations: JSON.parse(sessionStorage.getItem('admin_users_destinations')).data.chatDestination
            })

        }

        let get_destination__cb_success = (res) => {

            this.setState({
                destinations: res.data.chatDestination
            })

            if (cb !== null) {
                cb();
            }

        }

        let get_destination__eb_error = () => {

            if (cb !== null) {
                cb();
            }
        }

        generalSettingsUnit.get_destinations(get_destination__cb_success, get_destination__eb_error);

    }



    /* Add Destination*/

    add_destination = () => {

        let formCopy = this.state.form;
        let isValid = true;

        if (formCopy.region.valid === false && formCopy.vocation.valid === false) {
            isValid = false;
        }

        if (!isValid) {
            this.setState({
                show_errors: true
            })

            return false;
        };

        /* Build data-object for user-update call */


        this.setState({
            loading: true
        })

        let generalSettingsUnit = new GeneralSettingUnit(this.props.com);

        let cb_success = (res) => {

            this.setState({
                loading_responsibility: true
            })

            this.get_destinations(() => {
                this.setState({
                    loading_responsibility: false
                })
            });

            this.success_notification.current.open('success', 'Added responsibility successful')
            this.popup.current.close();
            this.setState({
                loading: false
            });

        }

        let cb_error = (res) => {

            this.popup.current.close();

            this.setState({
                loading: false,
                show_errors: true
            })
            this.error_notification.current.open()

        }
        let data = {
            userId: parseInt(this.state.user_id)
        }

        if (formCopy.region.value !== '') {
            data.region = formCopy.region.value;
        }
        if (formCopy.vocation.value !== '') {
            data.subjectArea = formCopy.vocation.value;
        }

        generalSettingsUnit.set_destination(data, cb_success, cb_error);

    }


    change_value = (key, value) => {


        let formCopy = this.state.form;

        if(typeof value === 'object' && value.target !== undefined){
            value = value.target.value;
        }

        if (key === 'email') {
            value = value.toLowerCase();
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({ form: formCopy })

    }

    delete_destination = (key) => {
        let destination = this.state.destinations[key];

        let isValid = true;

        if (destination === undefined) {
            isValid = false;
        }

        if (destination.user.id !== this.state.user_id) {
            isValid = false;
        }

        if (!isValid) {

            this.error_notification.current.open();

            return false;
        }

        this.setState({
            loading: true
        });

        let generalSettingsUnit = new GeneralSettingUnit(this.props.com);

        let destinationCopy = [...this.state.destinations];

        destinationCopy.splice(key, 1);

        let cb_success = (res) => {

            this.success_notification.current.open('success', 'Deleted responsibility')

            this.setState({
                loading_responsibility: true
            })

            this.get_destinations(() => {
                this.setState({
                    loading_responsibility: false
                })
            });

            this.setState({
                destinations: destinationCopy,
                loading: false
            });

        }

        let cb_error = (res) => {

            this.popup.current.close();

            this.setState({
                loading: false,
                show_errors: true
            })
            this.error_notification.current.open()

        }

        generalSettingsUnit.delete_destination(parseInt(destination.id), cb_success, cb_error);


    }

    save = () => {

        let formCopy = this.state.form;
        let isValid = true;
        let user_update_data = {}
        const form_elements_user = [
            'firstname', 'lastname', 'email', 'password',
        ];

        form_elements_user.forEach(key => {

            if (formCopy[key].valid === false) {

                isValid = false;

            } else if (formCopy[key].value !== '') {

                if (key === 'password' && this.state.user_id !== 'new') {
                    user_update_data['newpassword'] = formCopy[key].value;
                } else {
                    user_update_data[key] = formCopy[key].value;
                }

            }

        });

        let user_roles = [];

        this.state.roles.forEach(role => {

            if (formCopy[role.title].value === true) {
                user_roles.push(role.id);
            }

        })

        if (user_roles.length > 0) {
            user_update_data.roleIds = user_roles;
        }

        if (!isValid) {

            this.setState({
                show_errors: true
            })

            return false;

        };

        this.setState({
            loading: true
        })

        let userUnit = new UserUnit(this.props.com);

        let cb_success_update = (res) => {

            userUnit.get((res_get) => {

                this.success_notification.current.open('success', 'Updated Admin User successfully')

                this.setState({
                    loading: false
                });

            }, () => { });

        }

        let cb_success_create = (res_create) => {

            userUnit.get((res) => {

                this.success_notification.current.open();

                this.props.history.push('/admin-users/'+res_create.data.id);

                this.setState({
                    loading: false,
                    redirecting: true,
                    user_id: res_create.data.id
                });


            }, () => { });

        }
        let cb_error = (err) => {

            this.setState({
                loading: false,
                show_errors: true
            });

            this.error_notification.current.open();

        }

        if (this.state.user_id !== 'new') {
            user_update_data.id = parseInt(this.state.user_id);
            userUnit.update(user_update_data, cb_success_update, cb_error);
        } else {
            userUnit.add(user_update_data, cb_success_create, cb_error);
        }

    }
    /**
     * getting the object refered to the mvzvCode
     * @param {int} mvzvCode
     * @return {name: string, mvzvCode: int}
     */
    getWorkSubjectWithMvzvCode = (mvzvCode) => {
        const help = {name: '', mvzvCode: 0}

        this.state.workSubjectsAndAreas && this.state.workSubjectsAndAreas.forEach((singleWorkSubjectArea) => {
            if (singleWorkSubjectArea.mvzvCode === mvzvCode) {
                help.name = singleWorkSubjectArea.name;
                help.mvzvCode = singleWorkSubjectArea.mvzvCode;
            }
            singleWorkSubjectArea.children && singleWorkSubjectArea.children.forEach((singleWorkSubject) => {
                if (singleWorkSubject.mvzvCode === mvzvCode) {
                    help.name = singleWorkSubject.name;
                    help.mvzvCode = singleWorkSubject.mvzvCode;
                }
            })
        })
        return help;

    }

    renderWorkSubject = () => {
        const renderArray = [];

        this.state.workSubjectsAndAreas && this.state.workSubjectsAndAreas.forEach((singleWorkArea) => {
            renderArray.push(
                <MenuItem
                    value={singleWorkArea.mvzvCode}
                >
                    <b>{singleWorkArea.name + ' (' + singleWorkArea.mvzvCode + ')'}</b>
                </MenuItem>
            );
            singleWorkArea.children && singleWorkArea.children.forEach((singleWorkSubject) => {
                renderArray.push(
                    <MenuItem
                        value={singleWorkSubject.mvzvCode}
                    >
                        {' - ' + singleWorkSubject.name + ' (' + singleWorkSubject.mvzvCode + ')'}
                    </MenuItem>
                )
            })
        })
        return renderArray;
    }

    render() {
        if (this.state.is_404) {
            return (
                <><Link className="go_back_button" to="/admin-users">Go back</Link><br/><br/>
                    <h3>The user was not found</h3></>
            );
        }

        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Link className="go_back_button" to="/admin-users">Go back</Link><br /><br />
                            <h1>Admin Users</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <TextField id="firstname" value={this.state.form.firstname.value} error={!this.state.form.firstname.valid && this.state.show_errors} label="Firstname" onChange={value => this.change_value('firstname', value)} /><br />
                            <TextField id="lastname" value={this.state.form.lastname.value} error={!this.state.form.lastname.valid && this.state.show_errors} label="Lastname" onChange={value => this.change_value('lastname', value)} /><br />
                            <TextField id="email" value={this.state.form.email.value} error={!this.state.form.email.valid && this.state.show_errors} label="Email" type="email" onChange={value => this.change_value('email', value)} /><br />
                            <TextField id="password" value={this.state.form.password.value} error={!this.state.form.password.valid && this.state.show_errors} label="Password" type="password" onChange={value => this.change_value('password', value)} /><br />
                            <p>Min. 10 character. Use at lease 1 Uppercase letter, 1 lowercase letter, 1 number and 1 special character</p>

                            <br />
                            <br />
                            <br />

                            {
                                this.state.user_id !== 'new' ?
                                    <>
                                        <b style={{marginTop: 50}}>Vida Chat responsibility</b><br/><br/>
                                        <div className="add_button" onClick={() => {

                                            let formCopy = this.state.form;

                                            formCopy.region.valid = false;
                                            formCopy.region.value = "";

                                            formCopy.vocation.valid = false;
                                            formCopy.vocation.value = "";

                                            this.popup.current.open()
                                        }}>Add responsibility
                                        </div>
                                        {this.state.loading_responsibility ? <div style={{ float: "right" }}><Loading visible={true} relative style={{ width: 20, height: 20 }} container_style={{ width: 20, height: 20, display: 'inline-block', verticalAlign: 'middle', marginRight: 10 }} /> loading responsibilities</div> : null}
                                        <br /><br />
                                        <Table><TableBody>
                                            {this.state.destinations !== undefined && this.state.destinations.length > 0 ? this.state.destinations.map((destination, key) => {

                                                if (destination.user.id !== this.state.user_id) return null;
                                                if (destination.vocation === null && destination.region === null) return null;

                                                return <TableRow>
                                                    <TableCell>
                                                        {
                                                            destination.region !== null && destination.subjectArea !== null ?
                                                                this.getWorkSubjectWithMvzvCode(destination.subjectArea).name + " at " + regions[destination.region] : 'Fallback for ' + (destination.region !== null ? regions[destination.region] :
                                                                this.getWorkSubjectWithMvzvCode(destination.subjectArea).name)
                                                        }
                                                    </TableCell>
                                                    <TableCell className="align-right">{destination.user !== undefined ?
                                                        <div className="small_button"
                                                             onClick={() => this.delete_destination(key)}>remove</div> : null}</TableCell>
                                                </TableRow>

                                            }) : null}
                                        </TableBody></Table>
                                    </>
                                    : null
                            }
                        </Col>
                        <Col md="4">
                            <div className="button save" onClick={this.save}>{this.state.user_id === 'new' ? 'Add User' : 'Save Changes'}</div>
                            <br /><br />
                            {
                                this.state.user_id !== 'new' ?
                                    <>
                                        <b>Permissions</b><br /><br />
                                        {
                                            this.state.permissions_grouped.map((group, key) => {

                                                return <>{group.map((permission, key_permission) => {

                                                    let formElement = this.state.form[permission];

                                                    return <><FormControlLabel key={key_permission + '_' + key}
                                                        value={"true"}
                                                        control={<CustomCheckbox checked={formElement.value} />}
                                                        label={formElement.label}
                                                        labelPlacement="end"
                                                        onChange={(event) => this.change_value(permission, { target: { value: !this.state.form[permission].value } })}
                                                        style={{ marginBottom: 10 }}
                                                    /></>

                                                })}<br /><br /></>

                                            })
                                        }
                                    </>
                                    : null}

                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                        </Col>
                    </Row>
                </Container>
                <Popup ref={this.popup}>
                    <h3>Add responsibility</h3>
                    <InputLabel id="regions">Region</InputLabel>
                    <Select labelId="regions" id="regions" value={this.state.form.region.value} onChange={(event) => this.change_value('region', event)}>
                        {Object.keys(regions).map((key) => {
                            return <MenuItem value={key}>{regions[key]}</MenuItem>
                        })}

                    </Select>

                    <InputLabel id="vocations">Vocations / Branches</InputLabel>
                    <Select labelId="vocations" id="vocations" value={this.state.form.vocation.value}
                            onChange={(event) => this.change_value('vocation', event)}>
                        <MenuItem value=""/>
                        {
                            this.renderWorkSubject()
                        }

                    </Select>

                    <div className="button" onClick={() => { this.add_destination() }}>Add responsibility</div>

                </Popup>
                <Notification ref={this.success_notification} text="Added Admin User successfully" type="success" />
                <Notification ref={this.error_notification} text="Something went wrong. Please try again." type="error" />
                <Loading visible={this.state.loading} />
            </>

        )
    }
}
