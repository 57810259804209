import moment from 'moment';
import { SHOW_LOGS } from '../store';

/**
 * format date to object of date and time
 * @param {Date} datetime
 */
export const formatTimeFromDb = (datetime) => {

    let date = moment(datetime);
    return {
        date: date.format('DD.MM.YYYY'),
        time: date.format('HH:mm'),
    };

}

/**
 * format datetime to string
 * @param {*} datetime
 */
export const formatDatetime = (datetime) => {

    if (typeof datetime === 'string') {

        datetime = datetime.replace(' ', 'T');
        datetime = new Date(datetime);

    }

    return moment(datetime).format('DD.MM.YYYY HH:mm')
}

/**
 * Format datetime to unix
 * @param {*} datetime
 */
export const formatTimeToUnix = (datetime) => {

    if (typeof datetime === 'string') {

        datetime = datetime.replace(' ', 'T');
        datetime = new Date(datetime);

    }

    return datetime.getTime();
}

/**
 * get current time to unix
 */
export const getCurrentUnix = () => {
    return new Date().getTime();
}

/**
 * Format datetime for server
 * @param {Date} datetime
 */
export const formatDatetimeForDb = (datetime) => {
    return moment(datetime).format('YYYY-MM-DDTHH:mm:00') + 'Z';
}

/**
 * Format datetime for server
 * @param {*} datetime
 */
export const formatDatetimeForDbSimple = (datetime) => {
    return moment(datetime).format('YYYY-MM-DD HH:mm:00');
}

/**
 * Returns formatted current datetime
 */
export const currentDateTime = () => {

    return moment().format('DD.MM.YYYY HH:mm')

}

/**
 * Formats datetime and calculates timezone difference
 * @param {*} date
 */
export const calculateLocalTimezone = (date) => {


    if (typeof date === 'string') {

        date = date.replace(' ', 'T');
        date = new Date(date);

    }

    let offset = date.getTimezoneOffset();
    let newDate = new Date(date.getTime() - (offset * 60 * 1000));
    return moment(newDate).format('DD.MM.YYYY - HH:mm')

}

/**
 * returns utc formatted datetime and calculates timezone difference
 * @param {Date} date
 */
export const calculateUtcTimezone = (date) => {

    let offset = date.getTimezoneOffset();
    let newDate = new Date(date.getTime() + (offset * 60 * 1000));

    return moment(newDate).format('YYYY-MM-DDTHH:mm:00') + 'Z'

}

/**
 * Removes Object from Array by value
 * @param {Array} arr
 * @param {*} needle value
 */
export const removeA = (arr, needle) => {
    let ax = arr.indexOf(needle)
    if (ax !== -1) {
        arr.splice(ax, 1);
    }
    return arr;
}

export const wrlog = (label, value = null) => {

    if (SHOW_LOGS) {
        if (value === null) {
            console.log(label);
        } else {
            console.log(label, value);
        }
    }

}

/**
 * getBase64 from file
 */
export const getBase64 = (file, split = true) => {

    return new Promise(resolve => {

        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {

            baseURL = reader.result;

            if(split){
                const baseSplit = baseURL.split(';base64,');

                resolve(baseSplit[1]);
            }else{
                resolve(baseURL)
            }

        };

    });

};

/**
 * set WorkingSubjectsAndAreas
 * @param Array<{name: string, children: {name: string}}}}> subjectsAndAreas
 */
export const setWorkingSubjectsAndAreas = (subjectsAndAreas) => {
    return new Promise((resolve, reject) => {
        try {
            sessionStorage.setItem('subjectAreaList', JSON.stringify(subjectsAndAreas));
            resolve();
        }
        catch (e) {
            reject('StorageError: ', e)
        }
    })

}

/**
 * get WorkingSubjectsAndAreas
 * @returns Array<{name: string, children: {name: string}}}>
 */
export const getWorkingSubjectsAndAreas = () => {
    return new Promise((resolve, reject) => {
        try{
            const subjectAreaList = sessionStorage.getItem('subjectAreaList');
            resolve(subjectAreaList);
        }
        catch (e) {
            wrlog('Error: ', e);
            reject('Error: ',e)
        }
    })
}
