/**
 * Check if user is logged in
 */
export const is_logged_in = () => {

    return get_user_token() !== null

}

/**
 * Get the user's token
 */
export const get_user_token = () => localStorage.getItem('token');




/**
 * Check if user has certain permission
 * @param {String} permission_type 
 * @param {String} action 
 */
export const has_permission = (permission_type, action) => {

    let permissions = sessionStorage.getItem('permissions');

    if (permissions == null) return false;

    permissions = JSON.parse(permissions).data.permissions;
    
    if (permissions[permission_type] === undefined) return false;

    return permissions[permission_type][action] === true;
    
}