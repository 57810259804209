import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Loading from '../../../../helper/screens/Loading';


import {FormControlLabel} from '@material-ui/core';

import CustomCheckbox from '../../../../helper/screens/CustomCheckbox';

import AppUserUnit from '../../../../units/AppUserUnit';

import {ArgumentAxis, Chart, LineSeries, Title, Tooltip, ValueAxis} from "@devexpress/dx-react-chart-material-ui";

import {Animation, EventTracker, ScatterSeries, ValueScale} from "@devexpress/dx-react-chart";
import regions from '../../../../helper/data/regions.json';
import {formatDatetimeForDbSimple, getWorkingSubjectsAndAreas, wrlog} from '../../../../helper/misc';
import DatetimeRangePicker from 'react-datetime-range-picker';

import {CSVLink} from "react-csv";

export default class StatisticsUsers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            is_404: false,
            statistics: {},
            current_panel: 'panel1',
            activeUsers: 0,
            newUsers: 0,
            graphData: [],
            form: {
                from: {
                    value: new Date()
                },
                to: {
                    value: new Date()
                },
                regions: {
                    value: []
                },
                vocations: {
                    value: []
                },
                timeperiod: {
                    value: 'full'
                }
            },
            user: 0,
            csvData: "",
            workSubjectsAndAreas: null

        }

        this.csvDownloadLink = new React.createRef();
        this.sidebar_rightRef = new React.createRef();
        this.sidebar_right_burgerRef = new React.createRef();
        this.appUserUnit = new AppUserUnit(props.com);

    }

    componentDidMount() {

        this.props.set_current_page('/statistics');

        this.changeTimeperiod('full');

        getWorkingSubjectsAndAreas(false, this.cbLoadWorkingSubjectsAndAreas, (error) => wrlog('Error: ', error))

    }

    /**
     * loading SubjectsAndAreas from session-Storage
     * @param {name: string, mvzvCode: number, children?: {name: string, mvzvCode: number}} workSubjectsAndAreas
     */
    cbLoadWorkingSubjectsAndAreas = (workSubjectsAndAreas) => {
        this.setState({
            workSubjectsAndAreas: JSON.parse(workSubjectsAndAreas.toString()),
        })
    }


    toggleMobileNav = (force_action = false) => {

        if (force_action === 'close') {

            this.sidebar_rightRef.current.classList.remove('open')
            this.sidebar_right_burgerRef.current.classList.remove('open')

        } else if (force_action === 'open') {

            this.sidebar_rightRef.current.classList.add('open')
            this.sidebar_right_burgerRef.current.classList.add('open')

        } else {
            this.sidebar_rightRef.current.classList.toggle('open')
            this.sidebar_right_burgerRef.current.classList.toggle('open')
        }


    }

    changeRegions = (value) => {

        let formCopy = this.state.form;
        let selectedRegions = formCopy.regions.value;
        let position = selectedRegions.indexOf(value);

        if (position > -1) {
            selectedRegions.splice(position, 1);
        } else {
            selectedRegions.push(value);
        }

        formCopy.regions.value = selectedRegions;

        this.setState({
            form: formCopy
        })

    }

    changeVocations = (value) => {

        let formCopy = this.state.form;
        let selectedVocations = formCopy.vocations.value;
        let position = selectedVocations.indexOf(value);

        if (position > -1) {
            selectedVocations.splice(position, 1);
        } else {
            selectedVocations.push(value);
        }

        formCopy.vocations.value = selectedVocations;

        this.setState({
            form: formCopy
        })

    }

    createCsv = (data) => {

        let csvData = [
            ["Bundesland", "Berufsgruppe", "Beruf", "neue UserInnen", "Zeitraum", "Arbeitszeit", "Mitglieder", "Interessenten"],
        ];

        data.sort((a, b) => {

            return b.region < a.region

        })

        data.forEach((element, index) => {

            const vocationCategory = this.state.workSubjectsAndAreas && this.state.workSubjectsAndAreas.filter((singleWorkArea) => Math.floor(singleWorkArea.mvzvCode / 100) === Math.floor(element.vocation.value / 100));
            const workSubject = this.state.workSubjectsAndAreas && this.state.workSubjectsAndAreas.filter((singleWorkArea) => {
                return singleWorkArea.children && singleWorkArea.children.filter((singleWorkSubject) => {
                    return singleWorkSubject.mvzvCode === element.vocation.value
                })
            })

            let hours = parseInt(element.secondsWorked / 60 / 60);
            let minutes = parseInt((element.secondsWorked / 60) - (hours * 60));

            csvData.push(
                [
                    regions[element.region],
                    vocationCategory,
                    workSubject,
                    element.newlyCreated,
                    element.from + ' - ' + element.to,
                    hours + 'h ' + minutes + 'm',
                    element.interested,
                    element.member
                ]
            )
        });

        this.setState({
            csvData: csvData
        })

        console.log(this.csvDownloadLink)

        this.csvDownloadLink.link.click()

    }

    setFilters = () => {

        let from = new Date();
        let to = new Date();

        let today = new Date();

        today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 0);

        let last_month = today.getMonth() - 1;
        let last_month_year = today.getFullYear();

        if (last_month < 0) {
            last_month = 11;
            last_month_year = last_month_year - 1;
        }

        last_month = new Date(last_month_year, last_month, 1, 0, 0, 0);
        let last_month_last_day = new Date(last_month_year, last_month.getMonth(), new Date(last_month_year, last_month.getMonth() + 1, 0).getDate(), 23, 59, 0);

        let this_year_start = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0);

        let last_year_start = new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0);
        let last_year_end = new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 0);

        switch (this.state.form.timeperiod.value) {
            case 'last_7_days':
                from = new Date(today.getTime() - (1000 * 60 * 60 * 24 * 6))
                to = today;
                break;
            case 'this_month':
                from = new Date(today.getTime() - (1000 * 60 * 60 * 24 * new Date().getDate()))
                to = today;
                break;
            case 'last_month':
                from = last_month;
                to = last_month_last_day;
                break;
            case 'this_year':
                from = this_year_start;
                to = today;
                break;
            case 'last_year':
                from = last_year_start;
                to = last_year_end;
                break;
            case 'full':
                from = new Date(2000);
                to = new Date();
                break;
            case 'custom':
                from = this.state.statistics.createdLastWeek;
                to = new Date();
                break;
            default:
                return this.state.statistics.createdAll;
        }

        const success = (res) => {

            this.createCsv(res.data.statistics)
            console.log(res);

        }
        const error = (err) => {

            console.log(err);

        }

        this.appUserUnit.loadFilteredStatistics(
            formatDatetimeForDbSimple(from),
            formatDatetimeForDbSimple(to),
            this.state.form.vocations.value.join(','),
            this.state.form.regions.value.join(','),
            success,
            error
        )
    }

    getTimerange = (timeperiod) => {

        let from = new Date();
        let to = new Date();

        let today = new Date();

        today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 0);

        let last_month = today.getMonth() - 1;
        let last_month_year = today.getFullYear();

        if (last_month < 0) {
            last_month = 11;
            last_month_year = last_month_year - 1;
        }

        last_month = new Date(last_month_year, last_month, 1, 0, 0, 0);
        let last_month_last_day = new Date(last_month_year, last_month.getMonth(), new Date(last_month_year, last_month.getMonth() + 1, 0).getDate(), 23, 59, 0);

        let this_year_start = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0);

        let last_year_start = new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0);
        let last_year_end = new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 0);

        switch (timeperiod) {
            case 'last_7_days':
                from = new Date(today.getTime() - (1000 * 60 * 60 * 24 * 6))
                to = today;
                break;
            case 'this_month':
                from = new Date(today.getTime() - (1000 * 60 * 60 * 24 * (new Date().getDate() - 1)))
                to = today;
                break;
            case 'last_month':
                from = last_month;
                to = last_month_last_day;
                break;
            case 'this_year':
                from = this_year_start;
                to = today;
                break;
            case 'last_year':
                from = last_year_start;
                to = last_year_end;
                break;
            case 'full':
                from = null;
                to = new Date();
                break;
            case 'custom':
                from = this.state.form.from.value;
                to = this.state.form.to.value;
                break;
            default:
                from = null;
                to = new Date();
                break;
        }

        return {
            from: from,
            to: to
        }

    }

    changeTimeperiod = (period) => {

        const { from, to } = this.getTimerange(period);

        this.setState({
            loading: true
        })

        this.appUserUnit.loadStatisticsForTimeRange(from, to, this.formatStatistics, (err) => {
            console.log(err);
        })

        this.changePeriod(period);

    }

    formatStatistics = ({ data: { statistics: { activeInTimeFrame, createdInTimeFrame, created } } }, from, to) => {

        this.setState({
            loading: false,
            activeUsers: activeInTimeFrame,
            newUsers: createdInTimeFrame,
        });

        if (from === null) {
            from = this.destructDate(created[0].time).dateObject;
        }

        if (to === null) {
            to = this.destructDate(created[created.length - 1].time).dateObject;
        }

        this.formatGraph(created, from, to)

    }

    changePeriod = (value) => {

        let formCopy = this.state.form;

        formCopy.timeperiod.value = value;

        this.setState({ form: formCopy });

        this.toggleMobileNav('close');

    }

    handleAccordion = (panel) => {

        if (this.state.current_panel === panel) panel = null;

        this.setState({
            current_panel: panel
        })
    }

    formatGraph = (datesRaw, from, to) => {

        this.setState({
            graphData: []
        });
        if (datesRaw[0] === undefined) {
            this.setState({
                graphData: [{
                    label: "",
                    value: 0
                },
                {
                    label: "",
                    value: 0
                }]
            })
            return;
        }

        let dates = {};
        datesRaw.forEach(date => {
            dates[date.time] = date.count;
        })

        // Get days between dates
        const daysDiff = Math.round((to - from) / (1000 * 60 * 60 * 24));

        let values = [];
        let labels = [];

        let graphData = [];

        console.log("daysDiff", daysDiff, to, from, (to - from) / (1000 * 60 * 60 * 24))

        if (daysDiff <= 1) { // Display days

            const currDate = from;
            const key = currDate.getFullYear() + '-' + (currDate.getMonth() + 1) + '-' + currDate.getDate();

            graphData.push({
                label: key.toString(),
                value: dates[key] || 0
            })

            console.log(key);

        }
        else if (daysDiff <= 7) { // Display days

            for (let i = 0; i < daysDiff; i++) {

                const currDate = new Date(from.getTime() + (i * 24 * 60 * 60 * 1000));
                const key = currDate.getFullYear() + '-' + (currDate.getMonth() + 1) + '-' + currDate.getDate();

                graphData.push({
                    label: key.toString(),
                    value: dates[key] || 0
                })

            }

        } else if (daysDiff <= 31) { // Display Weeks

            let currDate = from;

            while (currDate < to) {

                const weekNr = this.getWeekNumber(currDate);

                const key = 'KW ' + weekNr;

                let newValues = 0;

                datesRaw.forEach(date => {

                    const { dateObject } = this.destructDate(date.time)
                    const thisWeekNr = this.getWeekNumber(dateObject);

                    if (thisWeekNr == weekNr && dateObject > from && dateObject < to) {
                        newValues = newValues + date.count;
                    }

                })

                graphData.push({
                    label: key.toString(),
                    value: newValues
                })

                console.log("graphData", graphData)

                currDate = new Date(currDate.getTime() + (24 * 60 * 60 * 1000 * 7));

            }

        } else if (daysDiff <= 365) { // Display Months

            let currDate = from;

            while (currDate < to) {

                const key = currDate.getFullYear() + '-' + (currDate.getMonth() + 1);

                let newValues = 0;

                datesRaw.forEach(date => {

                    const { year, month } = this.destructDate(date.time)

                    if (year == currDate.getFullYear() && currDate.getMonth() + 1 == month) {
                        newValues = newValues + date.count;
                    }

                })

                graphData.push({
                    label: key.toString(),
                    value: newValues
                })

                currDate.setMonth(currDate.getMonth() + 1);
            }


        } else { // Display years

            let currDate = from;

            while (currDate < to) {

                const key = currDate.getFullYear();

                let newValues = 0;
                datesRaw.forEach(date => {

                    const { year } = this.destructDate(date.time)

                    if (year == currDate.getFullYear()) {
                        newValues = newValues + date.count;
                    }

                })

                graphData.push({
                    label: key.toString(),
                    value: newValues
                })

                currDate.setYear(currDate.getFullYear() + 1);
            }


        }


        this.setState({
            graphData: graphData,
        })

    }

    destructDate = (date) => {
        const splitted = date.split('-');

        return {
            month: splitted[1],
            year: splitted[0],
            day: splitted[2],
            dateObject: new Date(splitted[0], splitted[1] - 1, splitted[2])
        }
    }

    changeDateRange = (value) => {

        value.start.setHours(0);
        value.start.setMinutes(0);
        value.start.setSeconds(0);

        value.end.setHours(23);
        value.end.setMinutes(59);
        value.end.setSeconds(59);

        let form_copy = this.state.form;
        form_copy.from.value = value.start;
        form_copy.to.value = value.end;

        this.setState({
            form: form_copy
        })

        this.changeTimeperiod('custom')

    }

    getWeekNumber = (d) => {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return array of year and week number
        return weekNo;
    }

    render() {

        return (
            <>
                <div
                    className="burger_right"
                    ref={this.sidebar_right_burgerRef}
                    onClick={() => {
                        this.toggleMobileNav()
                    }}
                ></div>

                <Container fluid style={{ marginLeft: 0 }} className="middle_container">
                    <Row>
                        <Col md={12} style={{ paddingBottom: 20 }}>
                            <Link className="go_back_button" to="/statistics">Go back</Link><br /><br />
                            <h1>Users</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <h2>{this.state.newUsers}</h2>
                            <strong>New Users</strong>
                        </Col>
                        <Col md={3}>
                            <h2>{this.state.activeUsers}</h2>
                            <strong>Active Users</strong>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 50 }}>
                        <Col md={12}>
                            {this.state.graphData.length > 0 ?
                                <Chart data={this.state.graphData} className="graph_container">
                                    <ValueScale name="value" />

                                    <Title
                                        text="New users"
                                        position={"top"}
                                        textComponent={(data) => { return <strong>{data.text}</strong> }}
                                    />

                                    <ValueAxis
                                        scaleName="value"
                                        position="left"
                                        showGrid={false}
                                        showLine={false}
                                        min={-10}
                                        showTicks={false}
                                    />
                                    <ArgumentAxis
                                        scaleName="label"
                                        position="bottom"
                                        showGrid={false}
                                        showLine={false}
                                        showTicks={false}
                                    />
                                    {this.state.graphData.length > 1 ? <LineSeries
                                        name=""
                                        valueField="value"
                                        argumentField="label"
                                        scaleName="value"
                                        color="#142945"
                                    /> :
                                        <ScatterSeries
                                            valueField="value"
                                            argumentField="label"
                                            color="#142945"
                                        />
                                    }
                                    <EventTracker />
                                    <Tooltip
                                        arrowComponent={() => { return null }}
                                        sheetComponent={(data) => { return data.children }}
                                        contentComponent={(data) => { return data.text }}
                                    />
                                    <Animation />
                                </Chart>
                                : null}
                        </Col>
                    </Row>

                </Container>
                <div className="sidebar_right" ref={this.sidebar_rightRef} style={{ paddingTop: 100, backgroundColor: 'white' }}>
                    <div className="small_button" onClick={this.setFilters}>download</div>
                    <br />
                    <br />

                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'full'} />}
                        label={'All Time'}
                        labelPlacement="end"
                        onChange={(event) => this.changeTimeperiod("full")}
                        style={{ marginBottom: 10 }}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'last_7_days'} />}
                        label={'Last 7 days'}
                        labelPlacement="end"
                        onChange={(event) => this.changeTimeperiod("last_7_days")}
                        style={{ marginBottom: 10 }}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'this_month'} />}
                        label={'This month'}
                        labelPlacement="end"
                        onChange={(event) => this.changeTimeperiod("this_month")}
                        style={{ marginBottom: 10 }}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'last_month'} />}
                        label={'Last Month'}
                        labelPlacement="end"
                        onChange={(event) => this.changeTimeperiod("last_month")}
                        style={{ marginBottom: 10 }}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'this_year'} />}
                        label={'This Year'}
                        labelPlacement="end"
                        onChange={(event) => this.changeTimeperiod("this_year")}
                        style={{ marginBottom: 10 }}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'last_year'} />}
                        label={'Last Year'}
                        labelPlacement="end"
                        onChange={(event) => this.changeTimeperiod("last_year")}
                        style={{ marginBottom: 10 }}
                    /><br />
                    <FormControlLabel
                        value={"true"}
                        control={<CustomCheckbox checked={this.state.form.timeperiod.value === 'custom'} />}
                        label={'Custom'}
                        labelPlacement="end"
                        onChange={(event) => this.changePeriod("custom")}
                        style={{ marginBottom: 10 }}
                    />
                    {
                        this.state.form.timeperiod.value === 'custom' ?
                            <DatetimeRangePicker
                                onChange={this.changeDateRange}
                                startDate={this.state.form.from.value}
                                endDate={this.state.form.to.value}
                                timeFormat={false}
                                className={"datepicker_range"}
                                dateFormat={'DD.MM.YYYY'}
                                isValidEndDate={(date) => {
                                    return date <= new Date();
                                }}
                            />
                            : null
                    }


                    <CSVLink
                        ref={ref => this.csvDownloadLink = ref}
                        data={this.state.csvData}
                    >

                    </CSVLink>
                    <br />
                    <br />
                    <br />

                </div>
                <Loading visible={this.state.loading} />

            </>

        )
    }
}

/*

from: "1970-01-01 01:00:00.0"
to: "2021-02-01 11:23:00.0"
interested: 0
member: 0
newlyCreated: 0
region: "AT-2"
secondsWorked: 0
vocation: "FB_GH_KOCH"
*/
