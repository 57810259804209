import React from 'react';

import {FormControlLabel} from '@material-ui/core';

import CustomCheckbox from './CustomCheckbox';
import Accordion from './Accordion';

import {getWorkingSubjectsAndAreas, wrlog} from '../misc'

/**
 * render Vocation Accordion
 */
export default class VocationAccordion extends React.Component {

    constructor() {

        super();

        this.state = {
            subjectAndAreas: null,
            currentOpenedKey: null,
        }
    }

    componentDidMount() {
        getWorkingSubjectsAndAreas()
            .then((result) => {
                this.setState({
                    subjectAndAreas: JSON.parse(result),
                })
            })
            .catch((error) => {
                wrlog('Error: ', error)
                this.setState({
                    subjectAndAreas: [{}]
                })
            })
    }

    /**
     * Toggles open state
     * @param {*} key
     */
    toggle = (key) => {
        if (this.state.currentOpenedKey === key) key = null;

        this.setState({
            currentOpenedKey: key
        })

    }

    /**
     * Lists the vocations / categories
     */
    listVocations = () => {

        const subjectCounts = this.state.subjectAndAreas && this.state.subjectAndAreas.map((singleSubjectArea) => {
            return (
                {
                    name: singleSubjectArea.name,
                    children: singleSubjectArea.children ? singleSubjectArea.children : null,
                    mvzvCode: singleSubjectArea.mvzvCode,
                    counter: {
                        selected: (
                            this.props.value.includes(singleSubjectArea.mvzvCode) ? 1 : 0) + (singleSubjectArea.children ?
                            singleSubjectArea.children.filter((singleSubject) =>
                                this.props.value.includes(singleSubject.mvzvCode)).length :
                            0),
                        availableSelections: (singleSubjectArea.children && singleSubjectArea.children.length) + 1,
                    }
                })
        })

        return this.state.subjectAndAreas && subjectCounts.map((singleSubjectArea) => {
            return (
                <Accordion
                    expanded={this.state.currentOpenedKey === singleSubjectArea.mvzvCode}
                    onClick={() => this.toggle(singleSubjectArea.mvzvCode)}
                    title={singleSubjectArea.name + " (" + singleSubjectArea.counter.selected + ")"}>

                    {
                        singleSubjectArea.counter.availableSelections > 1 &&
                        <div
                            className="small_button"
                            onClick={() => {
                                singleSubjectArea.counter.selected === singleSubjectArea.counter.availableSelections ?
                                    this.props.removeAllSubjectsFromArea(singleSubjectArea) :
                                    this.props.addAllSubjectsFromArea(singleSubjectArea)
                            }
                            }
                            style={{marginBottom: 10}}
                        >
                            {singleSubjectArea.counter.selected === singleSubjectArea.counter.availableSelections ? "Remove all" : "Select all"}
                        </div>
                    }
                    <FormControlLabel
                        value={singleSubjectArea.name}
                        control={<CustomCheckbox style={{fontWeight: 200}}
                                                 checked={this.props.value.includes(singleSubjectArea.mvzvCode)}/>}
                        label={singleSubjectArea.name + ' (' + singleSubjectArea.mvzvCode + ')'}
                        labelPlacement="end"
                        onChange={() => this.props.changevalue(singleSubjectArea.mvzvCode)}
                        style={{marginBottom: 10, width: '100%'}}
                        key={singleSubjectArea.mvzvCode}
                    />
                    {
                        singleSubjectArea.children && singleSubjectArea.children.map((singleSubject) => {
                            return (
                                <FormControlLabel
                                    value={singleSubject.name}
                                    control={<CustomCheckbox style={{fontWeight: 200}}
                                                             checked={this.props.value.includes(singleSubject.mvzvCode)}/>}
                                    label={singleSubject.name + ' (' + singleSubject.mvzvCode + ')'}
                                    labelPlacement="end"
                                    onChange={() => this.props.changevalue(singleSubject.mvzvCode)}
                                    style={{marginBottom: 10, width: '100%'}}
                                    key={singleSubject.mvzvCode}
                                />
                          );
                        })
                    }
                </Accordion>
            )
        });
    }

    render() {

        if (this.props.hideTitle !== undefined) {
            return (

                <div style={{ paddingLeft: 10 }}>
                    {
                        this.listVocations()
                    }
                </div>
            )
        }

        return (

            <Accordion expanded={this.props.opened} onClick={this.props.toggle} title={"Vocations/Branches (" + this.props.value.length + ")"}>
                <div style={{ paddingLeft: 10 }}>
                    {
                        this.listVocations()
                    }
                </div>
            </Accordion>


        )
    }
}
