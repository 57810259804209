import { wrlog } from "../misc";

/**
 * Get the german title of push Notification
 * @param {Object} notification 
 */
export const get_title = (notification) => {

    if (notification.data !== undefined) {
        let title = notification.data[0].title

        notification.data.forEach(version => {

            if (version.language === 'de') title = version.title;

        })

        return title;
    }

    if(notification.title != undefined) return notification.title

}
