import { TextField } from '@material-ui/core';
import React from 'react';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';
import AuthenticationUnit from '../../units/AuthenticationUnit';
//import AuthenticationUnit from '../../units/AuthenticationUnit';


export default class Login extends React.Component {

    constructor(){

        super(); 

        this.state = {
            show_errors : false,
            form: {
                email : {
                    valid : false,
                    value : '',
                    rules : {
                        required : true
                    }
                },
                password : {
                    valid : false,
                    value : '',
                    rules : {
                        required : true
                    }
                }
            }
        }

        this.notification = React.createRef()
    }

    componentDidMount() {

    }

    do_login = (event) => {

        this.setState({
            show_errors : false
        })

        event.preventDefault();

        let isValid = true;

        let formCopy = this.state.form;

        Object.keys(formCopy).forEach( key => {

            if(formCopy[key].valid === false){
                
                isValid = false;

            }

        });

        if(!isValid){
            this.setState({
                show_errors : true
            })

            return false;

        };

        this.setState({
            loading : true
        })

        let auth = new AuthenticationUnit(this.props.com);

        let cb_success = (res) => {

            localStorage.setItem('token', res.data.token);
            localStorage.setItem('token_expire_time', new Date().getTime() + (13 * 60 * 1000));
            
            window.location.reload();
            
            this.setState({
                loading: false
            });
                
        }
        let cb_error = (res) => {

            this.setState({
                loading: false,
                show_errors: true
            });

            this.notification.current.open();
                
        }

        setTimeout(() => {

            cb_error(null);
            this.notification.current.open();

        }, (30 * 1000))

        auth.login(formCopy.email.value.toLowerCase(), formCopy.password.value, cb_success, cb_error);

    }

    change_value = (key, value) => {

        let formCopy = this.state.form;

        if(typeof value === 'object' && value.target !== undefined){
            value = value.target.value;
        }

        formCopy[key].value = value;
        formCopy[key].valid = validation(value, formCopy[key].rules);

        this.setState({form: formCopy})

    }


    render() {
        return (
            <>
            <div className="login_wrapper">
                <div className="login_container">
                    <h1>Login</h1>
                    <form onSubmit={this.do_login}>
                        <TextField id="standard-basicasd" value={this.state.form.email.value} error={!this.state.form.email.valid && this.state.show_errors} label="Email" type="email" onChange={value => this.change_value('email', value)} /><br />
                        <TextField id="standard-basic" value={this.state.form.password.value} label="Password" type="password" error={!this.state.form.password.valid && this.state.show_errors} onChange={event => this.change_value('password', event)} /><br />
                        <input type="submit" className="button" value="Login" />
                    </form>
                    {/* <div className="">I forgot my password</div> */}
                </div>

            </div>
            <Loading visible={this.state.loading}/>
            <Notification ref={this.notification} text="Invalid email or password" type="error" />
            </>
        );
    }
}