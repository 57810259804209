import { get_user_token } from '../helper/functions/auth';
import Unit from './Unit';


export default class AuthenticationUnit extends Unit {


    constructor(communicator) {

        super(communicator, 'authentication');
    }

    login = (username, password, cb_success, cb_error) => { 

        let args = {
            data: {
                username: username,
                password: password,
            },
            action: 'login',
            unit: this.name
        }

        this.communicator.call(args, cb_success, cb_error);

    }

    logout = (cb) => {

        let args = {
            token : get_user_token(),
            action: 'logout',
            unit: this.name
        }

        this.communicator.call(args, cb, cb);

    }

    refresh_token = (cb_success = () => {}, cb_error = () => {}) => {

        let args = {
            token : get_user_token(),
            action: 'refreshToken',
            unit: this.name
        }

        this.communicator.call(args, (res) => {
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('token_expire_time', new Date().getTime() + (13 * 60 * 1000));
            cb_success();
        }, () => {
            this.logout(localStorage.removeItem('token'));
            cb_error();
        });
        
    }

    change_password = () => {
        let args = {
            token : get_user_token(),
            action: 'refreshToken',
            unit: this.name
        }

        this.communicator.call(args, (res) => {
            localStorage.setItem('token', res.data.token);
        }, () => {
            this.logout(localStorage.removeItem('token'));
        });
    }



}



